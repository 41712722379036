import palette from './palette/palette';
import overrides from './overrides';
import typography from './typography/typography';
import breakpoints from './breakpoints';
import { createMuiTheme } from '@material-ui/core';
import {
  overrides as sharedOverrides,
  props,
  spacing,
  typography as sharedTypography,
  palette as sharedPalette,
} from '@fluentsms/agentnet-web-components';

// TODO: Remove all ANS's original theming in favor of lib-agentnet's shared theming where applicable
const combinedPalette = {
  ...sharedPalette,
  ...palette,
};

const combinedOverrides = {
  ...sharedOverrides,
  ...overrides,
};

const combinedTypography = {
  ...sharedTypography,
  h1: { ...sharedTypography.h1, textTransform: typography.h1.textTransform },
  h2: { ...sharedTypography.h2, textTransform: typography.h2.textTransform },
  h3: { ...sharedTypography.h3, textTransform: typography.h3.textTransform },
  h4: { ...sharedTypography.h4, textTransform: typography.h4.textTransform },
  h5: { ...sharedTypography.h5, textTransform: typography.h5.textTransform },
  h6: { ...sharedTypography.h6, textTransform: typography.h6.textTransform },
};

export const theme = createMuiTheme({
  breakpoints,
  overrides: combinedOverrides,
  props,
  palette: combinedPalette,
  spacing,
  typography: combinedTypography,
});
