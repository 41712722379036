import React, { useState } from 'react';
import { FieldValidationError } from '../utilities/validation/validation';

export interface OrderCreateContextInterface {
  children?: React.ReactNode;
  validationErrors?: FieldValidationError[];
  setValidationErrors?: (errs: FieldValidationError[]) => void;
}
const OrderCreateContext = React.createContext<OrderCreateContextInterface | null>(null);

const OrderCreateProvider = (props: OrderCreateContextInterface): JSX.Element => {
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  const ctx: OrderCreateContextInterface = {
    validationErrors,
    setValidationErrors,
  };

  return <OrderCreateContext.Provider value={ctx}>{props.children}</OrderCreateContext.Provider>;
};

export { OrderCreateContext, OrderCreateProvider };
