import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import './AvlList.scss';
import { makeStyles } from '@material-ui/core/styles';
import { AgentDataType } from './types';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import LoadingSpinner from '../../../ui-kit/components/LoadingSpinner';
import NoResultFoundIcon from '../../../ui-kit/icons/NoResultFound';
import { ColDef, FilterChangedEvent, ValueFormatterParams } from 'ag-grid-enterprise';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import StateSelectField from 'ui-kit/inputs/StateSelectField';
import AvlAction from './AvlAction';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import { KBLinks } from 'features/constants';
import { DataTable } from '@fluentsms/agentnet-web-components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  tableStyles: {
    marginTop: '0px !important',
    height: 'calc(100vh - 349px) !important',
    width: '100% !important',
  },
}));

const AvlList: React.FC = () => {
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const history = useHistory();
  const [rows, setRows] = React.useState<AgentDataType[]>([]);
  const [agentRowsByState, setAgentRowsByState] = React.useState<AgentDataType[]>([]);
  const [propertyStates, setPropertyStates] = useState<{ name: string; value: string }[]>([]);
  const [propertyState, setPropertyState] = useState<string>('');
  const [isActionPending, setActionPendingFlag] = useState(false);

  useEffect(() => {
    if (!userFirm?.firmId) {
      history.push('/err');
    }
  }, [userFirm]);

  useEffect(() => {
    if (userFirm?.offices?.length ?? 0 > 0) {
      const accounts = userFirm?.offices?.flatMap((office) =>
        office.accounts.map((account) => ({
          id: office.officeId,
          officeStatus: office.status,
          address: office.address,
          officeDbaName: office.dbaName,
          ...account,
        })),
      );
      const agents: AgentDataType[] =
        accounts
          ?.filter((acc) => acc.status?.toLowerCase() === 'active' && acc.officeStatus?.toLowerCase() === 'active')
          .map((acc) => ({
            clientType: acc.clientType == 'Approved Attorney' ? acc.clientType : 'Agent',
            clientTypeId: acc.clientTypeId || null,
            agentName: acc.officeDbaName,
            accountNumber: acc.accountId,
            accountStatus: acc.status || null,
            accountStateCode: acc.stateCode,
            underwriterName: acc.underwriterName,
            underwriterCode: acc.underwriterCode,
            officeAddress: `${acc.address.address1}${
              acc.address.address2 && acc.address.address2.trim() !== '' ? ` ${acc.address.address2}` : ''
            }`,
            city: acc.address.city || null,
            state: acc.address.state || null,
            postalCode: acc.address.postalCode || null,
          })) ?? [];

      setRows(agents);

      const sortedPropertyStates: { name: string; value: string }[] = Array.from(
        new Set(agents?.map((a) => a.accountStateCode).filter((code) => code !== null)),
      )
        .sort()
        .map((code) => ({ name: code || '', value: code || '' }));

      if (sortedPropertyStates && sortedPropertyStates.length > 0) {
        setPropertyStates(sortedPropertyStates);
        setPropertyState(sortedPropertyStates[0].value);

        const agentResultByState = agents.filter((a) => a.accountStateCode === sortedPropertyStates[0].value);
        setAgentRowsByState(agentResultByState);
        if (!agentResultByState.length) gridRef.current?.api.showNoRowsOverlay();
        else gridRef.current?.api.hideOverlay();
      }
    } else {
      gridRef.current?.api.showNoRowsOverlay();
    }
  }, [userFirm]);

  const gridRef = useRef<any>(null);
  const CustomOverlayLoading = () => {
    return (
      <div className="no-rows">
        <LoadingSpinner status="pending" className="avl-container--spinner-root" />
      </div>
    );
  };

  const CustomOverlayNoRows = () => {
    return (
      <div className="no-rows">
        <NoResultFoundIcon className="no-avls" />
        <h1>No Results Found</h1>
        <p className="adjust-your-search">Try adjusting your search or filter to find what you’re looking for</p>
      </div>
    );
  };

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    if (!event.api.getDisplayedRowCount()) gridRef.current?.api.showNoRowsOverlay();
    else gridRef.current?.api.hideOverlay();
  }, []);

  function onPropertyStateChange(e: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const searchStr = typeof e === 'string' ? e : e.target.value;
    if (propertyStates.some((x) => x.value === searchStr)) {
      setPropertyState(searchStr);

      const agentResultByState = rows?.filter((a) => a.accountStateCode === searchStr);

      setAgentRowsByState(agentResultByState);
      gridRef.current?.api.setFilterModel(null);
    }
  }

  const colDefs: ColDef[] = [
    {
      field: 'clientType',
      headerName: 'Type',
      tooltipField: 'clientType',
      width: 160,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span ref="eFilter" data-qa="AvlListTypeIcon" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                        <span ref="eSortOrder" data-qa="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" data-qa="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                        <span ref="eSortDesc" data-qa="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                        <span ref="eSortNone" data-qa="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                    </div>
                </div>`;
        },
      },
    },
    {
      field: 'agentName',
      width: 300,
      headerName: 'Agent Name',
      tooltipField: 'agentName',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'accountNumber',
      headerName: 'Account #',
      tooltipField: 'accountNumber',
      width: 150,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'underwriterName',
      headerName: 'Underwriter',
      tooltipField: 'underwriterName',
      width: 300,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'officeAddress',
      headerName: 'Office Address',
      tooltipField: 'officeAddress',
      width: 300,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'city',
      headerName: 'City',
      tooltipField: 'city',
      width: 185,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                        <span ref="eSortOrder" data-qa="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" data-qa="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                        <span ref="eSortDesc" data-qa="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                        <span ref="eSortNone" data-qa="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                    </div>
                </div>`;
        },
      },
    },
    {
      field: 'state',
      headerName: 'State',
      tooltipField: 'state',
      width: 105,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'postalCode',
      headerName: 'Zip Code',
      tooltipField: 'postalCode',
      width: 125,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      pinned: 'right',
      cellClass: 'center-align-column jacket-actions-cell',
      cellRenderer: 'avlAction',
      cellRendererParams: (row: ValueFormatterParams) => ({
        agentAddress: [row.data.officeAddress, row.data.city, row.data.state, row.data.postalCode].join(', ') + ' ',
        clientTypeId: row.data.clientTypeId,
        propertyState: propertyState,
        setActionPendingFlag: setActionPendingFlag,
        isActionPending: isActionPending,
      }),
      width: 120,
      minWidth: 120,
      filter: false,
      floatingFilter: false,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
  ];

  return (
    <ContentContainer fullWidth>
      <Grid className={classes.paper}>
        <PageHeader
          title="Agency Verification Letters"
          subtitle="Agency Verification Letters formally confirm that a title agency is authorized by a particular underwriter to generate title insurance policies within a specific area. They do not provide any closing protection coverage. Easily access and download verification letters for compliance and agency validation."
          menuItems={[
            {
              label: 'Knowledge Base',
              link: KBLinks.avl,
            },
          ]}
          disableMargin="x"
        />
        {propertyStates?.length > 0 && (
          <div className={`${classes.row} dd-container`}>
            <StateSelectField
              label="Property State"
              required={true}
              Data-QA="qaPropertyState"
              name="State"
              id="State"
              shouldDisableClearable={true}
              stateOptions={propertyStates.map((state) => ({ ...state, abbreviation: state.value }))}
              value={propertyState}
              onChange={onPropertyStateChange}
            />
          </div>
        )}
        {agentRowsByState.length == 0 ? (
          <h3>Error in retrieving Agents - No Active Office</h3>
        ) : (
          <>
            <LoadingSpinner status={isActionPending ? 'pending' : 'success'} variant="linear" />
            <div className="grid-summary">
              <div className="avl-files-list-grid">
                <div id="avl-grid" className={`ag-theme-alpine table-grid ${classes.tableStyles}`}>
                  <DataTable
                    ref={gridRef}
                    rowData={agentRowsByState}
                    columnDefs={colDefs}
                    components={{
                      avlAction: AvlAction,
                      customOverlayLoading: CustomOverlayLoading,
                      customOverlayNoRows: CustomOverlayNoRows,
                      customFloatingFilter: FloatingFilterComponent,
                    }}
                    loadingOverlayComponent={'customOverlayLoading'}
                    noRowsOverlayComponent={'customOverlayNoRows'}
                    defaultColDef={{
                      cellClass: 'left-align-column',
                      headerClass: 'left-align-header',
                      sortable: true,
                      filter: 'agTextColumnFilter',
                      floatingFilter: true,
                      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                      suppressMenu: true,
                      minWidth: 100,
                      resizable: true,
                      suppressSizeToFit: false,
                    }}
                    onFilterChanged={onFilterChanged}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Grid>
    </ContentContainer>
  );
};

export default AvlList;
