import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { ArrowBack } from '@material-ui/icons';
import PaymentComponent from 'ui-kit/components/payment/PaymentComponent';
//import PDFIcon from 'ui-kit/icons/PDF';
import ServiceOrderInvoiceSummaryTable from 'ui-kit/components/payment/ServiceOrderInvoiceSummaryTable';
import { useViewState } from 'hooks/ViewStateContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { PaymentDetailsType } from './type';
import { useAuth } from '@agentnet/auth';
import useAsync from 'hooks/useAsync';
import { getEpayAccount, getPaymentIframe, onSubmitPayment } from 'api/payment/api';
import { openDocument } from 'utilities/utilities';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { DocumentType, newWindow, WindowFeatures } from 'features/constants';
import { FieldValidationError } from 'utilities/validation/validation';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface InvoiceSummaryProps {
  payFiles?: any;
  goBackAction: () => void;
  selectedRows?: any;
  isAllOpenStatus: boolean;
  isPremiumInvoice: boolean;
  paymentAccounts?: any;
}

const InvoiceSummary = ({
  payFiles,
  goBackAction,
  selectedRows,
  isAllOpenStatus,
  isPremiumInvoice,
  paymentAccounts,
}: InvoiceSummaryProps): JSX.Element => {
  const { menuCollapsed } = useViewState();
  const [drawerWidth, setDrawerWidth] = React.useState(menuCollapsed ? 445 : 684);
  React.useEffect(() => {
    setDrawerWidth(menuCollapsed ? 445 : 684);
  }, [menuCollapsed]);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
    },
    invoiceSummaryHeader: {
      padding: pxToRem(24),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    invoiceSummaryContent: {
      padding: pxToRem(0, 24, 0, 0),
      flex: 1,
      maxWidth: `calc(100vw - ${drawerWidth}px - 50px)`,
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mainWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: pxToRem(0, 0, 96),
      marginLeft: pxToRem(30),
    },
    paymentComponent: {
      position: 'fixed',
      right: '20px',
      overflowY: 'auto',
      maxHeight: '70vh',
    },
    heading: {
      marginBottom: theme.spacing(3),
    },
    iframe: {
      border: '0px #ffffff none',
      position: 'absolute',
      left: '8px',
      width: '100%',
      maxWidth: '370px',
      minHeight: '800px',
      marginTop: '16px',
    },
  }));

  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();
  const [orderData, setOrderData] = useState<any>(payFiles?.invoiceData);
  const [ePayAccounts, setEPayAccounts] = useState<any>([]);
  const [showValidation, setShowValidation] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile } = profileCtx;
  const [defaultEpayAccount, setDefaultEpayAccount] = React.useState<any>(null);
  const paymentMode = [
    { label: 'Credit or Debit Card', value: 'CD' },
    { label: 'ACH Transfer/Bank Account', value: 'ACH' },
  ];
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState(false);
  const [isAch, setAch] = useState(false);
  const [selectedPaymentMode, setselectedPaymentMode] = useState<RadioProps>(paymentMode[0]);

  useEffect(() => {
    mapEpayAccounts(paymentAccounts);
  }, []);

  const payManualRights = useMemo(() => {
    return (
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83)) &&
      Boolean(profile?.roleTypeId === 101)
    );
  }, [profile?.activityRights]);

  const paymentOptions = payManualRights
    ? [{ label: 'Check', value: 'Check' }]
    : [
        { label: 'ePay', value: 'ePay' },
        { label: 'Check', value: 'Check' },
      ];

  const totalAmountDueOpenFiles = orderData?.reduce(
    (sum: number, invoice: any) => (sum = sum + invoice?.invoiceBalance),
    0,
  );

  const underwritersSet = new Set();
  const invoiceTypesSet = new Set();

  selectedRows?.forEach((obj: any) => {
    underwritersSet.add('FAST');
    invoiceTypesSet.add('FASTInvoice');
  });

  const ePayAccountsPayload = {
    underwriter: Array.from(underwritersSet).join(', '),
    invoiceTypes: Array.from(invoiceTypesSet).join(', '),
  };

  const paymentInfo = useRef<PaymentDetailsType>({
    firmId: userFirm?.firmId ?? '',
    amount: totalAmountDueOpenFiles,
    orderNumbers: [],
    invoiceNumbers: [],
    paymentMethod: '',
    ePayType: undefined,
    ePayId: '',
    ePayName: '',
    checkNumber: '',
    wireNumber: '',
    email: '',
    memo: '',
  });

  paymentInfo.current = {
    ...paymentInfo.current,
    invoiceNumbers: orderData?.map((order: any) => order.invoiceNumber),
    amount: totalAmountDueOpenFiles,
  };

  // get e-pay accounts only for open orders
  const getEPaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getEpayAccount(token, userFirm?.firmId ?? '', ePayAccountsPayload);
  };
  const {
    execute: executeEPaymentAccounts,
    status: executeEPaymentDetailsStatus,
    value: ePaymentResults,
    errors: ePaymentAccountsErrors,
  } = useAsync<any>(getEPaymentDetails, false);

  const onSubmitPaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await onSubmitPayment(token, paymentInfo.current);
  };
  const {
    execute: executeSubmitPayment,
    status: executeSubmitPaymentDetailsStatus,
    value: submitPaymentResults,
    errors: submitPaymentErrors,
  } = useAsync<any>(onSubmitPaymentDetails, false);

  const getIframeData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getPaymentIframe(token, userFirm?.firmId ?? '', isAch, 'FAST');
  };

  const {
    execute: executeframeData,
    status: executeframeDataStatus,
    value: frameDataResults,
    errors: frameDataErrors,
  } = useAsync<any>(getIframeData, false);

  function processApiResponse(data: any) {
    if (data?.account) {
      setDefaultEpayAccount({
        name: data?.account?.counterPartyAccountName,
        value: data?.account?.counterPartyAccountId,
        type: data?.account?.counterPartyAccountType,
      });
      setOpenPaymentDrawer(false);
    }
  }

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event?.data) {
        processApiResponse(event?.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (isAllOpenStatus && !payManualRights) {
      executeEPaymentAccounts().then();
    }
  }, [isAllOpenStatus, defaultEpayAccount?.value]);

  useEffect(() => {
    if (openPaymentDrawer) {
      executeframeData().then();
    }
  }, [openPaymentDrawer, isAch]);

  useEffect(() => {
    mapEpayAccounts(ePaymentResults);
  }, [ePaymentResults]);

  function mapEpayAccounts(paymentAccounts: any): void {
    if (paymentAccounts) {
      let epayAccountNames = paymentAccounts?.accounts
        ?.map((account: any) => ({
          name: account.nameOnAccount,
          value: account.accountAliasId,
          type: account.paymentType,
        }))
        ?.sort((a: any, b: any) => a.name.localeCompare(b.name));
      epayAccountNames = isPremiumInvoice ? epayAccountNames?.filter((x: any) => x.type === 'ACH') : epayAccountNames;
      setEPayAccounts(epayAccountNames ?? []);
    }
  }
  useEffect(() => {
    const isError = executeEPaymentDetailsStatus === 'error' || executeSubmitPaymentDetailsStatus === 'error';
    setDisplayError(isError);

    if (executeSubmitPaymentDetailsStatus === 'success') {
      if (submitPaymentResults?.transmittalSheet)
        openDocument(submitPaymentResults?.transmittalSheet, DocumentType, newWindow, WindowFeatures);

      addSnackbarMessage({
        message: `Payment successful`,
        type: 'success',
      });
      goBackAction();
    }
  }, [executeEPaymentDetailsStatus, executeSubmitPaymentDetailsStatus]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [...(ePaymentAccountsErrors ?? []), ...(submitPaymentErrors ?? [])];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: any) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      setValidationErrors((prevErrs: any) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const handleOnChangePaymentDetails = (data: any) => {
    paymentInfo.current = {
      ...paymentInfo.current,
      ...data,
    };
  };

  const handleSubmitPayment = (doNotExecutePayment: boolean) => {
    if (doNotExecutePayment) {
      setShowValidation(true);
    } else {
      executeSubmitPayment().then();
    }
  };

  const closeDrawer = () => {
    setAch(false);
    setselectedPaymentMode(paymentMode[0]);
    setOpenPaymentDrawer(false);
  };
  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const paymentMethod = paymentMode?.find((option) => option.value === e.target.value) ?? selectedPaymentMode;
    setAch(paymentMethod.value === `ACH`);
    setselectedPaymentMode(paymentMethod);
  };

  const disablePayment =
    (orderData?.payment?.paymentMethod ? true : false) ||
    orderData?.orders?.[0]?.orderNumber?.toLowerCase().includes('order in process');

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.invoiceSummaryHeader}>
        <div>
          <Typography variant="h1" className={classes.mb1}>
            Invoice Summary
          </Typography>
          <AgentNetButton
            variant="text"
            size="medium"
            startIcon={<ArrowBack />}
            onClick={goBackAction}
            data-qa="SOBackToPayInvoices"
          >
            Back To Pay Invoices
          </AgentNetButton>
        </div>
      </div>
      <div className={classes.mainWrap}>
        <div className={classes.invoiceSummaryContent}>
          <ServiceOrderInvoiceSummaryTable invoices={orderData} />
        </div>
        <div className={classes.paymentComponent}>
          <PaymentComponent
            amountDue={totalAmountDueOpenFiles}
            checkNumber={orderData?.[0]?.payment?.checkNumber}
            email={orderData?.[0]?.payment?.email || profile?.emailAddress}
            memo={orderData?.[0]?.payment?.memo}
            showValidation={showValidation}
            paymentType={payManualRights ? 'Check' : orderData?.[0]?.payment?.paymentMethod ?? 'ePay'}
            ePayAccounts={ePayAccounts}
            validationErrors={validationErrors}
            setShowValidation={setShowValidation}
            getFilteredErrors={getFilteredErrors}
            externalPaymentOptions={paymentOptions}
            isPremiumInvoice={isPremiumInvoice}
            onSubmit={handleSubmitPayment}
            onChange={handleOnChangePaymentDetails}
            setOpenPaymentDrawer={setOpenPaymentDrawer}
            underwriter={'FAST'}
            showLoader={executeSubmitPaymentDetailsStatus === 'pending'}
            disabled={disablePayment}
          />
        </div>
      </div>
      <FormDrawerComponent
        title="Add New Payment Method"
        width={386}
        open={openPaymentDrawer}
        disableEnforceFocus
        crossQAPrefix="PaymentMethodDrawer"
        isPaddingRightZero
        showDrawerActions={false}
        onDismissAction={closeDrawer}
      >
        {executeframeDataStatus === 'pending' && <LoadingSpinner variant="circle" status={'pending'} />}
        {executeframeDataStatus === 'success' && (
          <>
            <Grid item sm={12} className={classes.heading}>
              <AgentNetDivider typoVariant="h3" title={'Payment Method'} disablePadding />
            </Grid>
            <Grid item sm={12}>
              <RadioGroup selected={selectedPaymentMode} options={paymentMode} onChange={handlePaymentMethod} />
            </Grid>
            <Grid item sm={12}>
              <iframe
                src={`${frameDataResults.sessionTransferUrl}`}
                className={classes.iframe}
                name="myiFrame"
                scrolling="no"
                height="800px"
                width="370px"
              ></iframe>
            </Grid>
          </>
        )}
      </FormDrawerComponent>
    </div>
  );
};

export default InvoiceSummary;
