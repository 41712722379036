import React, { useContext, useEffect, useMemo, useState } from 'react';
import DateFieldString from '../../../../ui-kit/inputs/DateField/DateFieldString';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, GridProps } from '@material-ui/core';
import { AgentNetTextInput } from '../../../../ui-kit/inputs/TextField';
import AgentNetDropdownSelector, { SelectOption } from '../../../../ui-kit/inputs/AgentNetDropdownSelector';
import ErrorSection from '../../file-create/ErrorSection';
import CurrencyField from '../../../../ui-kit/inputs/CurrencyField/CurrencyField';
import { TransactionTypeValue } from '../../../../api/file/constants';
import { FileDataContext, FileDataContextInterface } from '../../../../hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { transactionTypes } from '../../../constants';
import { format as formatDate } from 'date-fns';
import './FileInformationForm.scss';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import { fileInfoType } from 'api/file/interfaces/get-file';
import DeepSet from 'utilities/DeepSet';
import { getOfficeOptionsByState } from 'api/profile/profile-utilities';

interface FileInformationEditFormProps extends IAgentNetBaseFormGroupProps<fileInfoType> {
  ContainerComponentProps?: GridProps;
  isAdmin?: boolean;
}

const FileInfoEditForm: React.FC<FileInformationEditFormProps> = ({
  ContainerComponentProps,
  showAllValidation,
  value,
  onChange,
  validationErrors,
}: FileInformationEditFormProps) => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const {
    fileData,
    officeDisabled,
    resetFileNoDisable,
    underwriterDisabled,
    setResetFileNoDisable,
    setOfficeDisabled,
    setUnderwriterDisabled,
  } = fileDataCtx;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const [accountOptions, setAccountOptions] = useState<SelectOption[]>([]);

  /* Retrieve file office and userfirm offices to office dropdown */
  const getFileOffice = (): SelectOption | undefined => {
    const officeName = value && value?.office?.name;
    const officeVal = value && value?.officeId;
    if (officeName && officeVal) {
      return { name: officeName, value: officeVal.toString() };
    }
  };

  const getUserFirmOffices = (): Array<SelectOption> => {
    const officeList = getOfficeOptionsByState(userFirm, value.propertyState);
    const list = officeList ?? [];
    return list;
  };

  const getOffices = (): SelectOption[] => {
    const fileOffice = getFileOffice();
    const offices = fileOffice ? [fileOffice, ...getUserFirmOffices()] : getUserFirmOffices();
    const officeMap = offices.reduce((accumulator, office) => {
      const key = office.value ?? '';
      accumulator[key] = office;
      return { ...accumulator };
    }, {} as Record<string, SelectOption>);
    const finalOffice = Object.keys(officeMap).map((key) => {
      return officeMap[key];
    }) as SelectOption[];
    return (
      finalOffice.sort((a, b) => ((a.name as string) < (b.name as string) ? -1 : 0)) ?? [
        { name: 'No data found', value: '0' },
      ]
    );
  };

  const setUnderwriterOnChange = (
    accountOptions: {
      name: string;
      value: string;
    }[],
  ) => {
    const currentUnderwriter = value?.underwriterCode;
    const newUnderwriter = accountOptions.find((account) => account.value === currentUnderwriter);
    if (newUnderwriter) {
      onChange && onChange({ ...value, underwriterCode: newUnderwriter.value, underwriterName: newUnderwriter.name });
    } else if (accountOptions.length === 1) {
      onChange &&
        onChange({
          ...value,
          underwriterCode: accountOptions[0].value,
          underwriterName: accountOptions[0].name,
        });
    } else {
      onChange && onChange({ ...value, underwriterCode: '', underwriterName: '' });
    }
  };

  const fetchUnderWriterOptions = () => {
    if ((accountOptions ?? []).find((option) => option.value === value?.underwriterCode)) {
      return accountOptions;
    }
    const additionalOption =
      value?.underwriterName === '' && value?.underwriterCode === ''
        ? ''
        : { name: value.underwriterName, value: value.underwriterCode };
    return [...(accountOptions ?? []), additionalOption].filter((unique) => unique) as SelectOption[];
  };

  useEffect(() => {
    const offices = userFirm?.offices;
    const currentOffice =
      value?.officeId !== 0 ? offices?.find((office) => office.officeId === value?.officeId) : offices && offices[0];
    const accounts = currentOffice?.accounts ?? [];
    let accountOptions = accounts.map((account) => {
      return {
        name: account.underwriterName,
        value: account.underwriterCode,
        status: account.status,
        accountId: account.accountId,
      };
    });

    if (accountOptions.length > 0) {
      accountOptions = Array.from(new DeepSet(accountOptions)).sort((a, b) => (a.name < b.name ? -1 : 0));
      setAccountOptions([...accountOptions]);
    }

    accountOptions = Array.from(new DeepSet(accountOptions)).sort((a, b) => (a.name < b.name ? -1 : 0));
    setAccountOptions([...accountOptions]);
    setUnderwriterOnChange(accountOptions);
  }, [value?.officeId, userFirm]);

  const currentAccountId = useMemo(() => {
    const activeAccountId = accountOptions?.filter((account) => account.accountId === value?.accountId)?.[0];
    return activeAccountId?.status === 'Inactive' ? `${value.accountId} (Inactive)` : value?.accountId;
  }, [accountOptions, value?.accountId]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container {...ContainerComponentProps} spacing={3}>
        <Grid item sm={6}>
          <AgentNetTextInput
            fullWidth
            required
            variant="outlined"
            label="File Number"
            id="fileNumber"
            name="fileNumber"
            data-qa="FileInfoFileNumber"
            value={value ? value.fileNumber : ''}
            showValidation={showAllValidation}
            onChange={(e) => value && onChange && onChange({ ...value, fileNumber: e.target.value })}
            errs={validationErrors}
            resetDisabled={resetFileNoDisable}
            setResetDisabled={setResetFileNoDisable}
            disabled
            hideEditable={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
          />
        </Grid>
        <Grid item sm={6}>
          <AgentNetDropdownSelector
            name="officeId"
            qaAttribute="FileInfoOffice"
            label="Office"
            required
            showValidation={showAllValidation}
            options={getOffices()}
            value={value?.officeId}
            menuOption={(val: string) => {
              val && onChange && onChange({ ...value, officeId: Number(val) });
            }}
            errs={validationErrors}
            dropdowntype="outlined"
            onEditClick={() => {
              setOfficeDisabled && setOfficeDisabled(false);
            }}
            isFieldDisable={officeDisabled}
            hideEditable={true}
          />
        </Grid>

        <Grid item sm={6}>
          <AgentNetDropdownSelector
            name="underwriterCode"
            qaAttribute="FileInfoUnderwriter"
            label="Underwriter"
            required
            showValidation={showAllValidation}
            options={fetchUnderWriterOptions()}
            value={value?.underwriterCode ?? 0}
            id="underwriter"
            menuOption={(val: string) => {
              const underwriterName = accountOptions.find((account) => account.value === val)?.name;
              val && onChange && onChange({ ...value, underwriterCode: val, underwriterName: underwriterName });
            }}
            errs={validationErrors}
            dropdowntype="outlined"
            onEditClick={() => {
              setUnderwriterDisabled && setUnderwriterDisabled(false);
            }}
            isFieldDisable={underwriterDisabled}
            hideEditable={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
          />
          <ErrorSection errs={validationErrors} field={'underwriterName'} />
        </Grid>

        <Grid item sm={6}>
          <AgentNetTextInput
            fullWidth
            data-qa="FileInfoAccountNumber"
            required
            showValidation={showAllValidation}
            variant="outlined"
            label="Account Number"
            id="accountNumber"
            disabled
            value={value ? currentAccountId : ''}
            errs={validationErrors}
          />
        </Grid>

        <Grid item sm={3}>
          <AgentNetDropdownSelector
            disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
            name="transactionType"
            showValidation={showAllValidation}
            label="Transaction Type"
            options={transactionTypes}
            value={value ? value.transactionType : ''}
            id="transactionType"
            menuOption={(val: string) =>
              val && onChange && onChange({ ...value, transactionType: val as TransactionTypeValue })
            }
            errs={validationErrors}
            qaAttribute="FileInfoTransactionType"
            dropdowntype="outlined"
          />
        </Grid>

        <Grid item sm={3}>
          <CurrencyField
            disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
            variant="outlined"
            fullWidth
            label={'Sales Price'}
            id="salesprice"
            name={'salesPrice'}
            errs={validationErrors}
            max={100000000000}
            data-qa="FileInfoSalesPrice"
            allowNegative={false}
            value={value ? value.salesPrice : ''}
            onChange={(e) => {
              value && onChange && onChange({ ...value, salesPrice: +e.target.value });
            }}
          />
        </Grid>

        <Grid item sm={3}>
          <CurrencyField
            disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
            variant="outlined"
            fullWidth
            label={'Loan Amount'}
            id="totalLoanAmount"
            data-qa="FileInfoLoanAmount"
            name={'loanAmount'}
            value={value?.loanAmount}
            max={100000000000}
            allowNegative={false}
            onChange={(e) => {
              value && onChange && onChange({ ...value, loanAmount: +e.target.value });
            }}
          />
        </Grid>

        <Grid item sm={3}>
          <DateFieldString
            disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
            label="Closing Date"
            id="calender"
            qaAttribute="FileInfoClosingDate"
            maxDate="12/31/9999"
            value={(() => {
              const dateParsed = Date.parse(value?.closingDate || '');
              return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : value?.closingDate;
            })()}
            onChange={(dateVal) => {
              onChange && onChange({ ...value, closingDate: dateVal !== '' ? dateVal : null });
            }}
            error={validationErrors?.some((err) => err.field === 'closingDate') && showAllValidation}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FileInfoEditForm;
