import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AgentNetDropdownSelector, AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { format as formatDate } from 'date-fns';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface QuestionItemProps {
  randomId?: string;
  dataType: 'select' | 'currency' | 'text';
  description: string;
  header: string;
  options?: SelectOption[];
  onChange: (value: string) => void;
  errs?: boolean;
  value?: string;
  isDisabled?: boolean;
  additionalQuestionRef?: any;
  validationErrors: any;
  shouldShowValidation: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e0e0e0',
      padding: theme.spacing(1, 0),
    },
    description: {
      marginTop: theme.spacing(0.5),
    },
    required: {
      color: theme.palette.error.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
    error: {
      color: theme.palette.error.dark,
    },
  }),
);

const QuestionItem: React.FC<QuestionItemProps> = ({
  randomId,
  dataType,
  description,
  header,
  options,
  onChange,
  errs,
  value,
  isDisabled,
  additionalQuestionRef,
  validationErrors,
  shouldShowValidation,
}) => {
  const classes = useStyles();
  const questionRef = useRef<HTMLDivElement>(null);
  const [fieldValue, setFieldValue] = useState<string>(value ?? '');
  useEffect(() => {
    if (randomId) {
      additionalQuestionRef.current[randomId] = questionRef?.current;
    }
  }, [randomId]);

  const handleChange = (e: any) => {
    const newValue =
      dataType === 'currency' ? (e.target.value === '' ? '' : (e.target.value as unknown as number).toFixed(2)) : e;
    setFieldValue(newValue);
    onChange(newValue);
  };
  const isError =
    shouldShowValidation && (validationErrors ?? []).some((err: any) => err.field === 'question' + randomId);

  const renderInputFields = (dataType: string, id: string) => {
    switch (dataType) {
      case 'select':
        return (
          <AgentNetDropdownSelector
            dropdowntype="outlined"
            options={(options ?? []).map((x: any, i: any) => {
              return { name: x.text, value: x.value };
            })}
            name={'question' + id}
            value={fieldValue}
            menuOption={handleChange}
            error={errs}
            disabled={isDisabled}
            errs={validationErrors}
            showValidation={shouldShowValidation}
          />
        );
      case 'currency':
        return (
          <CurrencyField
            value={fieldValue}
            allowedBlank
            onChange={handleChange}
            variant="outlined"
            name={'question' + id}
            fullWidth
            required
            disabled={isDisabled}
            error={isError && shouldShowValidation}
            errs={validationErrors}
            alwaysDisplayError={shouldShowValidation}
            showValidationOnBlur={shouldShowValidation}
          />
        );
      case 'date':
        return (
          <DateField
            maxDate="12/31/9999"
            value={(() => {
              const dateParsed = Date.parse(fieldValue || '');
              return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : fieldValue;
            })()}
            onChange={handleChange}
            disabled={isDisabled}
            error={errs}
            name={'question' + id}
            errs={validationErrors}
            showValidation={shouldShowValidation}
          />
        );
      default:
        return (
          <AgentNetTextInput
            variant="outlined"
            value={fieldValue}
            name={'question' + id}
            disabled={isDisabled}
            onChange={(el) => {
              handleChange(el.target.value);
            }}
            fullWidth
            errs={validationErrors}
            showValidation={shouldShowValidation}
          />
        );
    }
  };

  return (
    <Grid container className={classes.root} ref={questionRef}>
      <Grid item sm={9}>
        <Typography variant="overline" color="textSecondary">
          {header}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          <span className={classes.required}>*</span>
          <span className={isError ? classes.error : ''}>{description}</span>
        </Typography>
      </Grid>
      <Grid item sm={3}>
        {renderInputFields(dataType, randomId ?? '')}
      </Grid>
    </Grid>
  );
};

export default QuestionItem;
