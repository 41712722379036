import React, { useEffect, useState } from 'react';
import { Order, OrderDocument } from './type';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import Uploader from 'ui-kit/components/uploader/Uploader';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import { FileWithPath } from 'react-dropzone';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { editServiceOrderMessage } from './constants';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { ServiceOrderUpdateSchema } from 'utilities/validation/schemas/serviceOrder-schema';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface EditOrderFormProps {
  order: Order;
  updateOrder: (id: any, value: any, field: string, isFieldValue: boolean, isDocumentDelete?: boolean) => void;
  setTriggerUpdateCall?: React.Dispatch<React.SetStateAction<boolean>>;
  updateAttempted: boolean;
  setUpdateAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  handleDrawerOpen: boolean;
  onDismissAction: () => void;
  onSecondaryAction: () => void;
  updateType?: string;
  handleUpdate?: boolean;
  isEdit: boolean;
}

const EditOrderForm = ({
  order,
  updateOrder,
  setTriggerUpdateCall,
  updateAttempted,
  setUpdateAttempted,
  handleDrawerOpen,
  onDismissAction,
  isEdit,
}: EditOrderFormProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      serviceOrdersWrap: {
        padding: pxToRem(0, 24, 48),
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      dropdown: {
        width: pxToRem(300),
        marginBottom: theme.spacing(2),
      },
      warningMsg: { marginBottom: theme.spacing(3) },
    }),
  );
  const [orderDetails, setOrderDetails] = useState(order);
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const { addSnackbarMessage } = useSnackBars();
  const [documents, setDocuments] = useState<OrderDocument[]>([]);
  const [documentUploadStatus, setDocumentUploadStatus] = useState('');
  const [email, setEmail] = useState(order.orderNotificationEmail);
  const classes = useStyles();
  const updateOptions = [
    { name: 'Amend', value: 'Amend' },
    { name: 'Date Down', value: 'Date Down' },
    { name: 'Update', value: 'Update' },
  ];
  const [updateDialog, setUpdateDialog] = React.useState<any>({
    id: undefined,
    note: '',
    emailNotification: order.orderNotificationEmail,
    updateType: undefined,
  });

  const validateErrors = async (order: Order) => {
    const errs: FieldValidationError[] = (await doValidate(order, ServiceOrderUpdateSchema)) || [];
    setValidationErrors(errs);
  };

  useEffect(() => {
    if (order) {
      if (!isEdit) {
        validateErrors(order);
      }
      setOrderDetails(order);
    }
  }, [order]);

  useEffect(() => {
    if (documents.length > 0) {
      updateOrder(orderDetails.randomId, documents, 'userUploadedDocuments', false);
      setDocumentUploadStatus('success');
    }
  }, [documents]);

  useEffect(() => {
    if (updateAttempted) {
      setShouldShowValidation(true);
      if (validationErrors?.length > 0) {
        addSnackbarMessage({
          message: `Edit Service Order Failed`,
          type: 'error',
        });
        setTriggerUpdateCall && setTriggerUpdateCall(false);
      } else {
        setTriggerUpdateCall && setTriggerUpdateCall(true);
      }
      setUpdateAttempted(false);
    }
  }, [updateAttempted]);

  const editServiceOrder = () => {
    setUpdateAttempted(true);
    if (!isEdit) {
      updateOrder(orderDetails.randomId, updateDialog.emailNotification, 'orderNotificationEmail', false);
    }
  };
  const handleDismiss = () => {
    onDismissAction();
    setUpdateDialog({ emailNotification: email, id: undefined });
  };

  async function getAsByteArray(file: Blob) {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  }

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const handleDocuments: any = async (uploads: FileWithPath[]) => {
    setDocumentUploadStatus('pending');
    const documentList: React.SetStateAction<OrderDocument[]> = [];
    const filePathsPromises: any[] = [];
    let i = 0;
    uploads.forEach((file) => {
      const document = {
        name: '',
        description: 'Agent Doc',
        mimeType: '',
        content: '',
        path: file.path,
      };
      document.name = file.name.split('.').slice(0, -1).join('.');
      document.mimeType = file.name.split('.').pop() ?? '';
      documentList.push(document);
      filePathsPromises.push(getAsByteArray(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    filePaths.forEach((e) => {
      documentList[i].content = e;
      ++i;
    });
    setDocuments(documentList);
  };

  const deleteDocument: any = async (file?: any) => {
    const documents = orderDetails.userUploadedDocuments.filter((e) => e.path !== file.path);
    updateOrder(orderDetails.randomId, documents, 'userUploadedDocuments', false, true);
  };

  return (
    <>
      {isEdit ? (
        <>
          <LoadingSpinner status={documentUploadStatus} variant="linear" />
          <FormDrawerComponent
            title="Edit Service Order"
            open={handleDrawerOpen}
            primaryActionLabel="Save Update"
            primaryActionProps={{
              disabled: updateDialog.note?.trim().length > 0 ? false : true,
              'data-qa': 'ServiceOrderEditSave',
            }}
            dismissActionProps={{ 'data-qa': 'ServiceOrderEditCancel' }}
            onPrimaryAction={editServiceOrder}
            onDismissAction={handleDismiss}
            onSecondaryAction={handleDismiss}
          >
            <Grid container>
              <Grid item className={classes.mb2} sm={12}>
                <Typography variant="body2">{editServiceOrderMessage}</Typography>
              </Grid>
              <Grid item className={classes.mb2} sm={12}>
                <AgentNetTextInput
                  Data-QA={'ServiceOrderEditNotes'}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  label={'Note'}
                  name="note"
                  value={updateDialog.note}
                  onChange={(e: any) => {
                    updateOrder(orderDetails.randomId, e.target.value, 'note', false);
                    setUpdateDialog({ ...updateDialog, note: e.target.value });
                  }}
                  required
                />
              </Grid>
              <Grid item className={classes.mb2} sm={12}>
                <Uploader
                  handleFiles={handleDocuments}
                  onDeleteItem={deleteDocument}
                  listTitle="Uploaded Document(s)"
                  hideInfo
                  acceptTypes={{
                    'application/pdf': ['.pdf'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/jpg': ['.JPG'],
                    'image/bmp': ['.bmp'],
                    'application/msword': ['.doc'],
                    'application/x-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/rtf': ['.rtf'],
                    'application/txt': ['.txt'],
                    'application/gif': ['.GIF'],
                    'application/msg': ['.msg'],
                    'application/htm': ['.htm'],
                    'application/png': ['.png'],
                    'application/xml': ['.xml'],
                  }}
                  drawerMode
                  Data-QA={'ServiceOrderEditDocuments'}
                />
              </Grid>
            </Grid>
          </FormDrawerComponent>
        </>
      ) : (
        <>
          <AgentNetConfirmationDialog
            onConfirm={editServiceOrder}
            onDismissAction={handleDismiss}
            open={handleDrawerOpen}
            size="sm"
            dialogTitle={'Request Update'}
            dialogBtnContent="Submit Request"
            qaAttrPrefix="ServiceOrderUpdate"
            disablePrimaryBtn={
              updateDialog.note?.trim().length > 0 &&
              updateDialog.updateType?.trim().length > 0 &&
              updateDialog.emailNotification?.trim().length > 0
                ? false
                : true
            }
            dialogTextHTML={
              <Grid container spacing={2}>
                <AgentNetDropdownSelector
                  name="updateType"
                  id="updateType"
                  required
                  label="Update Type"
                  dropdowntype="outlined"
                  options={updateOptions}
                  value={updateDialog.updateType}
                  menuOption={(value: any) => {
                    updateOrder(orderDetails.randomId, value, 'updateType', false);
                    setUpdateDialog({ ...updateDialog, updateType: value });
                  }}
                  className={classes.dropdown}
                  errs={validationErrors}
                  showValidation={shouldShowValidation}
                  qaAttribute={'ServiceOrderUpdateType'}
                />
                <AgentNetTextInput
                  fullWidth
                  variant="outlined"
                  label={'Email Notification'}
                  name="orderNotificationEmail"
                  Data-QA={'ServiceOrderUpdateEmail'}
                  required
                  value={updateDialog.emailNotification}
                  onChange={(e: any) => {
                    setUpdateDialog({ ...updateDialog, emailNotification: e.target.value });
                  }}
                  className={classes.mb2}
                  errs={validationErrors}
                  showValidation={shouldShowValidation}
                />
                <AgentNetTextInput
                  name="note"
                  required
                  label="Note"
                  multiline
                  fullWidth
                  value={updateDialog.note}
                  Data-QA={'ServiceOrderUpdateNotes'}
                  onChange={(e: any) => {
                    updateOrder(orderDetails.randomId, e.target.value, 'note', false);
                    setUpdateDialog({ ...updateDialog, note: e.target.value });
                  }}
                  className={classes.mb2}
                  rows={3}
                  errs={validationErrors}
                  showValidation={shouldShowValidation}
                />
              </Grid>
            }
          />{' '}
        </>
      )}
    </>
  );
};
export default EditOrderForm;
