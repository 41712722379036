import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { FieldValidationError } from 'utilities/validation/validation';
import ErrorSection from 'features/files/file-create/ErrorSection';
import PDFIcon from 'ui-kit/icons/PDF';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { pxToRem } from '@fluentsms/agentnet-web-components';
export interface SelectOption {
  name: string | undefined;
  // key will always be string but
  // value can be anything in a option
  // hence using any type
  [key: string]: any;
}
export interface AgentNetDropdownSelectorProps extends SelectProps {
  options: SelectOption[] | null;
  dropdowntype?: 'filled' | 'outlined';
  menuOption?: (value: any, name?: any) => void;
  styles?: string;
  className?: string;
  errs?: FieldValidationError[];
  showValidation?: boolean;
  defaultToFirst?: boolean;
  title?: string;
  isFieldDisable?: boolean;
  onEditClick?: any;
  qaAttribute?: any;
  hideEditable?: boolean;
  pdfIcon?: boolean;
  onPdfClick?: () => void;
  pdfQAAttribute?: string;
  pdfLabel?: string;
  compact?: boolean;
  isClearable?: boolean;
  primaryColor?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectDropdown: {
      flexGrow: 1,
      '&>div': {
        lineHeight: 'normal',
      },
    },
    pdf: {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    compact: {
      '&>div': {
        padding: '6px 12px',
      },
    },
    closeIcon: {
      '&:hover': {
        background: 'transparent',
      },
    },
    blue: {
      '&>div': {
        padding: '9px 12px',
        fontSize: pxToRem(13),
        color: theme.palette.primary.main,
        fontWeight: 600,
        minWidth: 200,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.statesPrimary.outlinedBorder,
      },
      '& .MuiSelect-icon': {
        borderLeft: `1px solid ${theme.palette.statesPrimary.outlinedBorder}`,
        transform: 'scale(1)',
        width: 40,
        right: 0,
        top: 0,
        height: 32,
      },
    },
  }),
);

const AgentNetDropdownSelector: React.FC<AgentNetDropdownSelectorProps> = (props: AgentNetDropdownSelectorProps) => {
  const {
    menuOption,
    label,
    error,
    options,
    value,
    className,
    dropdowntype = 'outlined',
    styles,
    required,
    errs,
    showValidation,
    name,
    defaultToFirst,
    isFieldDisable = false,
    title,
    onEditClick,
    qaAttribute,
    hideEditable,
    pdfIcon,
    onPdfClick,
    pdfQAAttribute,
    pdfLabel,
    compact,
    isClearable,
    primaryColor,
    ...rest
  } = props;
  const [displayErrors, setDisplayError] = useState(showValidation);
  const [errors, setErrors] = useState(false);
  const [isDropdownDisable, setDropdownDisable] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDropdownDisable(isFieldDisable);
  }, [isFieldDisable]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  const renderValue = () => {
    if (!defaultToFirst || (options && options.length > 1)) return value;
    if (options && options.length === 1) return options[0].value;
  };

  const renderName = (id: any) => {
    if (options && id) {
      const name = options.filter((option) => option.value == id)[0]?.name;
      return name ?? '';
    }
  };

  const [getValue, setGetValue] = useState(renderValue);
  useEffect(() => {
    setGetValue(renderValue());
  }, [value]);

  const handleClear = () => {
    if (menuOption) {
      menuOption('') as any;
    }
  };

  const val = value as string;
  const valTitle = val ? options?.find((opt) => opt.value === val) : undefined;

  const endAdornment =
    isClearable && value ? (
      <InputAdornment position="end">
        <IconButton
          className={classes.closeIcon}
          onClick={() => menuOption && menuOption('', name)}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <Close fontSize="large" />
        </IconButton>
      </InputAdornment>
    ) : null;

  return (
    <div>
      <FormControl
        variant="outlined"
        required={required}
        className={`MuiFormControl-root outlined-dropdown-selector ${styles} ${className} `}
        fullWidth
        {...(error && { error: true })}
      >
        <div className={classes.root}>
          <InputLabel shrink style={{ backgroundColor: '#ffffff' }}>
            {label}
          </InputLabel>
          {isDropdownDisable ? (
            <TextField
              InputProps={
                options && options.length > 1
                  ? {
                      endAdornment: hideEditable ? null : (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Edit Field"
                            onClick={onEditClick}
                            disableRipple
                            disableFocusRipple
                            style={{ padding: 0 }}
                          >
                            <Edit fontSize="large" color={isDropdownDisable ? 'disabled' : 'secondary'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
              {...(qaAttribute ? { 'data-qa': qaAttribute } : {})}
              value={renderName(getValue)}
              InputLabelProps={{ shrink: true }}
              error={errors && displayErrors}
              variant="outlined"
              label={label}
              disabled={true}
              className={classes.selectDropdown}
            />
          ) : (
            <Select
              {...rest}
              error={errors && displayErrors}
              onChange={(e) => menuOption && menuOption(e.target.value, name)}
              value={getValue}
              displayEmpty
              native={false}
              {...(qaAttribute ? { 'data-qa': qaAttribute } : {})}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              SelectDisplayProps={{
                title: valTitle ? valTitle.name : label ? label.toString() : '',
              }}
              className={clsx(classes.selectDropdown, { [classes.compact]: compact, [classes.blue]: primaryColor })}
              endAdornment={endAdornment}
            >
              {options?.map((item) => (
                <MenuItem data-option="AgentNetDropdownSelector" key={`${item.value}${item.name}`} value={item.value}>
                  {item.name}
                </MenuItem>
              )) ?? []}
            </Select>
          )}
          {pdfIcon && (
            <div title={pdfLabel ?? (label ? label.toString() : '')}>
              <PDFIcon className={classes.pdf} fontSize="large" onClick={onPdfClick} data-qa={pdfQAAttribute} />
            </div>
          )}
        </div>

        {errs && name && displayErrors && <ErrorSection errs={errs} field={name} />}
      </FormControl>
    </div>
  );
};

export default AgentNetDropdownSelector;
