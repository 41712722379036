import { getApiHost } from '../host-by-env';
import { firmSearchRequestType, firmSearchResponseType, lockboxNumberType } from '../../features/orderManagement/types';

export async function firmSearch(
  payload: firmSearchRequestType,
  token: string,
): Promise<{ result: firmSearchResponseType; errorCode?: string | number }> {
  const url = ` ${getApiHost()}order-management/firm-search`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getLockboxNumber(
  token: string,
): Promise<{ result: Array<lockboxNumberType>; errorCode?: string | number }> {
  const url = `${getApiHost()}order-management/lockbox-items?isActive=true`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const result = await response.json();
  for (const item of result.result) {
    item.name = item.display;
    item.value = item.lockboxNumber;
  }

  // sort by value
  result.result.sort((a: lockboxNumberType, b: lockboxNumberType) => a.value - b.value);

  return result;
}
