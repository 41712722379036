import React from 'react';
import clsx from 'clsx';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../routes/RouteWithSubRoutes';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

type RouteContentWrapperProps = {
  routes: Array<any>;
  className?: string;
};

const useStyles = makeStyles(() => ({
  coreContentContainer: {
    flex: 1,
  },
  fullHeight: {
    height: '100%',
  },
}));

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.
const NestedRouteLayout = ({ routes, className }: RouteContentWrapperProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.coreContentContainer, className && className)}>
      <Grid container className={classes.fullHeight}>
        <>
          <Grid item xs={12}>
            {/* ROUTES */}
            <Switch>
              {routes?.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} isNested />
              ))}
            </Switch>
          </Grid>
        </>
      </Grid>
    </div>
  );
};
export default NestedRouteLayout;
