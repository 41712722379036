import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { DollarField, DollarFieldProps } from '../DollarField';

const default_props = {
  thousandSeparator: true,
  decimalScale: 2,
  fixedDecimalScale: true,
};

type NumberMaskedFieldProps = DollarFieldProps &
  NumberFormatProps & {
    allowedBlank?: boolean;
    MaskedProps?: NumberFormatProps;
    max?: number;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  };

export type CurrencyFieldProps = NumberMaskedFieldProps;

const NumberMaskedField = ({ allowedBlank = false, MaskedProps, onChange, max, ...props }: any): JSX.Element => {
  const [internalValue, setInternalValue] = React.useState(props.value);

  React.useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  return (
    <NumberFormat
      {...MaskedProps}
      {...(props as NumberFormatProps)}
      customInput={DollarField}
      isAllowed={({ floatValue }) => (max && floatValue ? floatValue <= max : true)}
      //format={currencyFormatter}  TODO: have decimals be first on entry
      value={internalValue}
      onValueChange={(values) => {
        setInternalValue(values.value);
        if (!onChange) {
          return;
        }
        onChange({
          target: {
            name: props.name,
            value: allowedBlank && values.value === '' ? '' : Number(values.value),
          },
        });
      }}
    />
  );
};

export const CurrencyField = ({ MaskedProps, ...props }: NumberMaskedFieldProps): JSX.Element => {
  return (
    <NumberMaskedField
      MaskedProps={{
        ...default_props,
        ...MaskedProps,
      }}
      {...props}
    />
  );
};

export default CurrencyField;
