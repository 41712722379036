import React, { useState } from 'react';
import { AgentNetCheckBox } from 'ui-kit/inputs/Checkbox';
import { FormHelperText, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CoveredPartiesAmount, UnderwritingRequest } from './types';
import CurrencyField from 'ui-kit/inputs/CurrencyField/CurrencyField';

const useStyles = makeStyles((theme) => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  coveredParties: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  currencyhelpertext: {
    marginLeft: '0px',
    marginRight: '-2px',
    color: '#B3150A',
  },
}));

interface UnderwritingIncreaseCoveragelimitPartiesProps {
  uwr: UnderwritingRequest;
  index: number;
  coveredParties: string[];
  cplModCoveredParties: CoveredPartiesAmount[];
  cplModCheckBox: string;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
  handleCoveredParties: (
    index: number,
    cplModCheckBox: string,
    coveredParty: string,
    isChecked: boolean,
    currency?: string,
  ) => void;
  validateCoverageAmount: (field: string, value: string) => string;
}

const UnderwritingIncreaseCoveragelimitParties: React.FC<UnderwritingIncreaseCoveragelimitPartiesProps> = ({
  uwr,
  coveredParties,
  cplModCoveredParties,
  index,
  cplModCheckBox,
  isUwrDisabled,
  isDisabled,
  handleCoveredParties,
  validateCoverageAmount,
}: UnderwritingIncreaseCoveragelimitPartiesProps) => {
  const classes = useStyles();
  const [currencyValues, setCurrencyValues] = useState(
    coveredParties.map((partyName) => {
      const matchedParty = cplModCoveredParties.find((modParty) => modParty.CoveredPartyName === partyName);
      return matchedParty ? matchedParty.CoverageAmount || '0' : '0';
    }),
  );
  const [errors, setErrors] = useState(coveredParties.map(() => ''));
  const [touched, setTouched] = useState(coveredParties.map(() => false));

  const handleCurrencyChange = (
    index: any,
    subindex: any,
    currency: string,
    checked: boolean,
    CoveredPartyName: string,
  ) => {
    const newCurrencyValues = [...currencyValues];
    newCurrencyValues[subindex] = currency;
    setCurrencyValues(newCurrencyValues);

    if (cplModCoveredParties.length > 0) {
      handleCoveredParties(index, cplModCheckBox, CoveredPartyName, checked, currency);
    }
  };

  const handleBlur = (index: any) => {
    const newTouched = [...touched];
    newTouched[index] = true;
    setTouched(newTouched);
    const newErrors = [...errors];
    newErrors[index] = validateCoverageAmount(currencyValues[index], 'CoverageParties');
    setErrors(newErrors);
  };

  const handleCheck = (subindex: any, isChecked: boolean) => {
    if (!isChecked) {
      // Reset errors and touched states when the checkbox is unchecked
      const newErrors = [...errors];
      newErrors[subindex] = '';
      setErrors(newErrors);

      const newTouched = [...touched];
      newTouched[subindex] = false;
      setTouched(newTouched);

      const newCurrencyValues = [...currencyValues];
      newCurrencyValues[subindex] = '';
      setCurrencyValues(newCurrencyValues);
    }
  };
  return (
    <>
      <Typography variant="body1" className={classes.mb2}>
        Covered Parties{' '}
      </Typography>
      <Grid item sm={12} className={classes.coveredParties} style={{ marginLeft: '5px' }}>
        {coveredParties?.map((coveredParty, subindex) => (
          <Grid item key={subindex}>
            <AgentNetCheckBox
              style={{ marginLeft: '0px', marginRight: '-10px' }}
              key={coveredParty}
              value={cplModCoveredParties.some((cp) => cp.CoveredPartyName === coveredParty)}
              checkHandler={(val: boolean) => {
                handleCheck(subindex, val);
                handleCoveredParties(index, cplModCheckBox, coveredParty, val, currencyValues[subindex]);
              }}
              disabled={isUwrDisabled || isDisabled}
            />

            <CurrencyField
              disabled={
                !cplModCoveredParties.some((cp) => cp.CoveredPartyName === coveredParty) || isUwrDisabled || isDisabled
              }
              required={cplModCoveredParties.some((cp) => cp.CoveredPartyName === coveredParty)}
              variant="outlined"
              label={coveredParty}
              name={coveredParty}
              id={coveredParty}
              max={999999999999.99}
              allowNegative={false}
              style={{ width: '160px', marginRight: '15px' }}
              onChange={(e) => {
                handleCurrencyChange(
                  index,
                  subindex,
                  e.target.value,
                  cplModCoveredParties.some((cp) => cp.CoveredPartyName === coveredParty),
                  coveredParty,
                );
              }}
              value={currencyValues[subindex] ?? '0'}
              onBlur={() => handleBlur(subindex)}
              error={touched[subindex] && !!errors[subindex]}
              helperText={
                <FormHelperText style={{ marginLeft: '-12px', marginRight: '-20px', color: '#B3150A' }}>
                  {touched[subindex] && errors[subindex]}
                </FormHelperText>
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default UnderwritingIncreaseCoveragelimitParties;
