import React, { useContext, useMemo, useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import './InvoiceContent.scss';
import InvoicesList from 'features/ServiceOrderInvoices/InvoicesList';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { CircularProgress } from '@material-ui/core';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { pxToRem } from '@fluentsms/agentnet-web-components';
const InvoiceContent = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile } = profileCtx;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);
  const payRights = useMemo(() => {
    return (
      //payRights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 61)) ||
      //view pay rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 84)) ||
      //pay manual rights
      Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83))
    );
  }, [profile?.activityRights]);

  const getDefaultTab = () => {
    return payRights && location?.pathname.toLowerCase().includes('service-order-invoices/pay') ? 0 : 1;
  };

  const [selectedTab, setSelectedTab] = useState(getDefaultTab());

  useEffect(() => {
    setSelectedTab(getDefaultTab());
  }, [profile, location.pathname]);

  const handleTabChange = (tab: number) => {
    setSelectedTab(tab);
  };
  const tabName = selectedTab === 0 ? 'Pay' : 'View';

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <ContentContainer fullWidth>
      <div className={classes.contentWrap}>
        <InvoicesList
          tabSelection={selectedTab}
          tabName={tabName}
          contentClassName={classes.contentClass}
          handleTabChange={handleTabChange}
        />
      </div>
    </ContentContainer>
  );
};

export default InvoiceContent;
