import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormSection } from '../../../ui-kit/form/FormBody';
import { FormHeader } from '../../../ui-kit/form/FormHeader';
import FormDrawerBase, { FormDrawerProps } from './FormDrawerBase';
import './FormDrawerComponent.scss';
import { pxToRem } from '@fluentsms/agentnet-web-components';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootDrawer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: pxToRem(75, 24, 24),
    },
    rootWithoutRightPadding: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: pxToRem(85, 0, 24, 24),
    },
    asterik: {
      color: theme.palette.error.main,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export type FormDrawerComponentProps = FormDrawerProps & {
  title?: string;
  drawerComponentMounted?: () => void;
  disableCrossIconClick?: boolean;
  crossQAPrefix?: string;
  isPaddingRightZero?: boolean;
};
const FormDrawerComponent: React.FC<FormDrawerComponentProps> = (props: FormDrawerComponentProps) => {
  const {
    children,
    title,
    onDismissAction,
    drawerComponentMounted,
    disableCrossIconClick,
    crossQAPrefix,
    isPaddingRightZero,
  } = props;
  const classes = useStyles();
  useEffect(() => {
    if (drawerComponentMounted != undefined) {
      drawerComponentMounted();
    }
  }, []);
  const formDrawerProps = { ...props, header: 'hide-drawer-title' };
  return (
    <FormDrawerBase {...formDrawerProps}>
      <FormSection
        header={
          <FormHeader
            text={title ?? ''}
            crossQAPrefix={crossQAPrefix ?? ''}
            handleClose={onDismissAction}
            disableCrossIconClick={disableCrossIconClick}
          />
        }
        body={
          <div className={isPaddingRightZero ? classes.rootWithoutRightPadding : classes.rootDrawer}>{children}</div>
        }
      />
    </FormDrawerBase>
  );
};

export default FormDrawerComponent;
