import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { CurrencyField } from 'ui-kit/inputs';
import OverflowTip from 'ui-kit/components/utility/OverflowTip';
import { calculateTotals, dollarize, hideFieldsWithStatus } from 'utilities/utilities';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface ReportedFeesTableProps {
  products?: Array<any>;
  onChangeProduct?: (
    value: any,
    rateFeeLineItemId: string,
    field: string,
    childId?: string,
    nestedChildId?: string,
  ) => void;
  onClear?: (rateFeeLineItemId: string, field: string, childId?: string, nestedChildId?: string) => void;
  qaPrefix: string;
  showValidation?: boolean;
  displayNet?: boolean;
  disableFields?: boolean;
  hasRemittedProduct?: boolean;
}

const randomID = () => (Math.random() + 1).toString(36).substring(7);

const productsTest = [
  {
    rateFeeLineItemId: randomID(),
    rateFeeDescription: 'ALTA Loan Policy',
    grossReported: 0,
    netReported: 0,
    grossCalculated: 0,
    netCalculated: 0,
    isDisabled: false,
    isGrossOverridden: false,
    isNetOverridden: false,
    additionalFees: [
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: 'State Policy Fee',
        grossReported: 3,
        netReported: 2.4,
        grossCalculated: 3,
        netCalculated: 2.4,
        isDisabled: true,
        isGrossOverridden: false,
        isNetOverridden: false,
      },
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: '[ALTA 6.2-06] Variable Mortgage Rate - Negative Amorization',
        grossReported: 55,
        netReported: 44,
        grossCalculated: 55,
        netCalculated: 44,
        isDisabled: false,
        isGrossOverridden: false,
        isNetOverridden: false,
        additionalFees: [
          {
            rateFeeLineItemId: randomID(),
            rateFeeDescription: 'Municipality Fee',
            grossReported: 3,
            netReported: 2.4,
            grossCalculated: 3,
            netCalculated: 2.4,
            isDisabled: true,
            isGrossOverridden: false,
            isNetOverridden: false,
          },
        ],
      },
      {
        rateFeeLineItemId: randomID(),
        rateFeeDescription: '[ALTA 3.3-06] Zoning - Completed Improvement - Non-conf...',
        grossReported: 25,
        netReported: 32,
        grossCalculated: 25,
        netCalculated: 32,
        isDisabled: false,
        isGrossOverridden: false,
        isNetOverridden: false,
      },
    ],
  },
  {
    rateFeeLineItemId: randomID(),
    rateFeeDescription: 'StandardCPL',
    grossReported: 20,
    netReported: 16,
    grossCalculated: 20,
    netCalculated: 16,
    isDisabled: false,
    isGrossOverridden: false,
    isNetOverridden: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportedFeesTable: {
      width: '100%',
      margin: '8px 10px 32px 8px',
    },
    remittedFeesTable: {
      width: '100%',
      margin: '8px 10px 16px 8px',
    },
    reportedFeesTableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: pxToRem(14),
    },
    reportedFeesTableHeaderCell: {
      padding: pxToRem(12, 0, 8),
    },
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
      minHeight: '56px',
    },
    tableCell: {
      padding: pxToRem(8),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tableCellRight: {
      textAlign: 'right',
    },
    indented: {
      paddingLeft: theme.spacing(3),
    },
    indented2: {
      paddingLeft: theme.spacing(5),
    },
    totalsRow: {
      borderTop: '1px solid #616161',
      borderBottom: '1px solid #E0E0E0',
    },
    totalsCell: {
      padding: theme.spacing(1),
    },
    weight500: {
      fontWeight: 500,
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    isTouched: {
      backgroundColor: '#FDF8CF',
    },
    isError: {
      backgroundColor: '#FDECEA !important',
    },
  }),
);

const InputFields = ({
  data,
  qaPrefix,
  qaSuffix,
  displayNet,
  disableFields,
  showValidation,
  onGrossChangeProduct,
  onNetChangeProduct,
  onGrossClearProduct,
  onNetClearProduct,
  hasRemittedProduct,
}: any) => {
  const classes = useStyles();

  if (!hasRemittedProduct && hideFieldsWithStatus.includes(data.status)) {
    return null;
  }

  return (
    <>
      <Grid item sm={3} className={clsx(classes.tableCell)}>
        <CurrencyField
          variant="outlined"
          value={data.grossReported}
          fullWidth
          max={100000000000}
          allowNegative={false}
          data-qa={`${qaPrefix}Gross${qaSuffix}`}
          onChange={onGrossChangeProduct}
          className={clsx({ [classes.isTouched]: data.isGrossOverridden === true })}
          disabled={disableFields || data.isDisabled}
          InputProps={
            data.isGrossOverridden && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={onGrossClearProduct}
                    edge="end"
                  >
                    <Clear htmlColor="#666666" fontSize="large" data-qa={`${qaPrefix}DeleteGross${qaSuffix}`} />
                  </IconButton>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      {displayNet && (
        <Grid item sm={3} className={clsx(classes.tableCell)}>
          <CurrencyField
            variant="outlined"
            value={data.netReported}
            error={data.isError && showValidation}
            fullWidth
            max={100000000000}
            data-qa={`${qaPrefix}Net${qaSuffix}`}
            allowNegative={false}
            onChange={onNetChangeProduct}
            className={clsx({
              [classes.isTouched]: data.isNetOverridden === true,
              [classes.isError]: data.isError && showValidation,
            })}
            disabled={disableFields || data.isDisabled}
            InputProps={
              data.isNetOverridden && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={onNetClearProduct}
                      edge="end"
                    >
                      <Clear htmlColor="#666666" fontSize="large" data-qa={`${qaPrefix}DeleteNet${qaSuffix}`} />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid>
      )}
    </>
  );
};

const ReportedFeesTable = ({
  onChangeProduct,
  products = productsTest,
  onClear,
  qaPrefix,
  showValidation,
  displayNet = true,
  disableFields = true,
  hasRemittedProduct = false,
}: ReportedFeesTableProps) => {
  const classes = useStyles();
  const [grandTotal, setGrandTotal] = useState({
    gross: 0,
    net: 0,
  });
  useEffect(() => {
    setGrandTotal(calculateTotals(products, hasRemittedProduct));
  }, [products, hasRemittedProduct]);

  return (
    <>
      {products && products.length > 0 && (
        <div className={hasRemittedProduct ? classes.remittedFeesTable : classes.reportedFeesTable}>
          <Grid container className={classes.reportedFeesTableHeader}>
            <Grid item sm={displayNet ? 6 : 9} className={classes.reportedFeesTableHeaderCell}>
              Product
            </Grid>
            <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
              Gross Reported
            </Grid>
            {displayNet && (
              <Grid item sm={3} className={classes.reportedFeesTableHeaderCell}>
                Net Reported
              </Grid>
            )}
          </Grid>
          <Grid container>
            {products.map((e: any, i) => (
              <>
                <Grid container key={e.rateFeeLineItemId} className={classes.tableRow}>
                  <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell)}>
                    <OverflowTip>
                      <Typography variant="body2">{e.rateFeeDescription}</Typography>
                    </OverflowTip>
                  </Grid>
                  <InputFields
                    data={e}
                    qaPrefix={qaPrefix}
                    qaSuffix={i + 1}
                    displayNet={displayNet}
                    disableFields={disableFields}
                    showValidation={showValidation}
                    hasRemittedProduct={hasRemittedProduct}
                    onGrossChangeProduct={(el: any) => {
                      onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'grossReported');
                    }}
                    onNetChangeProduct={(el: any) => {
                      onChangeProduct && onChangeProduct(el.target.value, e.rateFeeLineItemId, 'netReported');
                    }}
                    onGrossClearProduct={() => onClear?.(e.rateFeeLineItemId, 'grossReported')}
                    onNetClearProduct={() => onClear?.(e.rateFeeLineItemId, 'netReported')}
                  />
                </Grid>
                {e.additionalFees &&
                  e.additionalFees.map((c: any, j: any) => (
                    <>
                      <Grid container key={c.rateFeeLineItemId} className={classes.tableRow}>
                        <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell, classes.indented)}>
                          <OverflowTip>
                            <Typography variant="body2">{c.rateFeeDescription}</Typography>
                          </OverflowTip>
                        </Grid>
                        <InputFields
                          data={c}
                          qaPrefix={qaPrefix}
                          qaSuffix={`${i + 1}${j + 1}`}
                          displayNet={displayNet}
                          disableFields={disableFields}
                          showValidation={showValidation}
                          hasRemittedProduct={hasRemittedProduct}
                          onGrossChangeProduct={(el: any) => {
                            onChangeProduct?.(
                              el.target.value,
                              e.rateFeeLineItemId,
                              'grossReported',
                              c.rateFeeLineItemId,
                            );
                          }}
                          onNetChangeProduct={(el: any) => {
                            onChangeProduct?.(el.target.value, e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId);
                          }}
                          onGrossClearProduct={() =>
                            onClear?.(e.rateFeeLineItemId, 'grossReported', c.rateFeeLineItemId)
                          }
                          onNetClearProduct={() => onClear?.(e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId)}
                        />
                      </Grid>
                      {c.additionalFees &&
                        c.additionalFees.map((z: any, k: any) => (
                          <Grid container key={z.rateFeeLineItemId} className={classes.tableRow}>
                            <Grid item sm={displayNet ? 6 : 9} className={clsx(classes.tableCell, classes.indented2)}>
                              <OverflowTip>
                                <Typography variant="body2">{z.rateFeeDescription}</Typography>
                              </OverflowTip>
                            </Grid>
                            <InputFields
                              data={z}
                              qaPrefix={qaPrefix}
                              qaSuffix={`${i + 1}${j + 1}${k + 1}`}
                              displayNet={displayNet}
                              disableFields={disableFields}
                              showValidation={showValidation}
                              hasRemittedProduct={hasRemittedProduct}
                              onGrossChangeProduct={(el: any) => {
                                onChangeProduct?.(
                                  el.target.value,
                                  e.rateFeeLineItemId,
                                  'grossReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                );
                              }}
                              onNetChangeProduct={(el: any) => {
                                onChangeProduct?.(
                                  el.target.value,
                                  e.rateFeeLineItemId,
                                  'netReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                );
                              }}
                              onGrossClearProduct={() =>
                                onClear?.(
                                  e.rateFeeLineItemId,
                                  'grossReported',
                                  c.rateFeeLineItemId,
                                  z.rateFeeLineItemId,
                                )
                              }
                              onNetClearProduct={() =>
                                onClear?.(e.rateFeeLineItemId, 'netReported', c.rateFeeLineItemId, z.rateFeeLineItemId)
                              }
                            />
                          </Grid>
                        ))}
                    </>
                  ))}
              </>
            ))}
            <Grid container className={classes.totalsRow}>
              <Grid item sm={displayNet ? 6 : 9} className={classes.totalsCell}>
                <Typography variant="body2" className={classes.weight500}>
                  Grand Total:
                </Typography>
              </Grid>
              <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                <Typography variant="body2" data-qa={`${qaPrefix}GrossTotal`} className={classes.weight500}>
                  {dollarize(grandTotal.gross)}
                </Typography>
              </Grid>
              {displayNet && (
                <Grid item sm={3} className={clsx(classes.totalsCell, classes.tableCellRight)}>
                  <Typography variant="body2" data-qa={`${qaPrefix}NetTotal`} className={classes.weight500}>
                    {dollarize(grandTotal.net)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReportedFeesTable;
