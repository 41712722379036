import React, { useContext, useEffect, useState } from 'react';
import { getFileInfoProps } from 'api/file/file-api';
import { useLocation, useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import './JacketsContainer.scss';
import { Jacket, StandaloneEndorsement, Warning } from './types';
import { useAuth } from '@agentnet/auth';
import { getJackets, getStandaloneEndorsements } from 'api/jacket-api';
import useAsync from 'hooks/useAsync';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import JacketFC from './Jacket';
import { getPropertyCounty } from 'features/files/file-utilities';
import StandaloneEndorsementFC from './StandaloneEndorsement';
import { useViewState } from '../../hooks/ViewStateContext';
interface JacketsContainerProps {
  showStandAlone?: boolean | false;
}
const JacketsContainer: React.FC<JacketsContainerProps> = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
    }),
  );
  const classes = useStyles();
  const { fileId } = useParams<getFileInfoProps>();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData, setFileData, loadFileOrderInsightsData } = fileDataCtx;

  const { getAccessToken } = useAuth();
  interface CustomizedState {
    showSE: string;
  }
  const location = useLocation();
  const state = location.state as CustomizedState;
  const myState = state?.showSE;

  const [showJacket, setShowJacket] = useState(false);
  const [isAddNewJacket, setIsAddNewJacket] = useState(false);
  const [showJacketList, setShowJacketList] = useState(false);
  const [showSaEndorsement, setShowSaEndorsement] = useState<boolean>(false);
  const [standaloneEndorsements, setStandaloneEndorsements] = useState<StandaloneEndorsement[]>([]);

  const [warnings, setWarning] = useState<Warning[]>([]);
  const [showWarnings, setShowWarnings] = useState(false);
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue]);

  const handleNewJacket = () => {
    setShowJacketList(false);
    setShowJacket(true);
    setIsAddNewJacket(true);
    setShowWarnings(false);
  };

  const handleStandaloneDelete = () => {
    setStandaloneEndorsements([]);
    setShowSaEndorsement(false);
  };

  const hideCreateJacket = () => {
    if (getJacketsValue && getJacketsValue.length > 0) {
      setShowJacketList(true);
    }
    setShowJacket(false);
    setIsAddNewJacket(false);
  };

  const refreshJacketsData = () => {
    executeGetJackets().then();
    hideCreateJacket();
  };

  const addWarnings = (warnings: Warning[]) => {
    if (warnings) {
      setWarning(warnings);
      setShowWarnings(true);
    } else {
      setWarning([]);
      setShowWarnings(false);
    }
  };

  const hideWarningOnCancel = (isWarning: boolean) => {
    {
      isWarning ? setShowWarnings(true) : setShowWarnings(false);
    }
  };

  const fetchJackets = async (): Promise<Jacket[]> => {
    const token = await getAccessToken();
    return getJackets(fileId ?? '', token);
  };

  const {
    execute: executeGetJackets,
    value: getJacketsValue,
    status: getJacketsStatus,
  } = useAsync<Jacket[]>(fetchJackets, false);

  useEffect(() => {
    if (!getJacketsValue) executeGetJackets().then();
  }, [fileId]);

  useEffect(() => {
    if (myState) {
      setShowSaEndorsement(true);
    }
  }, [myState]);

  useEffect(() => {
    if (getJacketsValue && getJacketsValue.length > 0) {
      const hasNonVoidJackets = getJacketsValue.some(
        (jacket) => !['Void', 'Pending Void'].includes(jacket.policyStatus),
      );
      if (hasNonVoidJackets) {
        setFileData &&
          setFileData((prevFileData: any) => ({
            ...prevFileData,
            serviceStatus: {
              ...prevFileData.serviceStatus,
              hasActiveJacket: true,
            },
          }));
      }
      setShowJacketList(true);
      setShowJacket(false);
      setShowSaEndorsement(false);
    } else if (getJacketsValue?.length == 0 && getJacketsStatus !== 'idle') {
      handleNewJacket();
    }
  }, [getJacketsValue]);

  const fetchStandaloneEndorsements = async (): Promise<StandaloneEndorsement[]> => {
    const token = await getAccessToken();
    return getStandaloneEndorsements(fileId ?? '', token);
  };

  const {
    execute: executeGetSaEndorsements,
    value: getSaEndorsementsValue,
    status: getSaEndorsementsStatus,
  } = useAsync<StandaloneEndorsement[]>(fetchStandaloneEndorsements, false);

  useEffect(() => {
    if (!getSaEndorsementsValue) executeGetSaEndorsements().then();
  }, [fileId]);

  useEffect(() => {
    if (getSaEndorsementsValue && getSaEndorsementsValue.length > 0) {
      setFileData &&
        setFileData((prevFileData: any) => ({
          ...prevFileData,
          serviceStatus: {
            ...prevFileData.serviceStatus,
            hasStandAloneEndorsement: true,
          },
        }));
      setStandaloneEndorsements(getSaEndorsementsValue);
      setShowSaEndorsement(true);
    }
  }, [getSaEndorsementsValue]);

  return (
    <>
      <div className={classes.root}>
        <div className="paper-layout contained">
          <div className={getJacketsStatus === 'pending' ? 'loading-container' : ''}>
            <LoadingSpinner status={getJacketsStatus} size={30} />
          </div>
          {!showSaEndorsement &&
            showJacketList &&
            getJacketsStatus !== 'pending' &&
            standaloneEndorsements?.length === 0 && (
              <JacketFC
                fileId={fileId}
                state={fileData?.propertyState}
                county={getPropertyCounty(fileData ?? {})}
                underwriter={fileData?.underwriterCode}
                setJacketFlag={setShowJacket}
                accountNumber={fileData?.accountId}
                jacketList={getJacketsValue ?? []}
                fileData={fileData}
                loadFileOrderInsightsData={loadFileOrderInsightsData}
                isAddNewJacketClicked={isAddNewJacket}
                lenders={fileData?.lenders}
                OnCancel={hideCreateJacket}
                handleAddNewJacket={handleNewJacket}
                refreshJacketsData={refreshJacketsData}
                addWarnings={addWarnings}
                warnings={warnings}
                hideWarningOnCancel={hideWarningOnCancel}
                showWarnings={showWarnings}
              />
            )}
          {showSaEndorsement && getJacketsStatus !== 'pending' && (
            <StandaloneEndorsementFC
              saEndorsements={standaloneEndorsements ?? []}
              fileId={fileId}
              state={fileData?.propertyState}
              underwriter={fileData?.underwriterCode}
              setStandaloneFlag={setShowSaEndorsement}
              handleDelete={handleStandaloneDelete}
              filestatus={fileData?.fileStatus}
            />
          )}

          {getSaEndorsementsStatus !== 'pending' &&
            !showSaEndorsement &&
            showJacket &&
            standaloneEndorsements?.length === 0 && (
              <JacketFC
                fileId={fileId}
                state={fileData?.propertyState}
                county={getPropertyCounty(fileData ?? {})}
                underwriter={fileData?.underwriterCode}
                setJacketFlag={setShowJacket}
                accountNumber={fileData?.accountId}
                jacketList={getJacketsValue ?? []}
                isAddNewJacketClicked={isAddNewJacket}
                lenders={fileData?.lenders}
                OnCancel={hideCreateJacket}
                refreshJacketsData={refreshJacketsData}
                fileData={fileData}
                loadFileOrderInsightsData={loadFileOrderInsightsData}
                addWarnings={addWarnings}
                warnings={warnings}
                hideWarningOnCancel={hideWarningOnCancel}
                showWarnings={showWarnings}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default JacketsContainer;
