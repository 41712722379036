import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AgentNetCheckBox, AgentNetTextInput, CurrencyField } from '../../../ui-kit/inputs';
import DomesticAddressField from './DomesticAddress';
import { lenderDomesticAddressSchema, lenderSchemaForNameOnly } from '../../../utilities/validation/schemas';
import PhoneField from '../../../ui-kit/inputs/PhoneField/PhoneField';
import AgentNetDropdownSelector, { SelectOption } from '../../../ui-kit/inputs/AgentNetDropdownSelector';
import { mortgageClause, MortgageClauseTextOptions } from '../parties/form/MortgageClause';
import { LenderClauseTypeValue, MyLenderDropdown } from '../../../api/file/constants';
import { FileCreateContext, FileCreateContextInterface } from '../../../hooks/FileCreateContext';
import { IGetLenderResponse } from '../parties/interfaces/CreateLenderRequest';
import { ICreateFileResponse } from '../../../api/file/interfaces/create-file';
import AutocompleteSearch from '../../../ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import { getLenderListFromFirmID } from '../../../api/file/file-party-api';
import { ProfileContext, ProfileContextInterface } from '../../../hooks/ProfileContext';
import { useAuth } from '@agentnet/auth';
import useAsync from '../../../hooks/useAsync';
import ErrorSection from './ErrorSection';
import { ILenderFirm } from '../parties/interfaces/LenderModels';
import { getResetLender, updateLenderWithFirm } from '../file-utilities';
import { DefaultLender } from '../../../models/create-file-model';
import { sortLenders } from 'api/profile/profile-utilities';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

type CreateLenderProps = {
  setIsFormValid?: (isValid: boolean) => void;
  showAllValidation?: boolean;
  isCreateForm?: boolean;
};

const CreateLender: React.FC<CreateLenderProps> = ({
  setIsFormValid,
  showAllValidation,
  isCreateForm = false,
}: CreateLenderProps) => {
  const { getAccessToken } = useAuth();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const fileCreateCtx: FileCreateContextInterface = useContext(FileCreateContext) ?? {};
  const { fileCreateData, setFileCreateData } = fileCreateCtx;
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  if (!fileCreateData || !setFileCreateData) return <></>;
  const [isAddressFormGroupValid, setIsAddressFormGroupValid] = useState<boolean>(false);

  // The first lender in the collection is the one we are working with
  const lender: IGetLenderResponse = fileCreateData.lenders ? fileCreateData.lenders[0] : {};
  const {
    name,
    attention,
    addToAddressBook,
    lenderTypeOptions, // this is actually a string, name of the selected My Lender
    address,
    phoneNumber,
    emailAddress,
    lenderClauseType,
    lenderClauseText,
    loanNumber,
    loanAmount,
  } = lender;

  /* Update the lenders array in file data context. It's an array of one lender. */
  const updateLenderData = (newLenderData: IGetLenderResponse) => {
    setFileCreateData({ ...fileCreateData, lenders: [newLenderData] } as ICreateFileResponse);
  };

  const [lenderFirmList, setLenderFirmList] = useState<ILenderFirm[]>([]);

  const getLenderFirmList = async (): Promise<ILenderFirm[]> => {
    const token = await getAccessToken();
    const firmId = userFirm?.firmId ?? '';
    return await getLenderListFromFirmID(firmId as string, token);
  };

  const { execute: execGetLenderFirms, value: respGetLenderFirms } = useAsync<ILenderFirm[]>(getLenderFirmList, false);

  // Update the lenders when response updates
  useEffect(() => {
    if (respGetLenderFirms) setLenderFirmList([...respGetLenderFirms]);
  }, [respGetLenderFirms]);

  // Get the lenders on load
  useEffect(() => {
    execGetLenderFirms().then();
  }, []);

  const noMatchingLenderOption: SelectOption = {
    name: MyLenderDropdown.noMatchingLender,
    value: MyLenderDropdown.noMatchingLender,
  };

  const tempLenderOptions = sortLenders(lenderFirmList);

  const lenderOptions: SelectOption[] = tempLenderOptions ? [noMatchingLenderOption, ...tempLenderOptions] : [];

  /** Get Lender data for given lenderName, use data to fill necessary form fields */
  const handleLenderInfo = (lenderSelection: string) => {
    if (lenderSelection === '') {
      /* Clear the lender stuff if they clicked the X */
      /* Set the lender data to default, and also clear the file's loan amount */
      setFileCreateData({
        ...fileCreateData,
        lenders: [DefaultLender],
      } as ICreateFileResponse);
    } else {
      const lenderFirm: ILenderFirm | undefined = lenderOptions
        ? lenderFirmList?.find((lender) => String(lender.AddressBookEntryId) === String(lenderSelection))
        : undefined;
      if (lenderFirm || lenderSelection === MyLenderDropdown.noMatchingLender) {
        let updated: IGetLenderResponse = {};
        if (lenderSelection === MyLenderDropdown.noMatchingLender) {
          updated = getResetLender(lender, isCreateForm);
        } else {
          updated = updateLenderWithFirm(lender, lenderFirm);
        }
        // Also update with the selection they made
        updated.lenderTypeOptions = lenderSelection;
        updated.loanAmount = fileCreateData.loanAmount;
        updated.loanNumber = '';
        updated.lenderClauseType = updated.lenderClauseType ? updated.lenderClauseType : '';
        updated.lenderClauseText = updated.lenderClauseText ? updated.lenderClauseText : '';
        updateLenderData(updated);
      }
    }
  };

  useEffect(() => {
    if (lenderTypeOptions === MyLenderDropdown.noMatchingLender) {
      validateErrors(lender);
    } else {
      setIsFormValid && setIsFormValid(true);
    }
  }, [lender, showAllValidation, lenderTypeOptions, isAddressFormGroupValid]);

  const validateErrors = async (lender: IGetLenderResponse) => {
    const errs = (await doValidate(lender, lenderSchemaForNameOnly)) || [];
    setValidationErrors(errs);
    setIsFormValid && setIsFormValid(errs.length === 0 && isAddressFormGroupValid);
  };

  const onLenderClauseTypeChange = (type: string) => {
    for (const obj in MortgageClauseTextOptions) {
      if (obj === type) {
        if (type === 'MERS') {
          updateLenderData({
            ...lender,
            lenderClauseType: type,
            lenderClauseText: MortgageClauseTextOptions[obj].value?.replace('{{ COMPANY NAME }}', String(lender?.name)),
          });
        } else {
          updateLenderData({
            ...lender,
            lenderClauseType: type,
            lenderClauseText: MortgageClauseTextOptions[obj].value,
          });
        }
      }
    }
  };
  return (
    <>
      <section className="lender-form-section">
        <Grid container spacing={3} className="lender-form-container">
          <Grid item xs={6} className="lender-form">
            <AutocompleteSearch
              label="My Lenders"
              // required={!displayDefaultLenderOption}
              // error={
              //   validationErrors?.some((err) => err.field === 'lenderTypeOptions') &&
              //   showAllValidation &&
              //   myLendersRequired
              // }
              errs={validationErrors}
              // showValidation={showAllValidation}
              name="lenderTypeOptions"
              options={lenderOptions}
              disableGrouping
              hideSearchIcon
              onChange={(val) => handleLenderInfo(val)}
              value={lenderTypeOptions ?? ''}
              hideNoMatches
              {...(isCreateForm ? { 'data-qa': 'CreateFileMyLenders' } : {})}
            />
            {/*{validationErrors?.some((err) => err.field === 'lenderTypeOptions') &&*/}
            {/*  myLendersRequired &&*/}
            {/*  showAllValidation && <ErrorSection errs={validationErrors} field={'lenderTypeOptions'} />}*/}
          </Grid>

          {/* HIDE MOST OF THE FORM UNTIL MY LENDER HAS BEEN SELECTED */}
          {lenderTypeOptions !== undefined && lenderTypeOptions !== '' && (
            <>
              <Grid item xs={3}>
                <AgentNetTextInput
                  variant="outlined"
                  fullWidth
                  label={'Loan Number'}
                  value={loanNumber}
                  name="loanNumber"
                  errs={validationErrors}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateLenderData({ ...lender, loanNumber: e.target.value });
                  }}
                  {...(isCreateForm ? { 'data-qa': 'CreateFileLenderLoanNumber' } : {})}
                />
              </Grid>

              <Grid item xs={3}>
                <CurrencyField
                  variant="outlined"
                  fullWidth
                  label={'Loan Amount'}
                  id="loanAmount"
                  defaultValue={0}
                  name={'loanAmount'}
                  errs={validationErrors}
                  value={loanAmount}
                  max={100000000000}
                  allowNegative={false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    /* Need to update the lender amount AND the amount on the FILE */
                    const updatedLoanAmount = e.target.value;
                    const updatedLender: IGetLenderResponse = { ...lender, loanAmount: updatedLoanAmount };
                    setFileCreateData({
                      ...fileCreateData,
                      lenders: [updatedLender],
                      loanAmount: updatedLoanAmount,
                    } as ICreateFileResponse);
                  }}
                  {...(isCreateForm ? { 'data-qa': 'CreateFileLenderLoanAmount' } : {})}
                />
                <ErrorSection errs={validationErrors} field={'loanAmount'} />
              </Grid>

              {/* ONLY SHOW IF ITS NO MATCHING LENDER */}
              {lenderTypeOptions === MyLenderDropdown.noMatchingLender && (
                <>
                  <Grid item xs={6}>
                    <AgentNetTextInput
                      variant="outlined"
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label={'Company Name'}
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updated = {
                          ...lender,
                          name: e.target.value,
                          ...(lender.lenderClauseType === LenderClauseTypeValue.mers
                            ? {
                                lenderClauseText: MortgageClauseTextOptions[LenderClauseTypeValue.mers].value?.replace(
                                  '{{ COMPANY NAME }}',
                                  String(e.target.value.trim()),
                                ),
                              }
                            : {}),
                        };
                        updateLenderData(updated);
                      }}
                      errs={validationErrors}
                      showValidation={showAllValidation}
                      {...(isCreateForm ? { 'data-qa': 'CreateFileLenderCompanyName' } : {})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AgentNetTextInput
                      variant="outlined"
                      fullWidth
                      errs={validationErrors}
                      // showValidation={showAllValidation}
                      label={'Attention'}
                      value={attention}
                      name="attention"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updated = { ...lender, attention: e.target.value };
                        updateLenderData(updated);
                      }}
                      {...(isCreateForm ? { 'data-qa': 'CreateFileLenderAttention' } : {})}
                    />
                  </Grid>

                  {/* Add to Lender checkbox */}
                  <Grid item xs={12}>
                    <div>
                      <AgentNetCheckBox
                        className="add-lender-address"
                        label="Add lender to address book"
                        value={addToAddressBook ?? false}
                        checkHandler={(checkVal: boolean) => {
                          const updated = { ...lender, addToAddressBook: checkVal };
                          updateLenderData(updated);
                        }}
                        {...(isCreateForm ? { 'data-qa': 'CreateFileAddLendertoAddressBook' } : {})}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <DomesticAddressField
                      schema={lenderDomesticAddressSchema}
                      displayRequiredAddressFields={true}
                      useGoogleAutocomplete={true}
                      getAllState={address?.state || address?.stateOrProvince ? false : true}
                      isCreateFileForm={true}
                      value={{
                        address1: address?.address1,
                        address2: address?.address2,
                        city: address?.city,
                        postalCode: address?.postalCode,
                        stateOrProvince: address?.state ?? address?.stateOrProvince,
                      }}
                      isOutsideJurisdiction={true}
                      // TODO: reimplement the validation. True for now
                      showAllValidation={showAllValidation}
                      setIsValid={(isValid) => {
                        setIsAddressFormGroupValid(isValid);
                      }}
                      {...(isCreateForm ? { qaAttributePrefix: 'CreateFileLender' } : {})}
                      onChange={(e: any) => {
                        const updatedAddress = { ...e, postalCode: e.postalCode as string };
                        const updated = { ...lender, address: updatedAddress };
                        updateLenderData(updated);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneField
                      variant="outlined"
                      name={'phoneNumber'}
                      label={'Phone Number'}
                      errs={validationErrors}
                      value={phoneNumber}
                      showValidation={showAllValidation}
                      fullWidth
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updated = { ...lender, phoneNumber: e?.target?.value };
                        updateLenderData(updated);
                      }}
                      {...(isCreateForm ? { 'data-qa': 'CreateFileLenderPhoneNumber' } : {})}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AgentNetTextInput
                      variant="outlined"
                      fullWidth
                      name="emailAddress"
                      showValidation={showAllValidation}
                      errs={validationErrors}
                      label={'Email'}
                      value={emailAddress?.trim() ?? ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updated = { ...lender, emailAddress: e.target.value.trim() ?? '' };
                        updateLenderData(updated);
                      }}
                      {...(isCreateForm ? { 'data-qa': 'CreateFileLenderEmail' } : {})}
                    />
                  </Grid>

                  <Grid item xs={6} className="lender-address-info-section-container">
                    <AgentNetDropdownSelector
                      name="mortgageClause"
                      label="Care of, DBA, Mortgage Clause"
                      options={mortgageClause}
                      value={lenderClauseType}
                      menuOption={(val) => {
                        onLenderClauseTypeChange(val);
                      }}
                      dropdowntype="outlined"
                      {...(isCreateForm ? { 'data-qa': 'CreateFileCareofDBAMortgageClause' } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} className="lender-address-info-section-container">
                    <AgentNetTextInput
                      variant="outlined"
                      fullWidth
                      multiline
                      rows="2"
                      label="Mortgage Clause"
                      value={lenderClauseText}
                      {...(isCreateForm ? { 'data-qa': 'CreateFileMortgageClauseNotes' } : {})}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updated = { ...lender, lenderClauseText: e.target.value };
                        updateLenderData(updated);
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </section>
    </>
  );
};

export default CreateLender;
