import { Overrides } from '@material-ui/core/styles/overrides';
import palette from '../palette/palette';
import spacing from '../spacing';
import typography from '../typography';
import breakpoints from '../breakpoints';
import { grey } from '@material-ui/core/colors';
import { pxToRem } from '@fluentsms/agentnet-web-components';

const overrides: Overrides = {
  //SVG ICON
  MuiSvgIcon: {
    root: {
      fontSize: pxToRem(16),
    },
    fontSizeLarge: {
      fontSize: pxToRem(24),
    },
  },

  //FORM INPUTS
  MuiSelect: {
    select: {
      textAlign: 'left',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    selectMenu: {
      height: '0',
    },
    icon: {
      position: 'relative',
      right: pxToRem(10),
      bottom: pxToRem(10),
      transform: 'scale(1.5)',
    },
    iconOpen: {
      transform: 'rotate(180deg) scale(1.5)',
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        background: palette.actionSecondary.disabledBackground,
        color: palette.actionSecondary.disabled,
      },
    },
    input: {
      ...typography.body2,
      color: palette.text.primary,
      fontFamily: 'Inter',
      fontSize: pxToRem(14),
      fontWeight: 400,
      position: 'relative',
      padding: '8px 0 10px',
      '&$disabled': {
        background: grey[50],
        color: palette.text.disabled,
        borderColor: palette.states.disabledBorder,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      overflow: 'hidden',
      '&$disabled': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.states.disabledBorder,
        },
      },
      '&$focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        transition: '.3s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: '.2s all cubic-bezier(0.420, 0.000, 0.580, 1.000)',
          borderColor: palette.primary.dark,
        },
      },
      '& legend': {
        maxWidth: '1000px',
      },
      '&$error $notchedOutline': {
        borderColor: `${palette.error.main} !important`,
      },
      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px',
      },
    },
    input: {
      padding: '10px 12px',
      color: palette.text.primary,
      fontFamily: 'Inter',
      fontWeight: 400,
      borderColor: '#c4c4c4',
    },
    inputMarginDense: {
      paddingTop: pxToRem(15),
      paddingBottom: pxToRem(15),
    },
  },
  MuiInputLabel: {
    formControl: {
      fontSize: pxToRem(13),
    },
    filled: {
      transform: ' translate(12px, 8px) scale(1)',
      fontSize: pxToRem(13),
    },
    outlined: {
      fontSize: pxToRem(16),
      fontFamily: 'Inter',
      fontWeight: 500,
      letterSpacing: '0.15px',
      color: palette.text.secondary,
      '&.MuiInputLabel-shrink': {
        fontSize: pxToRem(13),
        transform: `translate(14px, -6px)`,
        backgroundColor: 'white',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: pxToRem(12),
      letterSpacing: '0.4px',
      color: palette.text.secondary,
      '&$focused': {
        color: palette.primary.main,
      },
    },
  },
  MuiFilledInput: {
    input: {
      padding: pxToRem(27, 12, 10, 10),
    },
    inputMultiline: {
      padding: pxToRem(30, 0),
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: palette.error.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
      '&$error': {
        color: palette.error.dark,
      },
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginLeft: 'auto',
    },
  },

  //SWITCH
  MuiSwitch: {
    root: {
      '&$checked': {
        color: palette.primary.main,
      },
    },
    colorSecondary: {
      '&$checked': {
        color: palette.primary.main,
      },
    },
    track: {
      opacity: 0.38,
      backgroundColor: '#000',
      '$checked$checked + &': {
        opacity: 0.7,
        backgroundColor: palette.primary.main,
      },
    },
  },

  //CHECKBOX
  MuiCheckbox: {
    root: {
      padding: `2px 9px`,
    },
  },
  //MENU
  MuiMenu: {
    paper: {},
  },
  //MENU ITEM
  MuiMenuItem: {
    root: {
      fontSize: pxToRem(16),
      color: palette.text.primary,
      fontFamily: 'Inter',
    },
  },
  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
  },
  MuiPopover: {
    paper: {
      top: '155px',
    },
  },

  //TEXT INPUT
  MuiInput: {
    formControl: {
      'label + &': {
        marginTop: 19,
      },
    },
    underline: {
      '&.MuiInput-formControl:before': {
        borderBottomStyle: 'none',
      },
      '& .MuiInputAdornment-root': {
        marginBottom: spacing(0.375),
        '& .MuiIconButton-root': {
          padding: 3,
          fontSize: spacing(2.25),
          marginBottom: spacing(0.5),
        },
      },
      '&:hover:not($disabled):before': {
        borderBottomColor: 'none',
      },
    },
  },

  //DROPDOWN
  MuiFormControl: {
    root: {
      backgroundColor: palette.background.paper,
      '& .MuiSelect-icon': {
        position: 'absolute',
        top: 'calc(50% - 8px)',
      },
    },
  },

  //FORMCONTROL FOR SWITCHES
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
    },
  },

  //TYPOGRAPHY
  MuiTypography: {
    root: {
      padding: 0,
    },
    h5: {
      padding: 0,
    },
  },

  //AUTOCOMPLETE
  MuiAutocomplete: {
    root: {
      '&.MuiAutocomplete-hasClearIcon,.MuiAutocomplete-hasPopupIcon': {
        height: '97.5%',
      },
    },
    popupIndicator: {
      '& div': {
        padding: 0,
        marginRight: 0,
      },
    },
    inputRoot: {
      padding: '0px',
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0px',
        '& .MuiAutocomplete-input': {
          padding: '10px 12px',
        },
        '& .MuiAutocomplete-input:first-child': {
          paddingLeft: '12px',
        },
      },
    },
    noOptions: {
      padding: `16px 24px`,
      fontSize: pxToRem(14),
    },
  },

  // CONTAINERS
  MuiContainer: {
    maxWidthLg: {
      // Custom content width utilized for entirety of application.
      // All page content should be wrapped in a container with maxWidthLg as well as contextual areas of L1 / L2 Nav.
      [breakpoints.up('lg')]: {
        maxWidth: 1440,
      },
    },
  },

  // CHIPS
  MuiChip: {
    root: {
      fontSize: pxToRem(13),
      fontFamily: 'Inter',
      color: palette.text.primary,
      fontWeight: 400,
    },
  },

  // TOOLTIP
  MuiTooltip: {
    tooltip: {
      fontSize: pxToRem(13),
      fontFamily: 'Inter',
      lineHeight: pxToRem(16),
      padding: '8px',
    },
  },

  //MODAL
  MuiDialogContent: {
    root: {
      padding: pxToRem(24),
    },
  },
  MuiDialog: {
    paperWidthSm: {
      minWidth: 400,
    },
    paperWidthMd: {
      minWidth: 600,
    },
  },

  //TABS
  MuiTab: {
    root: {
      [breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
      padding: 0,
      fontSize: pxToRem(14),
      lineHeight: '150%',
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      margin: spacing(0, 1),
      '& .Mui-selected': {
        fontWeight: 700,
      },
    },
    textColorPrimary: {
      color: palette.text.primary,
    },
    textColorInherit: {
      opacity: 1,
      '&.Mui-selected': {
        fontWeight: 600,
      },
    },
  },

  MuiTabs: {
    indicator: {
      height: '3px',
    },
  },
};

export default overrides;
