import React from 'react';
import { useAuth } from '@agentnet/auth';

const ForgotPassword = (): JSX.Element => {
  const idaaSAuth = useAuth();
  idaaSAuth.forgotPassword();
  return <div />;
};

export default ForgotPassword;
