import { theme } from 'ui-kit/theme/theme';
import createTypography from '@material-ui/core/styles/createTypography';
import palette from 'ui-kit/theme/palette';

const { fontFamily } = theme.typography;

const kbTypography = createTypography(palette, {
  fontFamily,
  h1: { fontFamily, fontWeight: 400, lineHeight: '65px', fontSize: '42px' },
  h2: { fontFamily, fontWeight: 400, lineHeight: '52px', fontSize: '33px' },
  h3: { fontFamily, fontWeight: 400, lineHeight: '42px', fontSize: '26px' },
  h4: { fontFamily, fontWeight: 400, lineHeight: '33px', fontSize: '20px' },
  h5: { fontFamily, fontWeight: 600, lineHeight: '27px', fontSize: '16px' },
  h6: { fontFamily, fontWeight: 500, lineHeight: '27px', fontSize: '16px' },
  body1: { fontFamily, fontSize: '16px', fontWeight: 400, lineHeight: '24px' },
  body2: { fontFamily, fontSize: '14px', fontWeight: 400, lineHeight: '28px' },
  caption: { fontFamily, fontSize: '13px', fontWeight: 400, lineHeight: '15.41px' },
  overline: {},
});

export default kbTypography;
