import { useParams } from 'react-router-dom';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { AgentNetDivider as NewAgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { cplData, cplList } from '../types';
import { CPLActions, cplActiveActionProps, CPLErrorCodes, CPLStatus, noCplsFound, openCplsLimit } from './constants';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { deleteCPL, getCPLDetail, unvoidCPL, voidCPL } from 'api/cpl-api';
import { getFileInfoProps } from 'api/file/file-api';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import Typography from '@material-ui/core/Typography';
import { openDocument } from 'utilities/utilities';
import { DocumentType, KBLinks, newWindow, WindowFeatures } from '../../constants';
import NoResultFoundIcon from 'ui-kit/icons/NoResultFound';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import CPLEdit from '../cpl-edit/CPLEdit';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { Notification } from 'ui-kit/components/notification/Notification';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';

interface CplListProps {
  cplId?: string;
  onCancel: () => void;
  cplListData: cplList | null;
  isCplClicked?: boolean;
  handleAddNewCPL?: () => void;
  handleActionEdit?: (cplId: string) => void;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  updateOpenPendingCount: (cpls: cplData[]) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({ warningMsg: { marginBottom: theme.spacing(3) } }));
const CplList: React.FC<CplListProps> = ({
  cplId,
  onCancel,
  cplListData,
  handleAddNewCPL,
  handleActionEdit,
  isStarsStatusActive,
  noStarsStatusActive,
  updateOpenPendingCount,
}: CplListProps) => {
  const classes = useStyles();
  const [expandedCplIds, setExpandedCplIds] = useState<any[]>([]);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case CPLActions.edit:
        return { ...state, isEditing: true, selectedCplId: action.payload };
      case CPLActions.view:
        return { ...state, isEditing: false, selectedCplId: '', closeAllActive: !state.closeAllActive };
      case CPLActions.expandCollapseActive:
        return { ...state, isExpandActive: action.payload, closeAllActive: !state.closeAllActive };
      case CPLActions.expandCollapseInActive:
        return { ...state, isExpandInActive: action.payload, closeAllInActive: !state.closeAllInActive };
      case CPLActions.onInactiveToggleChange:
        return { ...state, isInactiveToggle: !state.isInactiveToggle };
      case 'OnExpandChangeActive':
        return { ...state, closeAllActive: false };
      case 'OnExpandChangeInActive':
        return { ...state, closeAllInActive: false };
      case 'OnChangingCplId':
        return { ...state, selectedCplId: action.payload };
      default:
        throw new Error();
    }
  };

  const [cplActionsState, dispatch] = useReducer(reducer, {
    isEditing: false,
    selectedCplId: '',
    isExpandActive: false,
    isExpandInActive: false,
    isInactiveToggle: false,
    closeAllActive: false,
    closeAllInActive: false,
  });
  const [editedPartyName, setEditedPartyName] = useState<string>('');
  const [activeAction, setactiveAction] = useState<cplActiveActionProps | null>(null);
  const [actionListModal, setActionListModal] = useState<boolean>(false);
  const [activeActionPos, setactiveActionPos] = useState<any>();
  const [topPos, setTopPos] = useState<any[] | undefined>([]);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openVoidModal, setOpenVoidModal] = useState<boolean>(false);
  const [openUnvoidModal, setOpenUnvoidModal] = useState<boolean>(false);
  const [selectedCplId, setSelectedCplId] = useState<string | number>('');
  const [CPLs, updateCPLList] = useState<cplData[]>(cplListData?.cpls ?? []);
  const [errMsgs, setErrMsgs] = useState([]);
  const { fileId } = useParams<getFileInfoProps>();
  const { getAccessToken } = useAuth();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { setActiveTab, fileData } = fileDataCtx;
  const activeCpls = CPLs.filter(
    (item) =>
      item.status === CPLStatus.open ||
      item.status === CPLStatus.pending ||
      item.status === CPLStatus.closed ||
      item.status === CPLStatus.pending_remit ||
      item.status === CPLStatus.remitted,
  );
  const inactiveCpls = CPLs.filter((item) => item.status === CPLStatus.void);
  const activeCplsCount = activeCpls.length ?? 0;

  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();

  const deleteSingleCPL = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return deleteCPL(fileId ?? '', cplActionsState.selectedCplId ?? '', token);
  };

  const voidSingleCPL = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return voidCPL(fileId ?? '', cplActionsState.selectedCplId ?? '', token, fileData?.accountId);
  };

  const unvoidSingleCPL = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return unvoidCPL(fileId ?? '', cplActionsState.selectedCplId ?? '', token, fileData?.accountId);
  };

  const getCplPdf = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return getCPLDetail(fileId ?? '', fileData?.accountId, token, selectedCplId ?? '');
  };

  const {
    execute: executeCplPdf,
    value: pdfValue,
    status: pdfStatus,
    errors: getPdfError,
  } = useAsync<any>(getCplPdf, false);

  const {
    execute: executeDeleteCpl,
    value: deleteCplValue,
    status: deleteStatus,
    errors: deleteCplError,
  } = useAsync<any>(deleteSingleCPL, false);

  const {
    execute: executeVoidCpl,
    value: voidCplValue,
    status: voidStatus,
    errors: voidCplError,
  } = useAsync<any>(voidSingleCPL, false);

  const {
    execute: executeUnvoidCpl,
    value: unvoidCplValue,
    status: unvoidStatus,
    errors: unvoidCplError,
  } = useAsync<any>(unvoidSingleCPL, false);

  useEffect(() => {
    setDisplayError(
      pdfStatus === 'error' || deleteStatus === 'error' || voidStatus === 'error' || unvoidStatus === 'error',
    );
  }, [pdfStatus, deleteStatus, voidStatus, unvoidStatus]);

  useEffect(() => {
    updateOpenPendingCount(CPLs);
  }, [CPLs]);

  useEffect(() => {
    const errorMessages = getPdfError?.length
      ? getPdfError
      : deleteCplError?.length
      ? deleteCplError
      : voidCplError?.length
      ? voidCplError
      : unvoidCplError?.length
      ? unvoidCplError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (deleteCplValue?.result?.cplId) {
      setDisplayError(false);
      addSnackbarMessage({
        message: 'Deleted CPL Successfully',
        type: 'success',
      });
      const cpls = CPLs?.filter((cpl) => cpl.cplId !== deleteCplValue?.result?.cplId);
      if (!cpls || cpls.length === 0) {
        if (setActiveTab) setActiveTab('CPLEdit');
      } else {
        updateCPLList(cpls);
      }
    } else {
      if (deleteCplValue?.errorCode == CPLErrorCodes.notFound) {
        const cpls = CPLs?.filter((cpl) => cpl.cplId !== activeAction?.cpl.cplId);
        updateCPLList(cpls);
      }
    }
    setOpenDeleteModal(false);
  }, [deleteCplValue]);

  useEffect(() => {
    if (voidCplValue?.result?.cplId) {
      setDisplayError(false);
      addSnackbarMessage({
        message: 'Voided CPL Successfully',
        type: 'success',
      });
      const cpls = CPLs?.map((cpl: cplData) => {
        if (cpl.cplId === voidCplValue?.result?.cplId) {
          cpl.status = voidCplValue?.result?.status ?? 'Void';
        }
        return cpl;
      });

      if (!cpls || cpls.length === 0) {
        if (setActiveTab) setActiveTab('CPLEdit');
      } else {
        updateCPLList(cpls);
      }
    }
    setOpenVoidModal(false);
  }, [voidCplValue]);

  useEffect(() => {
    if (unvoidCplValue?.result?.cplId) {
      setDisplayError(false);
      addSnackbarMessage({
        message: 'Unvoided CPL Successfully',
        type: 'success',
      });
      const cpls = CPLs?.map((cpl: cplData) => {
        if (cpl.cplId === unvoidCplValue?.result?.cplId) {
          cpl.status = unvoidCplValue?.result?.status ?? 'Open';
        }
        return cpl;
      });

      if (!cpls || cpls.length === 0) {
        if (setActiveTab) setActiveTab('CPLEdit');
      } else {
        updateCPLList(cpls);
      }
    }
    setOpenUnvoidModal(false);
  }, [unvoidCplValue]);

  useEffect(() => {
    if (activeActionPos && activeActionPos.pageY) {
      setTopPos([activeActionPos.pageX, activeActionPos.pageY]);
    }
  }, [activeActionPos]);

  useEffect(() => {
    if (selectedCplId !== '') {
      executeCplPdf().then();
      setTimeout(() => setSelectedCplId(''), 3000);
    }
  }, [selectedCplId]);

  useEffect(() => {
    if (pdfStatus === 'success') {
      if (pdfValue?.pdfData) openDocument(pdfValue.pdfData, DocumentType, newWindow, WindowFeatures);
    }
  }, [pdfStatus]);

  useEffect(() => {
    if (displayError && errMsg) {
      addSnackbarMessage({
        message: errMsg,
        type: 'error',
        onClose: () => setDisplayError(false),
      });
    }
  }, [displayError, errMsg]);

  useEffect(() => {
    if (activeCplsCount === 0) {
      dispatch({ type: CPLActions.onInactiveToggleChange });
    }
  }, [activeCplsCount]);

  const onActionHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.id === CPLActions.edit || event?.target?.id === CPLActions.view) {
      if (handleActionEdit) handleActionEdit(activeAction?.cpl.cplId ?? '');
    }
    if (event?.target?.id === CPLActions.delete) {
      setOpenDeleteModal(true);
    }
    if (event?.target?.id === CPLActions.void) {
      setOpenVoidModal(true);
    }
    if (event?.target?.id === CPLActions.unvoid) {
      if (activeCplsCount >= 5) {
        setErrMsg(
          ' The maximum limit of 5 open and pending CPLs in this file has been reached. Please Void/Delete a CPL to continue.',
        );
        setDisplayError(true);
      } else {
        setErrMsg('');
        setOpenUnvoidModal(true);
      }
    }
    setActionListModal(false);
  };

  const activeActionHandler = (jacket: cplData, rowId: number) => {
    setactiveAction({ cpl: jacket, rowId: rowId });
    toggleActionModal(true);
  };

  const toggleActionModal = (value: boolean) => {
    setActionListModal(value);
    if (!value) {
      setactiveAction(null);
    }
  };
  /* Handle Dialog Modal */
  const handleCloseDialog = () => {
    if (unvoidStatus === 'pending' || deleteStatus === 'pending' || voidStatus === 'pending') return;
    setOpenDeleteModal(false);
    setOpenVoidModal(false);
    setOpenUnvoidModal(false);
  };
  const handleDeleteCPLDialog = () => {
    executeDeleteCpl().then();
  };
  const handleVoidCPLDialog = () => {
    executeVoidCpl().then();
  };
  const handleUnvoidCPLDialog = () => {
    executeUnvoidCpl().then();
  };

  function getAccordianProps(cpl: cplData, index: number) {
    const i = index + 1;
    return {
      closeAll:
        (cpl.status !== CPLStatus.void && cplActionsState.closeAllActive) ||
        (cpl.status === CPLStatus.void && cplActionsState.closeAllInActive),
      expanded:
        (cpl.status !== CPLStatus.void && cplActionsState.isExpandActive) ||
        (cpl.status === CPLStatus.void && cplActionsState.isExpandInActive),
      isEditing: cpl.cplId === cplActionsState.selectedCplId && cplActionsState.isEditing,
      isEditDisabled:
        cplActionsState.isEditing ||
        noStarsStatusActive ||
        isStarsStatusActive ||
        cpl.isCplUploaded ||
        fileData?.fileStatus !== CPLStatus.open,
      isUnvoidDisabled:
        cplActionsState.isEditing ||
        noStarsStatusActive ||
        isStarsStatusActive ||
        fileData?.fileStatus !== CPLStatus.open,
      isVoidDisabled: noStarsStatusActive || fileData?.fileStatus !== CPLStatus.open,
      isCollapseDisabled: cplActionsState.isEditing || cpl.isCplUploaded,
      //isPdfDisabled: cplActionsState.isEditing,
      isDeleteDisabled: cplActionsState.isEditing || noStarsStatusActive || fileData?.fileStatus !== CPLStatus.open,
      accordianQAAttribute: `CPLSummary${cpl.status === CPLStatus.void ? 'Inactive' : 'Active'}${i}`,
      deleteQAAttribute: `CPL${i}Delete`,
      voidQAAttribute: `CPL${i}Void`,
      unvoidQAAttribute: `CPL${i}Unvoid`,
      editQAAttribute: `CPL${i}Edit`,
      pdfQAAttribute: `CPL${i}ViewPDF`,
      id: cpl.cplId,
      title: `${cpl.coveredParty} - ${cpl.letterType}`,
      type: 'cpl',
      status: cpl.status,
      subtitle:
        cpl.cplId === cplActionsState.selectedCplId && cplActionsState.isEditing
          ? [editedPartyName || cpl.partyName]
          : [cpl.partyName],
      createdDate: cpl.created,
      icon: 'document',
      className: 'cpl-accordion',
      onExpandChange: (expand: boolean, id: string | number) => {
        if (expand) {
          cpl.status === CPLStatus.void
            ? dispatch({ type: 'OnExpandChangeActive' })
            : dispatch({ type: 'OnExpandChangeInActive' });
          setExpandedCplIds((prevIds) => [...prevIds, id]);
        } else {
          setExpandedCplIds((prevIds) => [...prevIds].filter((ele) => id !== ele));
        }
      },
      ...((cpl.status === CPLStatus.open || cpl.status === CPLStatus.pending) && {
        onEdit: (id: number | string) => {
          dispatch({ type: CPLActions.edit, payload: id });
        },
      }),
      ...(cpl.status === CPLStatus.open && {
        onVoid: (id: number | string) => {
          dispatch({ type: 'OnChangingCplId', payload: id });
          setOpenVoidModal(true);
        },
      }),
      ...((cpl.status === CPLStatus.open ||
        cpl.status === CPLStatus.closed ||
        cpl.status === CPLStatus.pending_remit ||
        cpl.status === CPLStatus.remitted) && {
        onPDF: (id: number | string) => {
          setSelectedCplId(id);
        },
      }),
      ...(cpl.status === CPLStatus.pending && {
        onDelete: (id: number | string) => {
          dispatch({ type: 'OnChangingCplId', payload: id });
          setOpenDeleteModal(true);
        },
      }),
      ...(cpl.status === CPLStatus.void && {
        onUnvoid: (id: number | string) => {
          dispatch({ type: 'OnChangingCplId', payload: id });
          if (activeCplsCount >= 5) {
            setErrMsg(
              ' The maximum limit of 5 open and pending CPLs in this file has been reached. Please Void/Delete a CPL to continue.',
            );
            setDisplayError(true);
          } else {
            setErrMsg('');
            setOpenUnvoidModal(true);
          }
        },
      }),
    };
  }

  function getCplEditProps(cplId: string) {
    return {
      cplListData: cplListData,
      cplsCount: activeCplsCount,
      onCancel: onCancel,
      isStarsStatusActive: isStarsStatusActive,
      noStarsStatusActive: noStarsStatusActive,
      isEditMode: cplId === cplActionsState.selectedCplId && cplActionsState.isEditing,
    };
  }

  const renderCplDetails = (cpls: cplData[]) => {
    if (Array.isArray(cpls) && !cpls.length) return;
    return cpls.map((cpl, index) => (
      <AccordionContent key={cpl.cplId} {...getAccordianProps(cpl, index)}>
        {expandedCplIds.some((id) => cpl.cplId === id) ? (
          <CPLEdit
            accordionView
            index={index + 1}
            cplId={cpl.cplId}
            {...getCplEditProps(cpl.cplId)}
            onViewMode={() => {
              setEditedPartyName('');
              dispatch({ type: CPLActions.view });
            }}
            setEditedPartyName={setEditedPartyName}
          />
        ) : (
          <> </>
        )}
      </AccordionContent>
    ));
  };

  return (
    <>
      <LoadingSpinner status={pdfStatus} variant="linear" />
      {(isStarsStatusActive || noStarsStatusActive) && !CPLs?.length ? (
        <div className="no-results-msg">
          <NoResultFoundIcon className="no-cpls" />
          <Typography variant="h1">No CPLs Associated to This File</Typography>
          <Typography variant="body1">Due to inactive status, this file can not create CPLs.</Typography>
        </div>
      ) : (
        <>
          <div className="jackets-summary">
            <div className="paper-layout">
              <Container>
                <PageHeader
                  title="CPL"
                  subtitle="View and manage your Closing Protection Letters for this file. You can have up to 5 active CPLs at any time."
                  contentRight={
                    <AgentNetButton
                      variant="contained"
                      color="primary"
                      onClick={handleAddNewCPL}
                      disabled={
                        activeCplsCount >= 5 ||
                        isStarsStatusActive ||
                        noStarsStatusActive ||
                        fileData?.fileStatus !== CPLStatus.open ||
                        (fileData?.fileStatus === 'Open' && fileData?.serviceStatus?.isReopenedByProduct)
                      }
                      data-qa="CPLCreateNew"
                      plusIcon
                    >
                      Add CPL
                    </AgentNetButton>
                  }
                  disableMargin="x"
                  menuItems={[
                    {
                      label: 'Knowledge Base',
                      link: KBLinks.cpl,
                    },
                  ]}
                />

                {cplActionsState.isEditing && (
                  <Notification
                    inline
                    severity="warning"
                    msg="You are in edit mode. Finish editing the CPL to enable all page features."
                    disablePadding
                    className={classes.warningMsg}
                  />
                )}
                {activeCplsCount >= 5 && (
                  <Notification inline severity="warning" className={classes.warningMsg}>
                    {openCplsLimit}
                  </Notification>
                )}
                {activeCplsCount === 0 && (
                  <Notification inline severity="warning" className={classes.warningMsg}>
                    {noCplsFound}
                  </Notification>
                )}
                {renderCplDetails(activeCpls)}
                {inactiveCpls.length ? (
                  <NewAgentNetDivider
                    title="Show Inactive CPLs"
                    {...(cplActionsState.isInactiveToggle && {
                      buttonName: 'Close All',
                      buttonName2: 'Expand All',
                    })}
                    onClick={() => {
                      dispatch({ type: CPLActions.expandCollapseInActive, payload: false });
                    }}
                    onClick2={() => {
                      dispatch({ type: CPLActions.expandCollapseInActive, payload: true });
                    }}
                    disablePaddingX
                    onSwitch={() => {
                      dispatch({ type: CPLActions.onInactiveToggleChange });
                    }}
                    switchValue={cplActionsState.isInactiveToggle}
                    buttonNameQaAttr="CPLCollapseAllInActive"
                    buttonName2QaAttr="CPLExpandAllInActive"
                    toggleQaAttr="CPLDisplayInvalid"
                  />
                ) : null}
                {cplActionsState.isInactiveToggle ? renderCplDetails(inactiveCpls) : null}

                <div>
                  <AgentNetConfirmationDialog
                    qaAttrPrefix="ConfirmationDelete"
                    disabled={deleteStatus === 'pending'}
                    onConfirm={handleDeleteCPLDialog}
                    open={openDeleteModal}
                    onDismissAction={handleCloseDialog}
                    dialogTitle="Delete CPL?"
                    dialogBtnContent="Yes, Continue"
                    dialogText="Are you sure you want to Delete this CPL?"
                  />
                  <AgentNetConfirmationDialog
                    qaAttrPrefix="ConfirmationVoid"
                    disabled={voidStatus === 'pending'}
                    onConfirm={handleVoidCPLDialog}
                    open={openVoidModal}
                    onDismissAction={handleCloseDialog}
                    dialogTitle="Void CPL?"
                    dialogBtnContent="Yes, Continue"
                    dialogText="Are you sure you want to void this CPL?"
                  />
                  <AgentNetConfirmationDialog
                    qaAttrPrefix="ConfirmationUnvoid"
                    disabled={unvoidStatus === 'pending'}
                    onConfirm={handleUnvoidCPLDialog}
                    open={openUnvoidModal}
                    onDismissAction={handleCloseDialog}
                    dialogTitle="Unvoid CPL?"
                    dialogBtnContent="Yes, Continue"
                    dialogText="Are you sure you want to unvoid this CPL?"
                  />
                </div>
              </Container>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CplList;
