import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { FieldValidationError } from '../../utilities/validation/validation';
import ErrorSection from '../../features/files/file-create/ErrorSection';
import { Control } from 'react-hook-form';
import './DollarField.scss';

export type DollarFieldProps = TextFieldProps & {
  error?: boolean;
  helperText?: string | React.ReactNode;
  control?: Control;
  label?: string | React.ReactNode | undefined;
  rows?: string | number;
  errorMap?: Record<string, string[]>;
  errs?: FieldValidationError[];
  id?: string;
  alwaysDisplayError?: boolean;
  showValidationOnBlur?: boolean;
  showValidationOnFocus?: boolean;
  className?: string;
};

export function DollarField({
  InputProps,
  errs,
  variant,
  label,
  id,
  name,
  alwaysDisplayError,
  showValidationOnBlur = true,
  showValidationOnFocus = false,
  onFocus,
  onBlur,
  className,
  ...rest
}: DollarFieldProps): JSX.Element {
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(alwaysDisplayError);
  const displayErrorOnCallback = (callback: () => void) => {
    setDisplayError(true);
    callback();
  };
  useEffect(() => {
    const hasError = errs?.find((err) => err.field === id);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);
  useEffect(() => {
    setDisplayError(alwaysDisplayError);
  }, [alwaysDisplayError]);

  const val = rest.value as string;
  return (
    <>
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          ...InputProps,
        }}
        inputProps={{
          style: {
            textAlign: 'right',
          },
          title: val ? val : label ? label.toString() : '',
          ...rest.inputProps,
        }}
        InputLabelProps={{ shrink: true }}
        error={errors && displayErrors}
        variant="outlined"
        label={label}
        onBlur={(e) => {
          showValidationOnBlur && displayErrorOnCallback(() => onBlur && onBlur(e));
        }}
        onFocus={(e) => {
          showValidationOnFocus && displayErrorOnCallback(() => onFocus && onFocus(e));
        }}
        className={className ? `${className} an-dollar-field` : `an-dollar-field`}
        {...rest}
      />
      {errs && name && displayErrors && <ErrorSection errs={errs} field={name} />}
    </>
  );
}
