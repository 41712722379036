import React from 'react';
import { Search } from '@material-ui/icons';
import { InputAdornment, TextField } from '@material-ui/core';
import { pxToRem } from '@fluentsms/agentnet-web-components';

export interface SearchBarProps {
  searchString?: string | null;
  onSearchChange?: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  prefix?: boolean;
  textFieldClass?: string;
  inputClass?: string;
}

export const SearchBar = (props: SearchBarProps): JSX.Element => {
  const {
    searchString: filterValue,
    onSearchChange,
    placeholder = 'Search',
    fullWidth,
    prefix = true,
    textFieldClass,
    inputClass,
  } = props;

  const handleSearchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onSearchChange?.(e.target.value);
    },
    [onSearchChange],
  );

  return (
    <TextField
      size="small"
      className={textFieldClass}
      fullWidth={fullWidth}
      placeholder={placeholder}
      data-testid={placeholder.replace(/\s+/g, '-').toLowerCase() + '-search-bar'}
      value={filterValue}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: prefix && (
          <InputAdornment position="start" style={{ color: '#677784' }}>
            <Search style={{ fontSize: pxToRem(24), margin: pxToRem(5) }} />
          </InputAdornment>
        ),
        disableUnderline: true,
        classes: { input: inputClass },
      }}
    />
  );
};
