import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Grid, Theme, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import { useSearchParams } from 'features/help/hooks/useSearchParams';
import { getFileInfoProps, getFileSummaryReport } from 'api/file/file-api';
import {
  Cpl,
  FeeItem,
  FileSummaryReportType,
  Jacket,
  missingDataRiskTypeMap,
  RecordingFee,
  riskTypeMap,
  ServiceOrder,
} from 'api/file/interfaces/get-file-summary-report';
import {
  extractRisks,
  filePartyTypeMap,
  getParentSeverity,
  onFileOwnerAddresses,
  onFileOwnerNames,
  OrderInsightJacket,
  Party,
  Property,
  Risk,
  RiskCategory,
  severityOrder,
  toFullAddress,
} from 'api/file/interfaces/get-file-order-insights';
import { getStreetViewUrl } from 'api/google/streetViewUrl';
import { checkPdfToken } from 'api/pdf/pdf-api';

import useAsync from 'hooks/useAsync';

import OrderInsightsRiskIcon from 'features/files/order-insights/components/OrderInsightsRiskIcon';
import PropertyOrderInsights from 'features/files/order-insights/PropertyOrderInsights';
import PropertyInfo from './components/PropertyInfo';
import ServiceOrderInfo from './components/ServiceOrderInfo';
import BackTitleInfo from './components/BackTitleInfo';
import CplInfo from './components/CplInfo';
import JacketInfo from './components/JacketInfo';
import RecordingFeeCost from './components/RecordingFeeCost';

export function FileSummaryReport() {
  const [fileSummaryData, setFileSummaryData] = React.useState<FileSummaryReportType>();
  const [policyLiabilityJacket, setPolicyLiabilityJacket] = React.useState<OrderInsightJacket>();
  const [risksToReview, setRisksToReview] = React.useState<string[]>([]);
  const [risksSeverity, setRisksSeverity] = React.useState<string>('');
  const [isOrderInsightAvailable, setOrderInsightAvailable] = React.useState<boolean>(false);
  const [properties, setProperties] = React.useState<Property[]>();
  const [streetViewUrl, setStreetViewUrl] = React.useState<string>('');
  const ImageSize = '500x300';
  const NoInfo = 'n/a';

  const params = useSearchParams<{ pdfToken?: string }>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fullWidth: {
        width: '100%',
        background: '#fff',
      },
      headerTxt: {
        fontSize: '22pt',
        fontWeight: 800,
        color: '#013A6F',
        textAlign: 'center',
      },
      blueText: {
        textTransform: 'uppercase',
        fontSize: '9pt',
        fontWeight: 600,
        letterSpacing: '0.5px',
        color: '#013A6F',
        textAlign: 'center',
        marginBottom: '-4px',
      },
      blueText0: {
        fontSize: '9pt',
        fontWeight: 600,
        color: '#013A6F',
        textAlign: 'center',
        margin: '0',
      },
      twoCol: {
        display: 'flex',
        gap: '50px',
        fontSize: '9pt',
        margin: '0 auto',
        maxWidth: '400px',
        justifyContent: 'center',
      },
      bgWhite: {
        background: '#fff',
      },
      blueTextLeft: {
        textTransform: 'uppercase',
        fontSize: '9pt',
        fontWeight: 600,
        color: '#013A6F',
        textAlign: 'center',
        marginBottom: '-4px',
        letterSpacing: '0.5px',
      },
      unknown: {
        background: '#F5F5F5',
        display: 'flex',
        gap: '8px',
        padding: theme.spacing(1),
        margin: '0 auto',
        '& a': {
          color: theme.palette.text.secondary,
          '&:hover': {
            opacity: '0.8',
          },
        },
      },
      error: {
        background: '#FDECEA',
        display: 'flex',
        gap: '8px',
        padding: theme.spacing(1),
        margin: '0 auto',
      },
      grey: {
        color: theme.palette.text.secondary,
        fontSize: '9pt',
        margin: '0',
        '& a': {
          color: theme.palette.text.secondary,
        },
      },
      warning: {
        background: '#FDF4E7',
        display: 'flex',
        gap: '8px',
        padding: theme.spacing(1),
        margin: '0 auto',
      },
      orange: {
        color: '#C77700',
        fontSize: '9pt',
        marginTop: '0',
        '& a': {
          color: '#C77700',
        },
      },
      red: {
        color: '#B3150A',
        fontSize: '9pt',
        margin: '0',
        '& a': {
          color: '#B3150A',
        },
      },
      smallGrey: {
        fontSize: '9pt',
        color: '#666666',
        marginTop: '-8px',
        '& a': {
          color: '#666666',
        },
      },
      blueBar: {
        background: 'rgba(1, 58, 111, 0.15)',
        borderBottom: '1px solid rgba(1, 58, 111, 0.50)',
        display: 'flex',
        pageBreakInside: 'avoid',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        margin: theme.spacing(2, 0),
      },
      notes: {
        fontSize: '9pt',
        paddingLeft: theme.spacing(1),
        fontStyle: 'italic',
      },
      caps: {
        fontSize: '11pt',
        fontWeight: 600,
        letterSpacing: '1px',
        color: '#013A6F',
        textTransform: 'uppercase',
      },
      f12: {
        fontSize: '13pt',
      },
      trf10: {
        fontSize: '11pt',
        padding: theme.spacing(0, 0, 0, 1),
        textAlign: 'right',
      },
      trf11: {
        fontSize: '11pt',
        padding: theme.spacing(0, 2, 0, 0),
        textAlign: 'right',
      },
      f10: {
        fontSize: '11pt',
        padding: theme.spacing(1, 1, 0, 1),
      },
      fr: {
        float: 'right',
      },
      pr2: {
        paddingRight: theme.spacing(2),
      },
      pr3: {
        paddingRight: '20px !important',
      },
      f9: {
        fontSize: '10pt',
        paddingLeft: theme.spacing(1),
      },
      middle: {
        display: 'flex',
        padding: theme.spacing(1, 1, 0, 1),
        alignItems: 'center',
      },
      txtr: {
        justifyContent: 'flex-end',
      },
      m1: {
        paddingTop: '8px !important',
      },
      f8: {
        fontSize: '9pt',
        paddingLeft: theme.spacing(1),
      },
      f7: {
        fontSize: '8pt',
        fontStyle: 'italic',
      },
      p1: {
        padding: theme.spacing(1),
      },
      pt1: {
        paddingTop: theme.spacing(1),
      },
      flex: {
        display: 'flex',
        padding: '16px 8px',
        borderBottom: '1px solid #E9E9E9',
        pageBreakInside: 'avoid',
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      textFlexTop: {
        display: 'flex',
        height: 'fit-content',
        alignItems: 'flex-start',
        gap: '8px',
        marginRight: theme.spacing(3),
        minWidth: '25%',
        maxWidth: '25%',
      },
      textFlex: {
        display: 'flex',
        height: 'fit-content',
        alignItems: 'center',
        gap: '8px',
        minWidth: '150px',
      },
      m0: {
        margin: '0',
      },
      mt0: {
        marginTop: '0',
        fontSize: '10pt',
      },
      mt1: {
        marginTop: theme.spacing(1),
      },
      maxWidth: {
        maxWidth: '400px',
        marginTop: theme.spacing(2),
      },
      m32: {
        margin: '32px auto 0',
      },
      greyCaps: {
        color: '#666666',
        textTransform: 'uppercase',
        fontSize: '9pt',
        letterSpacing: '0.5px',
      },
      center: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '10pt',
      },
      centerTxt: {
        textAlign: 'center',
        width: '166px',
      },
      p8: {
        padding: theme.spacing(1),
      },
      w75: {
        display: 'inline-block',
        width: '75px',
      },
      pdfbody: {
        width: '8.5in',
        padding: '0',
        margin: theme.spacing(0, 'auto'),
        background: '#fff !important',
        border: '6px solid #fff !important',
        boxShadow: 'none !important',
      },
      pageBreak: {
        pageBreakAfter: 'always',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '80vh',
        paddingTop: '30px',
      },
    }),
  );
  const classes = useStyles();
  const history = useHistory();

  const { fileId } = useParams<getFileInfoProps>();

  const getFileSummary = (): Promise<FileSummaryReportType> => {
    return getFileSummaryReport(fileId ?? '', params.pdfToken ?? '') as Promise<FileSummaryReportType>;
  };
  const { execute: executeFileSummaryReport, value: fileSummaryReportData } = useAsync<FileSummaryReportType>(
    getFileSummary,
    false,
  );

  const fetchStreetUrl = async (address: string) => {
    const response = await getStreetViewUrl(address, ImageSize);
    setStreetViewUrl(response);
  };

  const addressWith5DigitZipCode = (address: string): string => {
    const zipCodeMatch = address?.match(/\b\d{5}(?:-\d{4})?\b/);
    if (zipCodeMatch && zipCodeMatch[0].length === 10) {
      return address.slice(0, -5);
    }
    return address;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await checkPdfToken(params.pdfToken ?? '');
      if (!res) {
        history.push('/');
        return;
      }
      executeFileSummaryReport().then();
    };
    document.title = 'File Summary Report';
    fetchData();
  }, [fileId]);

  useEffect(() => {
    const filterCplInfo = (cplInfo: Cpl[]) => cplInfo.filter((x) => x.cplId !== 0);
    const filterJacketInfo = (jacketInfo: Jacket[]) =>
      jacketInfo
        .filter((x) => x.policyType !== NoInfo)
        .map((jacket) => ({
          ...jacket,
          policyNumber: jacket.policyNumber === NoInfo ? '' : jacket.policyNumber,
        }));

    if (fileSummaryReportData) {
      const { cplInfo, jacketInfo, orderInsights, fileInfo } = fileSummaryReportData;
      if (cplInfo) fileSummaryReportData.cplInfo = filterCplInfo(cplInfo);

      if (jacketInfo) {
        fileSummaryReportData.jacketInfo = filterJacketInfo(jacketInfo);
      }

      setOrderInsightAvailable(!orderInsights?.isGenerating && orderInsights?.lastGeneratedDate != null);

      if (orderInsights?.properties) {
        const filePartyType =
          orderInsights?.parties.filter((party) => party.filePartyType === 'Seller').length > 0 ? 'Seller' : 'Buyer';
        const mappedProperties = orderInsights.properties.map((property) => {
          return {
            ...property,
            onFileOwnerAddressWithPartyName: onFileOwnerAddresses(orderInsights?.parties, filePartyType),
            onFileOwnerNames: onFileOwnerNames(orderInsights?.parties, filePartyType),
          };
        });
        setProperties(mappedProperties);
      }

      const risks = [
        ...extractRisks(orderInsights?.parties || [], ['Alert', 'Warning', 'None', 'Error']),
        ...extractRisks(orderInsights?.properties || [], ['Alert', 'Warning', 'None', 'Error']),
      ].sort((a: Risk, b: Risk) => severityOrder[a?.severityName] - severityOrder[b?.severityName]);

      const risksSeverity =
        risks[0]?.severityName === 'None' || risks[0]?.severityName === 'Error' ? 'Warning' : risks[0]?.severityName;
      setRisksSeverity(risksSeverity);

      const mappedRisksToReview: string[] = Array.from(
        new Set(
          risks.map((risk: Risk) => {
            if (risk.typeName === 'MissingData' || risk.typeName === 'MultipleProperties') {
              return missingDataRiskTypeMap[risk.categoryName as keyof typeof missingDataRiskTypeMap];
            } else {
              return riskTypeMap[risk.typeName as keyof typeof riskTypeMap];
            }
          }),
        ),
      );

      if (fileSummaryReportData.orderInsights?.jackets) {
        const policyLiabilityJacket = fileSummaryReportData.orderInsights?.jackets.find(
          (jacket) => jacket.isMaxLiabilityAmount,
        );
        setPolicyLiabilityJacket(policyLiabilityJacket);
      }

      setRisksToReview(mappedRisksToReview);
      setFileSummaryData(fileSummaryReportData);

      const address = fileInfo?.properties[0].address.toString();
      fetchStreetUrl(address);
    }
  }, [fileSummaryReportData]);

  return (
    <div className={classes.fullWidth}>
      {fileSummaryData && (
        <div id="pdf-content" className={classes.pdfbody}>
          <div className={classes.pageBreak}>
            <h1 className={classes.headerTxt}>
              {addressWith5DigitZipCode(fileSummaryData.fileInfo?.properties[0].address.toString())}
            </h1>
            {fileSummaryData.fileInfo?.parties?.buyer?.names != NoInfo && (
              <span>
                <p className={classes.blueText}>Buyer/Borrower:</p>
                <p className={classes.center}>{fileSummaryData.fileInfo?.parties?.buyer?.names}</p>
              </span>
            )}
            {fileSummaryData.fileInfo?.parties?.seller?.names != NoInfo && (
              <span>
                <p className={classes.blueText}>Seller/Owner:</p>
                <p className={classes.center}>{fileSummaryData.fileInfo?.parties?.seller?.names}</p>
              </span>
            )}
            {isOrderInsightAvailable && risksToReview?.length > 0 && (
              <div
                className={clsx(
                  risksSeverity === 'Alert' ? classes.error : classes.warning,
                  classes.center,
                  classes.maxWidth,
                )}
              >
                <OrderInsightsRiskIcon isPdf={true} riskSeverity={risksSeverity} />
                <span>
                  <p className={risksSeverity === 'Alert' ? classes.red : classes.orange}>
                    <strong>{risksToReview.length} Potential Risk(s) to Review</strong>
                  </p>
                  <p className={clsx(risksSeverity === 'Alert' ? classes.red : classes.orange, classes.m0)}>
                    {risksToReview.join(', ')}
                  </p>
                </span>
              </div>
            )}
            <div className={clsx(classes.center, classes.m32)}>
              {streetViewUrl ? <img src={streetViewUrl} alt="Street View" /> : <p>Loading image...</p>}
            </div>
            <div className={clsx(classes.twoCol, classes.m32)}>
              <span className={classes.centerTxt}>
                <p className={classes.blueTextLeft}>File Created:</p>
                <p>{fileSummaryData.fileInfo?.createdDate}</p>
              </span>
              <span className={classes.centerTxt}>
                <p className={classes.blueText}>File Status:</p>
                <p>{fileSummaryData.fileInfo?.fileStatus}</p>
              </span>
              <span className={classes.centerTxt}>
                <p className={classes.blueText}>Account No:</p>
                <p>{fileSummaryData.fileInfo?.accountNumber}</p>
              </span>
            </div>
            <div className={classes.twoCol}>
              <span className={classes.centerTxt}>
                <p className={classes.blueTextLeft}>Office:</p>
                <p>{fileSummaryData.fileInfo?.office?.name}</p>
                <p className={classes.smallGrey}>{fileSummaryData.fileInfo?.office?.address}</p>
              </span>
              <span className={classes.centerTxt}>
                <p className={classes.blueTextLeft}>Underwriter:</p>
                <p>{fileSummaryData.fileInfo?.underwriter}</p>
              </span>
            </div>
          </div>
          <div className={classes.blueBar}>
            <p className={classes.caps}>Party Information</p>
            {isOrderInsightAvailable && (
              <p className={classes.blueText0}>
                Search Date: {moment(fileSummaryData.orderInsights?.lastGeneratedDate).format('MM/DD/YYYY')}
              </p>
            )}
          </div>
          <div className={classes.bgWhite}>
            {fileSummaryData.orderInsights?.parties?.length > 0 ? (
              fileSummaryData.orderInsights?.parties.map((party: Party, index: number) => (
                <React.Fragment key={index}>
                  <div className={classes.flex}>
                    <div className={classes.textFlexTop}>
                      <OrderInsightsRiskIcon
                        isPdf={true}
                        riskSeverity={
                          isOrderInsightAvailable ? getParentSeverity(party.risks ?? [], RiskCategory.Party) : ''
                        }
                      />
                      <span>
                        <p className={classes.mt0}>
                          <strong>{party.partyName}</strong>
                        </p>
                        <p className={classes.smallGrey}>{filePartyTypeMap[party.filePartyType]}</p>
                      </span>
                    </div>
                    {!isOrderInsightAvailable ? (
                      ' '
                    ) : !party.risks ? (
                      <p className={classes.mt0}>No Risks Detected</p>
                    ) : (
                      <div className={classes.m0}>
                        {party.risks.map((risk: Risk, index: number) => (
                          <div
                            className={clsx(
                              risk.severityName == 'None' || risk.severityName == 'Error'
                                ? classes.unknown
                                : classes.error,
                              classes.mt1,
                            )}
                            key={index}
                          >
                            {' '}
                            <OrderInsightsRiskIcon
                              isPdf={true}
                              riskSeverity={getParentSeverity(party.risks ?? [], RiskCategory.Party)}
                            />
                            <div
                              className={clsx(
                                risk.severityName == 'None' || risk.severityName == 'Error'
                                  ? classes.grey
                                  : classes.red,
                              )}
                            >
                              <span>
                                <p className={classes.m0}>
                                  <strong>{risk.title}</strong>
                                </p>
                                <p
                                  className={clsx(
                                    risk.severityName == 'None' || risk.severityName == 'Error'
                                      ? classes.grey
                                      : classes.red,
                                    classes.m0,
                                  )}
                                  dangerouslySetInnerHTML={{ __html: risk.description }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <p className={classes.f9}>No parties found</p>
            )}
          </div>
          {/* Order Insights */}
          <>
            {isOrderInsightAvailable && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>Order insights</p>
                </div>
                {properties &&
                  properties.map((property: Property, index: number) => (
                    <React.Fragment key={index}>
                      <h2 className={clsx(classes.p8, classes.f12)}>{toFullAddress(property.address)}</h2>
                      <PropertyOrderInsights
                        property={property}
                        policyLiabilityJacket={policyLiabilityJacket}
                        isPdf={true}
                        index={index}
                        key={index}
                      />
                    </React.Fragment>
                  ))}
              </>
            )}
          </>
          {/* Property Information */}
          <>
            <div className={classes.blueBar}>
              <p className={classes.caps}>Property Information</p>
            </div>
            {properties &&
              properties.map((property: Property, index: number) => (
                <React.Fragment key={index}>
                  <h2 className={clsx(classes.p8, classes.f12)}>{toFullAddress(property.address)}</h2>
                  <PropertyInfo property={property} />
                </React.Fragment>
              ))}
          </>
          {/* Service Orders */}
          {fileSummaryData.fastOrders?.length > 0 && (
            <>
              <div className={classes.blueBar}>
                <p className={classes.caps}>Service Orders</p>
              </div>
              {fileSummaryData.fastOrders.map((serviceOrder: ServiceOrder, index: number) => (
                <React.Fragment key={index}>
                  <h2 className={clsx(classes.p8, classes.f12)}>{serviceOrder.productName}</h2>
                  <ServiceOrderInfo serviceOrder={serviceOrder} />
                </React.Fragment>
              ))}
            </>
          )}
          {/* Back Title Info */}
          <>
            {fileSummaryData.backTitle?.length > 0 && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>Back Title</p>
                </div>
                <BackTitleInfo backTitles={fileSummaryData.backTitle} />
              </>
            )}
          </>
          {/* CPL Info */}
          <>
            {fileSummaryData.cplInfo?.length > 0 && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>CPL</p>
                </div>
                <CplInfo cpls={fileSummaryData.cplInfo} />
              </>
            )}
          </>
          {/* Jacket Info */}
          <>
            {fileSummaryData.jacketInfo?.length > 0 && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>Jackets</p>
                </div>
                <JacketInfo jackets={fileSummaryData.jacketInfo} />
              </>
            )}
          </>
          {/* Title Rates */}
          <>
            {fileSummaryData.rateFees?.feeItems?.length > 0 && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>Title Rates</p>
                </div>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography className={classes.f10}>
                      <strong>Policy Type</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.f10}>
                      <strong>Rate Type</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.trf10}>
                      <strong>Gross Premium (Actual)</strong>
                    </Typography>
                  </Grid>
                  {fileSummaryData.rateFees?.isDisplayNetPremiumAmount && (
                    <Grid item xs={2}>
                      <Typography className={classes.trf11}>
                        <strong>Net Premium</strong>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {fileSummaryData.rateFees.feeItems.map((feeItem: FeeItem, index: number) => (
                  <Grid container spacing={1} key={index}>
                    <Grid item xs={5} className={classes.m1}>
                      <Typography className={classes.f9}>{feeItem.rateFeeDescr}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.m1}>
                      <Typography className={classes.f9}>{feeItem.policyType}</Typography>
                    </Grid>
                    {feeItem.grossPremiumAmount && (
                      <Grid item xs={2} className={classes.m1}>
                        <Typography className={clsx(classes.f9, classes.fr)}>{feeItem.grossPremiumAmount}</Typography>
                      </Grid>
                    )}
                    {fileSummaryData.rateFees?.isDisplayNetPremiumAmount && feeItem.netDueAmount && (
                      <Grid item xs={2} className={classes.m1}>
                        <Typography className={clsx(classes.f9, classes.fr, classes.pr2)}>
                          {feeItem.netDueAmount}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Grid container spacing={1}>
                  <Grid item xs={8} className={classes.middle}>
                    <Typography className={classes.f10}>
                      <strong>Total</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={clsx(classes.middle, classes.txtr)}>
                    <Typography className={classes.f9}>
                      <strong>{fileSummaryData.rateFees.grossPremiumTotal}</strong>
                    </Typography>
                  </Grid>
                  {fileSummaryData.rateFees?.isDisplayNetPremiumAmount && (
                    <Grid item xs={2} className={clsx(classes.middle, classes.txtr, classes.pr3)}>
                      <Typography className={classes.f9}>
                        <strong>{fileSummaryData.rateFees.netPremiumTotal}</strong>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <p className={classes.f9}>
                  <em>Date of Policy used to calculate premium and fees.</em>
                </p>
              </>
            )}
          </>
          {/* Recording Fees */}
          <>
            {fileSummaryData.recordingFees?.recordingFeeCost?.length > 0 && (
              <>
                <div className={classes.blueBar}>
                  <p className={classes.caps}>Recording Fees</p>
                </div>
                <p className={classes.notes}>
                  Note: This section has been requested from a Third Party System and is not visible in AgentNet Web.
                </p>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.f10}>
                      <strong>Buyer</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.f10}>
                      <strong>Seller</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.f10}>
                      <strong>Total</strong>
                    </Typography>
                  </Grid>
                </Grid>
                {fileSummaryData.recordingFees.recordingFeeCost.map((recordingFee: RecordingFee, index: number) => (
                  <RecordingFeeCost key={index} recordingFeeCost={recordingFee} />
                ))}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography className={classes.f10}>
                      <strong>Total for Recording Fees</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {(fileSummaryData.recordingFees?.totalBuyer ?? -1) >= 0 && (
                      <Typography className={classes.f10}>
                        <strong>
                          {Number(fileSummaryData.recordingFees?.totalBuyer).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </strong>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {(fileSummaryData.recordingFees?.totalSeller ?? -1) >= 0 && (
                      <Typography className={classes.f10}>
                        <strong>
                          {Number(fileSummaryData.recordingFees?.totalSeller).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </strong>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {(fileSummaryData.recordingFees?.total ?? -1) >= 0 && (
                      <Typography className={classes.f10}>
                        <strong>
                          {Number(fileSummaryData.recordingFees?.total).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </strong>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
          {/* Recorder's Office Information */}
          <>
            {fileSummaryData.recordingFees &&
              fileSummaryData.recordingFees.recordingFeeAddress &&
              fileSummaryData.recordingFees.recordingFeeAddress.length > 0 && (
                <>
                  <div className={classes.blueBar}>
                    <p className={classes.caps}>Recorder&apos;s Office Information</p>
                  </div>
                  <Grid container spacing={1}>
                    {['MAILING', 'COURIER'].map((type) => {
                      const address = fileSummaryData.recordingFees.recordingFeeAddress.find(
                        (addr) => addr.type === type,
                      );
                      return (
                        <Grid item xs={4} key={type}>
                          <Typography className={clsx(classes.f9, classes.p1)}>
                            <strong>{type === 'MAILING' ? 'Mailing Address:' : 'Courier Address:'}</strong>
                          </Typography>
                          {address && address?.city ? (
                            <>
                              <Typography className={classes.f9}>{address.attention}</Typography>
                              <Typography className={classes.f9}>{address.address1}</Typography>
                              <Typography className={classes.f9}>{address.address2}</Typography>
                              <Typography
                                className={classes.f9}
                              >{`${address.city}, ${address.state}, ${address.zip}`}</Typography>
                            </>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </Grid>
                      );
                    })}
                    <Grid item xs={4}>
                      <Typography className={clsx(classes.f9, classes.pt1)}>
                        <strong>Phone:</strong> {fileSummaryData.recordingFees.phone}
                      </Typography>
                      <Typography className={classes.f9}>
                        <strong>Fax:</strong> {fileSummaryData.recordingFees.fax}
                      </Typography>
                      <Typography className={classes.f9}>
                        <strong>Office Hours:</strong> {fileSummaryData.recordingFees.businessHours}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
          </>
          {/* Underwriting Request */}
          <>
            {fileSummaryData.underwritingRequestinfo &&
              fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation?.requestId > 0 && (
                <>
                  <div className={classes.blueBar}>
                    <p className={classes.caps}>Underwriting Request</p>
                  </div>
                  <Grid container spacing={1} className={classes.pt1}>
                    {[
                      {
                        label: 'Request ID:',
                        value:
                          fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation?.requestId,
                      },
                      {
                        label: 'Request Name:',
                        value:
                          fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation?.requestName,
                      },
                      {
                        label: 'Status:',
                        value: `${
                          fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation?.requestStatus
                        } on ${moment(
                          fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation?.modifiedDate,
                        ).format('MM/DD/YYYY')} by ${
                          fileSummaryData.underwritingRequestinfo.underwritingRequest?.requestInformation
                            ?.modifiedByUserName
                        }`,
                      },
                      {
                        label: 'Risks:',
                        value: fileSummaryData.underwritingRequestinfo.underwritingRequest?.risks
                          ?.map((risk) => risk.name)
                          .join(', '),
                      },
                    ].map((item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={2}>
                          <Typography className={classes.f9}>
                            <strong>{item.label}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography className={classes.f9}>{item.value}</Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </>
              )}
          </>
          {/* Disclaimer */}
          <>
            <br />
            <br />
            <div className={classes.f7}>
              <p>
                <strong>Disclaimer:</strong>
              </p>
              <p>This report is generated solely for the use of {fileSummaryReportData?.firmName}. </p>
              <p>
                The property location information on this report was compiled from various sources and is deemed
                reliable but not guaranteed.
              </p>
              <p>
                First American Order Insights is a service offered exclusively to First American’s policy issuing agents
                for the purpose of aiding in the preparation of title policies underwritten by First American Title
                Insurance Company and its affiliated insurer entities. This service utilizes agent-provided data,
                alongside First American&apos;s internal data and data from third-party resources, to identify potential
                matters of concern or property record mismatches signaling the necessity for further investigation and
                security measures. Please be aware that the data presented in Order Insights is purely informational and
                requires verification by the issuing Agent, as no assurance can be given regarding the accuracy of the
                internal data or third-party information provided through Order Insights. Additionally, it is important
                to recognize that many of the parties and properties reported may be victims of identity theft or other
                forms of fraud. The inclusion of persons or properties in Order Insight lists is not an indication that
                the person or property was involved in disreputable activities but serves as a warning that extra
                vigilance is essential to ensure the protection of all transaction participants.
              </p>
              <p>
                The title rates and recording fees on this report are derived from the First American Comprehensive
                Calculator (FACC). FACC is an Internet-based platform, which provides our customers with a user-friendly
                method of obtaining estimates for the title insurance premiums, endorsements charges, recording fees,
                and transfer tax. There may be variables that need to be considered in determining the final rate to be
                charged, including geographic and transaction-specific items which are beyond the functionality provided
                by the FACC. All estimates obtained through the use of this calculator are dependent upon the accuracy
                of the information entered into the calculator. Please contact your local First American office to
                confirm your quote. Contact information for First American offices in your area is available at
                www.firstam.com.
              </p>
            </div>
          </>
        </div>
      )}
    </div>
  );
}
