import React from 'react';
import clsx from 'clsx';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface TopNavTabProps {
  label: string;
  testId?: string;
  disabled?: boolean;
  hidden?: boolean;
}
interface TopNavProps {
  activeTab: number;
  tabs: TopNavTabProps[];
  onTabChange: (newValue: number) => void;
  children?: React.ReactNode;
}

const TopNav = ({ activeTab, tabs, onTabChange, children }: TopNavProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tabWrap: {
        backgroundColor: theme.palette.brand.lightGrey25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: '.3s all',
        padding: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: 'fixed',
        zIndex: 5,
        width: '100%',
        '& .MuiTab-root': {
          margin: `0 ${theme.spacing(2)}px`,
        },
      },
      tabWrapInner: {
        display: 'flex',
        justifyContent: 'space-between',
        width: `calc(100% - ${theme.spacing(3)}px)`,
      },
    }),
  );

  const classes = useStyles();

  const handleTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Paper square elevation={0} className={clsx(classes.tabWrap)}>
      <div className={classes.tabWrapInner}>
        <Tabs value={activeTab} indicatorColor="primary" onChange={handleTabChange}>
          {tabs &&
            tabs.length &&
            tabs.map(
              (tab: TopNavTabProps, i) =>
                !tab.hidden && <Tab label={tab.label} Data-QA={tab.testId} key={i} disabled={tab.disabled} />,
            )}
        </Tabs>
        {children && children}
      </div>
    </Paper>
  );
};

export default TopNav;
