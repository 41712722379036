import React, { ReactNode } from 'react';
import { Drawer, DrawerProps, Button, Box, makeStyles, Theme, ButtonProps } from '@material-ui/core';
import DrawerTitle from '../DrawerTitle';
import DrawerContent from '../DrawerContent';
import DrawerActions from '../DrawerActions';

export type FormDrawerProps = DrawerProps & {
  children?: ReactNode;
  header?: string;
  width?: number;
  onDismissAction?: () => void;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  primaryActionProps?: ButtonProps;
  dismissActionProps?: ButtonProps;
  secondaryActionProps?: ButtonProps;
  testId?: string;
  loading?: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100vw',
  },
  buttonsContainer: {
    [theme.breakpoints.only('xs')]: {
      minHeight: 'auto',
      flexDirection: 'column',
      display: 'flex',
      paddingBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      '& > :first-child': {
        marginLeft: theme.spacing(2),
      },
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  secondaryButton: {
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      flex: 1,
    },
  },
}));
const FormDrawer = ({
  header,
  children,
  width = 600,
  onPrimaryAction = () => undefined,
  onDismissAction = () => undefined,
  onSecondaryAction = () => undefined,
  primaryActionLabel,
  secondaryActionLabel,
  dismissActionProps,
  primaryActionProps,
  secondaryActionProps,
  testId = 'form-drawer',
  loading = false,
  ...props
}: FormDrawerProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Drawer anchor="right" {...props}>
      <Box style={{ width: width }} className={classes.root} data-testid={testId}>
        <DrawerTitle title={header} />
        <DrawerContent>{children}</DrawerContent>
        <DrawerActions loading={loading}>
          {secondaryActionLabel && (
            <div className={classes.secondaryButton}>
              <Button
                id="secondary-action"
                variant="text"
                color="primary"
                size="large"
                onClick={onSecondaryAction}
                {...secondaryActionProps}
              >
                {secondaryActionLabel}
              </Button>
            </div>
          )}
          <div className={classes.buttonsContainer}>
            <Button onClick={onPrimaryAction} id="primary-action" color="primary" size="large" {...primaryActionProps}>
              {primaryActionLabel}
            </Button>
            <Button
              onClick={onDismissAction}
              id="dismiss-action"
              color="secondary"
              variant="outlined"
              size="large"
              {...dismissActionProps}
            >
              Cancel
            </Button>
          </div>
        </DrawerActions>
      </Box>
    </Drawer>
  );
};
export default FormDrawer;
