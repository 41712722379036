import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { FileDataContext, FileDataContextInterface } from '../../../../hooks/FileDataContext';
import AgentNetDropdownSelector from '../../../../ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetTextInput } from '../../../../ui-kit/inputs/TextField';
import { LenderClauseTypeValue, MyLenderDropdown } from '../../../../api/file/constants';
import { lenderDomesticAddressSchema } from '../../../../utilities/validation/schemas';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';
import ErrorSection from '../../file-create/ErrorSection';
import CurrencyField from '../../../../ui-kit/inputs/CurrencyField/CurrencyField';
import PhoneField from '../../../../ui-kit/inputs/PhoneField/PhoneField';
import { mortgageClause, MortgageClauseTextOptions } from './MortgageClause';
import AutocompleteSearch from 'ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import DomesticAddressField from 'features/files/file-create/DomesticAddress';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import './AddLenderForm.scss';
import { ILenderFirm } from '../interfaces/LenderModels';

export enum SubFormGroupID {
  DomesticAddress = 'domestic-address',
}

export interface LenderPropertiesProps extends IAgentNetBaseFormGroupProps<IGetLenderResponse> {
  displayDefaultLenderOption?: boolean;
  resetErr?: () => void;
  handleClose?: () => void;
  withHeader?: boolean;
  isFail?: boolean;
  userFirmId?: string | undefined;
  lenderList?: any;
  lenderFirmDetails?: ILenderFirm[];
  firstLoad?: boolean;
  myLendersRequired?: boolean;
  editMode?: boolean;
  getLenderStatus?: any;
  qaAttrPrefix?: string;
}

// This is used only during File edit, not File Create
const AddLenderForm = ({
  displayDefaultLenderOption: displayDefaultLenderOption,
  value,
  onChange,
  showAllValidation,
  lenderList = [],
  lenderFirmDetails,
  validationErrors,
  myLendersRequired,
  setSubFormGroupValidity,
  editMode,
  getLenderStatus,
  qaAttrPrefix = '',
}: LenderPropertiesProps) => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;

  const noMatchingLenderOptionValue = {
    name: MyLenderDropdown.noMatchingLender,
    value: MyLenderDropdown.noMatchingLender,
  };

  const defaultValue = useRef(value);

  const formatLender = () => {
    if (defaultValue.current.address) {
      const { address1, address2, city, state, postalCode } = defaultValue.current.address;
      const option = `${defaultValue.current.name}, ${address1}${
        address2 && address2.trim() ? `, ${address2}` : ''
      }, ${city}${state ? `, ${state}` : ''} ${postalCode}`;
      return {
        name: option.trim(),
        value:
          defaultValue.current.lenderAddressBookId ||
          defaultValue.current.addressBookEntryId ||
          defaultValue.current.name,
      };
    }
    return {};
  };

  useEffect(() => {
    defaultValue.current = value;
  }, [lenderList]);
  /* Call to action: Form submission display error validations */
  useEffect(() => {
    if (showAllValidation) {
      onChange({ ...value });
    }
  }, [showAllValidation]);

  const onLenderClauseTypeChange = (type: string) => {
    for (const obj in MortgageClauseTextOptions) {
      if (obj === type) {
        if (type === 'MERS') {
          onChange({
            ...value,
            lenderClauseType: type,
            lenderClauseText: MortgageClauseTextOptions[obj].value?.replace('{{ COMPANY NAME }}', String(value?.name)),
          });
        } else {
          onChange({
            ...value,
            lenderClauseType: type,
            lenderClauseText: MortgageClauseTextOptions[obj].value,
          });
        }
      }
    }
  };

  const resetForm = (val: string) => {
    onChange({
      ...value,
      name: '',
      attention: '',
      loanNumber: '',
      loanAmount: '',
      lenderClauseType: mortgageClause[0].name,
      lenderClauseText: MortgageClauseTextOptions[mortgageClause[0].name].value,
      emailAddress: '',
      phoneNumber: '',
      lenderTypeOptions: val,
      address: {
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        state: '',
      },
    });
  };

  const lenderOptions = (lenderList ?? []).map((el: any) => {
    return { name: el?.name, value: el?.value };
  });

  const handleLenderInfo = (val: string) => {
    if (!lenderFirmDetails || lenderFirmDetails.length === 0 || Object.keys(lenderFirmDetails).length === 0) {
      if (val === MyLenderDropdown.noMatchingLender) {
        resetForm(MyLenderDropdown.noMatchingLender);
        return;
      }
    } else {
      if (typeof val === 'string' && val !== MyLenderDropdown.noMatchingLender) {
        const convertedLenderFieldValues: IGetLenderResponse = {
          lenderAddressBookId: defaultValue.current?.lenderAddressBookId,
          attention: defaultValue.current?.attention || '',
          lenderClauseType: !defaultValue.current?.lenderClauseType
            ? mortgageClause[0].name
            : defaultValue.current?.lenderClauseType,
          lenderClauseText: !defaultValue.current?.lenderClauseType
            ? MortgageClauseTextOptions[mortgageClause[0].name].value
            : defaultValue.current?.lenderClauseText,

          phoneNumber: defaultValue.current?.phoneNumber || '',
          emailAddress: defaultValue.current?.emailAddress || '',
          address: {
            address1: defaultValue.current?.address?.address1 || '',
            address2: defaultValue.current?.address?.address2 || '',
            city: defaultValue.current?.address?.city || '',
            county: defaultValue.current?.address?.county || '',
            postalCode: defaultValue.current?.address?.postalCode || '',
            state: defaultValue.current?.address?.state || '',
          },
          name: defaultValue.current?.name || '',
          lenderId: defaultValue.current?.lenderId,
        };
        onChange({ ...value, ...convertedLenderFieldValues, lenderTypeOptions: val });
      } else {
        const lenderFirm = lenderOptions
          ? lenderFirmDetails?.find((lender) => String(lender.AddressBookEntryId) === String(val))
          : null;

        const convertedLenderFieldValues: IGetLenderResponse = {
          lenderAddressBookId: lenderFirm?.AddressBookEntryId,
          attention: lenderFirm?.LenderAttentionName || '',
          lenderClauseType: !lenderFirm?.LenderClauseType ? mortgageClause[0].name : lenderFirm?.LenderClauseType,
          lenderClauseText: !lenderFirm?.LenderClauseType
            ? MortgageClauseTextOptions[mortgageClause[0].name].value
            : lenderFirm?.LenderClauseText,
          phoneNumber: lenderFirm?.PhoneNumber || '',
          emailAddress: lenderFirm?.EmailAddress || '',
          address: {
            address1: lenderFirm?.Address.Address1 || '',
            address2: lenderFirm?.Address.Address2 || '',
            city: lenderFirm?.Address.City || '',
            county: lenderFirm?.Address.County || '',
            postalCode: lenderFirm?.Address.PostalCode || '',
            state: lenderFirm?.Address.State || '',
          },
          name: lenderFirm?.Name || '',
          lenderId: value.lenderId,
        };

        if (lenderFirm || val === MyLenderDropdown.noMatchingLender) {
          onChange({ ...value, ...convertedLenderFieldValues, lenderTypeOptions: val });
        }
      }
    }
  };

  const getLenderIdValue = useMemo((): string => {
    if (value?.lenderTypeOptions === '') {
      return value.lenderTypeOptions;
    }
    return typeof value?.lenderTypeOptions === 'string' && (value?.lenderAddressBookId || value?.addressBookEntryId)
      ? value?.lenderAddressBookId || value?.addressBookEntryId
      : value?.lenderTypeOptions ?? value?.lenderAddressBookId ?? MyLenderDropdown.noMatchingLender;
  }, [value?.lenderTypeOptions, value?.lenderAddressBookId, value?.addressBookEntryId, lenderList]);

  /**
   * Display the Add/Update lender in addressbook checkbox, depending on lender type
   */
  const renderAddUpdateLender = () => {
    return (
      <>
        {!editMode && getLenderIdValue === MyLenderDropdown.noMatchingLender && (
          <Grid item xs={12}>
            <div>
              <AgentNetCheckBox
                label="Add lender to address book"
                {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}AddtoAddressBook` } : {})}
                value={value?.addToAddressBook ?? false}
                checkHandler={(val: boolean) => onChange({ ...value, addToAddressBook: val })}
              />
            </div>
          </Grid>
        )}
      </>
    );
  };

  const composeLenderTypeOptions = useMemo(() => {
    const name = value && value.name;
    const lender = formatLender();

    const nameInLenderOptions = (lenderList ?? []).some((option: any) => option.value === lender.value);
    let finalOptions;
    if (nameInLenderOptions) {
      finalOptions = lenderList;
    } else if (name && editMode) {
      finalOptions = [{ name: lender.name, value: lender.value, createdDate: new Date().getTime() }, ...lenderList];
    } else {
      finalOptions = [...lenderList];
    }

    const sortedLendersDetail = finalOptions
      .sort((firstLender: any, secondLender: any) => {
        if (firstLender.name.toLowerCase() === secondLender.name.toLowerCase()) {
          return secondLender.createdDate - firstLender.createdDate;
        } else if (firstLender.name.toLowerCase() > secondLender.name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      })
      ?.map((el: any) => {
        return { name: el?.name, value: el?.value };
      });
    return [noMatchingLenderOptionValue, ...sortedLendersDetail];
  }, [value.name, lenderList, defaultValue.current]);

  return (
    <>
      <section className="lender-form-section">
        <Grid container spacing={3} className="lender-form-container">
          <Grid item xs={6} className="lender-form">
            <AutocompleteSearch
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              label="My Lenders"
              required={!displayDefaultLenderOption}
              error={
                validationErrors?.some((err) => err.field === 'myLenders') && showAllValidation && myLendersRequired
              }
              errs={validationErrors}
              helperText={
                validationErrors?.some((err) => err.field === 'myLenders') &&
                myLendersRequired &&
                showAllValidation &&
                'My Lenders is required.'
              }
              showValidation={showAllValidation}
              name="myLenders"
              options={composeLenderTypeOptions}
              disableGrouping
              hideSearchIcon
              onChange={(val) => handleLenderInfo(val)}
              value={getLenderIdValue}
              hideNoMatches
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}MyLenders` } : {})}
            />
          </Grid>
          <Grid item xs={3}>
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              fullWidth
              label={'Loan Number'}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}LoanNumber` } : {})}
              value={value?.loanNumber}
              name="loanNumber"
              showValidation={showAllValidation}
              errs={validationErrors}
              onChange={(e) => {
                onChange({ ...value, loanNumber: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <CurrencyField
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              fullWidth
              label={'Loan Amount'}
              id="loanAmount"
              defaultValue={0}
              name={'loanAmount'}
              errs={validationErrors}
              value={value?.loanAmount}
              max={100000000000}
              allowNegative={false}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}LoanAmount` } : {})}
              onChange={(e) => onChange({ ...value, loanAmount: e.target.value })}
            />
            <ErrorSection errs={validationErrors} field={'loanAmount'} />
          </Grid>
          <Grid item xs={6}>
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}CompanyName` } : {})}
              fullWidth
              required
              id="name"
              name="name"
              label={'Company Name'}
              value={value?.name}
              onChange={(e) => {
                onChange({
                  ...value,
                  name: e.target.value,
                  ...(value?.lenderClauseType === LenderClauseTypeValue.mers
                    ? {
                        lenderClauseText: MortgageClauseTextOptions[LenderClauseTypeValue.mers].value?.replace(
                          '{{ COMPANY NAME }}',
                          String(e.target.value.trim()),
                        ),
                      }
                    : {}),
                });
              }}
              errs={validationErrors}
              showValidation={showAllValidation}
            />
          </Grid>
          <Grid item xs={6}>
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              fullWidth
              errs={validationErrors}
              showValidation={showAllValidation}
              label={'Attention'}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}Attention` } : {})}
              value={value?.attention}
              name={'attention'}
              onChange={(e) => {
                onChange({ ...value, attention: e.target.value });
              }}
            />
          </Grid>
          {getLenderStatus === 'success' && renderAddUpdateLender()}
          <Grid item style={{ width: '100% ' }}>
            <DomesticAddressField
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              qaAttributePrefix={qaAttrPrefix}
              schema={lenderDomesticAddressSchema}
              displayRequiredAddressFields={true}
              useGoogleAutocomplete={true}
              getAllState={value?.address?.stateOrProvince ? false : true}
              value={{
                address1: value?.address?.address1,
                address2: value?.address?.address2,
                city: value?.address?.city,
                postalCode: value?.address?.postalCode,
                state: value?.address?.stateOrProvince ?? value?.address?.state,
                stateOrProvince: value?.address?.stateOrProvince ?? value?.address?.state,
              }}
              isOutsideJurisdiction={true}
              showAllValidation={showAllValidation}
              setIsValid={(isValid) => {
                setSubFormGroupValidity && setSubFormGroupValidity(SubFormGroupID.DomesticAddress, isValid);
              }}
              onChange={(e) => {
                onChange({ ...value, address: { ...e, state: e.stateOrProvince, postalCode: e.postalCode as string } });
              }}
              stateOrProvinceField={true}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneField
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              name={'phoneNumber'}
              label={'Phone Number'}
              value={value?.phoneNumber}
              showValidation={showAllValidation}
              errs={validationErrors}
              fullWidth
              onChange={(e: any) => {
                onChange({ ...value, phoneNumber: e?.target?.value });
              }}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}PhoneNumber` } : {})}
            />
          </Grid>
          <Grid item xs={6}>
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              fullWidth
              name="emailAddress"
              showValidation={showAllValidation}
              errs={validationErrors}
              label={'Email'}
              value={value?.emailAddress?.trim() ?? ''}
              onChange={(e) => {
                onChange({ ...value, emailAddress: e.target.value.trim() ?? '' });
              }}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}Email` } : {})}
            />
          </Grid>
          <Grid item xs={6} className="lender-address-info-section-container">
            <AgentNetDropdownSelector
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              name="mortgageClause"
              id="mortgageClause"
              label="Care of, DBA, Mortgage Clause"
              options={mortgageClause}
              value={value.lenderClauseType ?? ''}
              menuOption={(val) => {
                onLenderClauseTypeChange(val);
              }}
              dropdowntype="outlined"
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}CareofDBAMortgageClause` } : {})}
            />
          </Grid>
          <Grid item xs={12} className="lender-address-info-section-container">
            <AgentNetTextInput
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              variant="outlined"
              fullWidth
              multiline
              rows="2"
              label="Mortgage Clause"
              value={value?.lenderClauseText}
              onChange={(e) => {
                onChange({ ...value, lenderClauseText: e.target.value });
              }}
              {...(qaAttrPrefix ? { 'data-qa': `${qaAttrPrefix}MortgageClauseNotes` } : {})}
            />
          </Grid>
        </Grid>
      </section>
    </>
  );
};
export default AddLenderForm;
