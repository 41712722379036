import React from 'react';
import Grid from '@material-ui/core/Grid';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import { reopenedFileInfoText } from './reportsListConfig';
import { Tooltip } from '@material-ui/core';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface ReopenedFileIconProps {
  iconOnly?: boolean;
}

const ReopenedFileIcon: React.FC<ReopenedFileIconProps> = ({ iconOnly }) => {
  return iconOnly ? (
    <div style={{ display: 'flex', alignItems: 'center', color: '#C77700' }}>
      <Tooltip title={reopenedFileInfoText}>
        <HistoryOutlinedIcon fontSize="small"></HistoryOutlinedIcon>
      </Tooltip>
    </div>
  ) : (
    <Grid
      item
      style={{
        margin: pxToRem(0, 24),
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        width: '130px',
        justifyContent: 'space-evenly',
      }}
    >
      <Tooltip title={reopenedFileInfoText}>
        <HistoryOutlinedIcon style={{ color: '#C77700' }} fontSize="small"></HistoryOutlinedIcon>
      </Tooltip>
      Reopened File
    </Grid>
  );
};

export default ReopenedFileIcon;
