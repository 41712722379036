import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import { Notification } from 'ui-kit/components/notification/Notification';
import clsx from 'clsx';
import { ColDef } from 'ag-grid-enterprise';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import '../files/files-summary/files-list/FilesList.scss';
import '../ServiceOrderInvoices/Invoice.scss';
import { useAuth } from '@agentnet/auth';
import useAsync from 'hooks/useAsync';
import { getPrefferedOffices, setPrefferedOffices } from 'api/admin/preferred-offices-api';
import { SavePreferredOffices } from 'api/admin/interfaces';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import { KBLinks } from 'features/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: theme.palette.common.white,
    },
    contentWrap: {
      padding: pxToRem(0, 0, 32),
    },
    mb: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      width: '926px',
    },
    preferredOfficeDesc: {
      marginLeft: theme.spacing(3),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    userName: {
      marginBottom: theme.spacing(0.5),
    },
    heading: {},
    captionDivider: {
      width: theme.spacing(2),
      display: 'inline-block',
    },
    fieldSection: {},
    fieldSectionLeft: {
      padding: theme.spacing(3),
    },
    fieldSectionRight: {
      padding: theme.spacing(3),
      marginTop: 0,
    },
    adornmentIcon: {
      padding: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    infoIcon: {
      alignItems: 'flex-start',
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    alertContainer: {
      marginBottom: '63px',
    },
    alertMsg: {
      width: '-webkit-fill-available',
      position: 'absolute',
      bottom: '63px',
      left: 0,
      padding: '0 35px',
    },
    tableStyles: {
      marginTop: '0px !important',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: 'calc(100vh - 410px) !important',
    },
  }),
);

const prefferedOfficeContent = () => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const { addSnackbarMessage } = useSnackBars();
  const [gridApi, setGridApi] = useState<any>(null);
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setpreferredOffice } = profileCtx;
  const preferredOfficesData = async () => {
    const token = await getAccessToken();
    const prefferedOfficeresponse = await getPrefferedOffices(token);
    setRowData(prefferedOfficeresponse);
  };

  useEffect(() => {
    if (gridApi) {
      rowData.forEach((row: { IsSelected: number }, index: any) => {
        if (row.IsSelected > 0) {
          gridApi.forEachNode((node: any) => {
            if (node.rowIndex === index) {
              node.setSelected(true);
            }
          });
        }
      });
    }
  }, [gridApi, rowData]);
  const { execute: executePreferredOfficeData, status: getPreferredOfficeData } = useAsync(preferredOfficesData, false);
  const actionStatus = getPreferredOfficeData === 'pending' ? 'pending' : 'idle';
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = useCallback(() => {
    const currentselectedRows = gridRef.current?.api.getSelectedRows();
    setSelectedRows(currentselectedRows);
  }, []);

  const savePreferredOffices = async () => {
    if (selectedRows) {
      const jurisdictionIds: number[] = selectedRows.map((o: any) => o.FirmLocationJurisdictionID);

      if (jurisdictionIds !== undefined) {
        const saveOffices: SavePreferredOffices = {
          FirmLocationJurisdictionIds: jurisdictionIds.join(','),
        };
        const token = await getAccessToken();
        const saveResponse = await setPrefferedOffices(token, saveOffices);
        const prefferedOfficeresponse = await getPrefferedOffices(token);
        if (setpreferredOffice) {
          setpreferredOffice(prefferedOfficeresponse);
        }
        if (saveResponse.Status) {
          addSnackbarMessage({
            message: 'Saving Successful',
            type: 'success',
          });
        } else {
          addSnackbarMessage({
            message: 'Save Failed',
            type: 'error',
          });
        }
      }
      window.location.reload();
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    // call get the list API here;
    executePreferredOfficeData();
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const prefferedOfficeColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: 'Firm',
      field: 'FirmName',
      tooltipField: 'FirmName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Office',

      field: 'BusinessName',
      tooltipField: 'BusinessName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'State',
      maxWidth: 100,
      // tooltipField: 'state',
      filter: 'agTextColumnFilter',

      field: 'StateCode',
      tooltipField: 'StateCode',
      // filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Underwriter',
      field: 'UnderwriterName',
      tooltipField: 'UnderwriterName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Account',
      maxWidth: 140,
      field: 'FirmLocationJurisdictionID',
      tooltipField: 'FirmLocationJurisdictionID',
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'ALTA ID',
      maxWidth: 140,
      // tooltipField: 'altaid',
      filter: 'agTextColumnFilter',
      field: 'ALTAID',
      tooltipField: 'ALTAID',
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <LoadingSpinner status={actionStatus} variant="linear" />
        {rowData.length !== 0 && (
          <PageHeader
            title="Preferred Offices"
            subtitle="Customize your file creation workflow by selecting which offices from your current firm's authorized list should appear in the office selection dropdown menu. This personalized filter helps streamline file creation by displaying only your chosen offices within the active firm."
            menuItems={[
              {
                label: 'Knowledge Base',
                link: KBLinks.prefferedOffice,
              },
            ]}
          />
        )}

        {rowData.length !== 0 && (
          <div className={classes.mb2}>
            <Grid item sm={12}>
              <Notification inline severity="info" className={classes.infoIcon}>
                If multiple offices work on the same file, it is recommended to select all offices to avoid file
                duplication.
              </Notification>
            </Grid>
          </div>
        )}
        {rowData.length !== 0 && (
          <div className={clsx('ag-theme-alpine table-grid', classes.tableStyles)}>
            <DataTable
              columnDefs={prefferedOfficeColumnsConfig}
              ref={gridRef}
              rowData={rowData}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        )}

        {rowData.length !== 0 && (
          <BottomAppBar
            primaryActionLabel="Save"
            onPrimaryClick={() => savePreferredOffices()}
            cancelLabel="Clear All"
            onCancel={() => window.location.reload()}
          />
        )}
      </div>
    </>
  );
};

export default prefferedOfficeContent;
