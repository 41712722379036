import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@material-ui/core';
import { FieldValidationError } from '../../utilities/validation/validation';
import { Control } from 'react-hook-form';
import ErrorSection from '../../features/files/file-create/ErrorSection';
import PDFIcon from '../icons/PDF';
import { Edit, HelpOutline, InfoOutlined } from '@material-ui/icons';
import Pdf from '../../common-assets/files/Kentucky Municipal Tax Bulletin 2024-2025.pdf';
import { pxToRem } from '@fluentsms/agentnet-web-components';

export type AgentTextInputProps = TextFieldProps & {
  error?: boolean;
  helperText?: string | React.ReactNode;
  control?: Control;
  label?: string;
  rows?: string | number;
  errorMap?: Record<string, string[]>;
  startAdornment?: React.ReactNode;
  endAdornment?: JSX.Element;
  errs?: FieldValidationError[];
  id?: string;
  icon?: 'pdf' | 'info' | 'help';
  variant?: 'outlined' | 'filled' | 'standard';
  isEditable?: boolean | undefined;
  showValidation?: boolean;
  showValidationOnBlur?: boolean;
  showValidationOnFocus?: boolean;
  counter?: boolean;
  addPropertyClicked?: boolean;
  firstLoad?: boolean;
  tooltip?: string;
  title?: string | undefined;
  tooltipQAPrefix?: string;
  hideEditable?: boolean;
  resetDisabled?: any;
  setResetDisabled?: any;
};

export function AgentNetTextInput({
  error,
  endAdornment,
  startAdornment,
  InputProps,
  inputProps,
  errs,
  variant = 'outlined',
  label,
  id,
  name,
  onFocus,
  onBlur,
  icon,
  isEditable = false,
  disabled,
  showValidation,
  showValidationOnBlur = true,
  showValidationOnFocus = false,
  counter = false,
  addPropertyClicked,
  firstLoad,
  tooltip,
  title,
  tooltipQAPrefix = '',
  hideEditable,
  resetDisabled = false,
  setResetDisabled,
  ...rest
}: AgentTextInputProps): JSX.Element {
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);
  const displayErrorOnCallback = (callback: () => void) => {
    callback();
  };

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  const [isDisabled, setDisabled] = React.useState(isEditable);
  useEffect(() => {
    //set internal state
    if (resetDisabled) {
      setDisabled(true);
      setResetDisabled && setResetDisabled(false);
    }
  }, [resetDisabled]);

  const val = rest.value as string;

  const FieldBase = (
    <TextField
      InputProps={{
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
        ...InputProps,
      }}
      inputProps={{
        ...inputProps,
        title: title ? title : val ? val : label,
      }}
      InputLabelProps={{ shrink: true }}
      error={(errors && displayErrors) || error}
      variant={variant}
      label={label}
      onBlur={(e) => {
        showValidationOnBlur && displayErrorOnCallback(() => onBlur && onBlur(e));
      }}
      onFocus={(e) => {
        showValidationOnFocus && displayErrorOnCallback(() => onFocus && onFocus(e));
      }}
      disabled={disabled ? disabled : isDisabled}
      helperText={
        <Box component="span" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{rest.helperText}</span>
          {counter && inputProps?.maxLength && (
            <span>{`${(rest.value as string)?.length} / ${inputProps?.maxLength}`}</span>
          )}
        </Box>
      }
      {...rest}
    />
  );

  function handleEdit() {
    setDisabled(!isDisabled);
  }

  const FieldBaseEditable = (
    <TextField
      InputProps={{
        endAdornment: hideEditable ? null : (
          <InputAdornment position="end">
            <IconButton
              aria-label="Edit Field"
              onClick={handleEdit}
              disableRipple
              disableFocusRipple
              style={{ padding: 0 }}
            >
              <Edit fontSize="large" htmlColor={isDisabled ? '#666666' : '#217DD4'} />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        title: title ? title : val ? val : label,
        ...inputProps,
      }}
      InputLabelProps={{ shrink: true }}
      error={(errors && displayErrors) || error}
      variant="outlined"
      label={label}
      onBlur={(e) => {
        displayErrorOnCallback(() => onBlur && onBlur(e));
      }}
      onFocus={(e) => {
        displayErrorOnCallback(() => onFocus && onFocus(e));
      }}
      disabled={isDisabled}
      {...rest}
    />
  );

  const iconStyles = { marginLeft: pxToRem(12), marginTop: '6px' };
  return (
    <>
      {!icon ? (
        isEditable ? (
          <Box style={{ display: 'flex', alignItems: 'center' }}>{FieldBaseEditable}</Box>
        ) : (
          FieldBase
        )
      ) : (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {FieldBase}
          {icon === 'pdf' && (
            <a href={Pdf} target="_blank" rel="noreferrer">
              <PDFIcon fontSize="large" style={iconStyles} />
            </a>
          )}
          {icon === 'info' &&
            (tooltip ? (
              <Tooltip data-qa={tooltipQAPrefix + 'ToolTip'} title={tooltip}>
                <InfoOutlined fontSize="large" style={iconStyles} color="primary" />
              </Tooltip>
            ) : (
              <InfoOutlined fontSize="large" style={iconStyles} color="primary" />
            ))}
          {icon === 'help' &&
            (tooltip ? (
              <Tooltip data-qa={tooltipQAPrefix + 'ToolTip'} title={tooltip}>
                <HelpOutline fontSize="large" style={iconStyles} color="primary" />
              </Tooltip>
            ) : (
              <HelpOutline fontSize="large" style={iconStyles} color="primary" />
            ))}
        </Box>
      )}
      {!firstLoad && errors && name && displayErrors && <ErrorSection errs={errs} field={name} />}
    </>
  );
}
