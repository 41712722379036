import React, { createContext, FC, ReactNode, useCallback, useReducer } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

interface GlobalMsgContext {
  state: any;
  addGlobalMsg: any;
  removeGlobalMsg: any;
  removeAllGlobalMsgOnNav: any;
}
interface Message {
  id: string;
  type: string;
  msg?: string;
}
enum MsgType {
  ADD_MESSAGE = 'ADD_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  REMOVE_ALL_MESSAGES = 'REMOVE_ALL_MESSAGES',
}
interface SnackbarAction {
  type: MsgType;
  payload?: any;
}
interface SnackbarState {
  messages: Message[];
  totalMsgs: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '50vw',
    maxWidth: '750px',
    marginBottom: theme.spacing(2),
  },
}));

export const GlobalMsgCtx = createContext<GlobalMsgContext>({} as GlobalMsgContext);

const GlobalMsgReducer = (state: SnackbarState, action: SnackbarAction) => {
  switch (action.type) {
    case MsgType.ADD_MESSAGE:
      return {
        ...state,
        messages: [{ type: 'success', ...action.payload }, ...state.messages].slice(0, state.totalMsgs),
      };
    case MsgType.REMOVE_MESSAGE:
      return { ...state, messages: state.messages.filter((message) => message.id !== action.payload.id) };
    case MsgType.REMOVE_ALL_MESSAGES:
      return { ...state, messages: [] };
  }
};

export const GlobalMsgProvider: FC<{
  children: ReactNode;
  totalMsgs?: number;
}> = ({ children, totalMsgs = 10 }) => {
  const [state, dispatch] = useReducer(GlobalMsgReducer, { messages: [], totalMsgs });
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    removeAllGlobalMsgOnNav(null);
  }, [location]);

  const addGlobalMsg = useCallback(
    (data: any) => {
      const id = (Math.random() + 1).toString(36).substring(7);
      dispatch({ type: MsgType.ADD_MESSAGE, payload: { id, ...data } });
    },
    [dispatch],
  );
  const removeGlobalMsg = useCallback(
    (id: any) => {
      dispatch({ type: MsgType.REMOVE_MESSAGE, payload: { id } });
    },
    [dispatch],
  );
  const removeAllGlobalMsgOnNav = useCallback(
    (id: any) => {
      dispatch({ type: MsgType.REMOVE_ALL_MESSAGES, payload: { id } });
    },
    [dispatch],
  );

  const classes = useStyles();

  return (
    <GlobalMsgCtx.Provider value={{ state, addGlobalMsg, removeGlobalMsg, removeAllGlobalMsgOnNav }}>
      {children}
      <div className="snackbar-messenger-wrapper">
        <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <div>
            {state.messages.map(({ type, title, message, id, onClose, html = false, variant = 'filled' }) => (
              <Alert
                key={id}
                className={classes.root}
                severity={type}
                onClose={() => {
                  removeGlobalMsg(id);
                  onClose && onClose();
                }}
                variant={variant}
              >
                {title && <AlertTitle>{title}</AlertTitle>}
                {html ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
              </Alert>
            ))}
          </div>
        </Snackbar>
      </div>
    </GlobalMsgCtx.Provider>
  );
};
