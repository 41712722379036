import { Files } from '../layout-core/core-content/FileContent/FileContent';
import ServiceContent from '../layout-core/core-content/ServiceContent';
import SdnContent from '../../features/services/sdnAdhoc/SdnContent';
import BackTitleContent from '../../features/services/backtitleAdhoc/BackTitleContent';
import FilesList from '../../features/files/files-summary/files-list/FilesList';
import FileInfoContainer from '../layout-core/core-content/FileContent/FileInfoContainer';
import CPLSummary from '../../features/cpl/CPLContainer';
import JacketsContainer from '../../features/jackets/JacketsContainer';
import Underwriting from '../../features/underwriting/Underwriting';
import ServiceOrdersContainer from '../../features/serviceorder/ServiceOrdersContainer';
import Verification from '../../utilities/verification/verification';
import Dashboard from '../layout-core/core-content/Dashboard';
import Reports from '../layout-core/core-content/Remittance/ReportContent';
import Invoices from '../layout-core/core-content/ServiceOrderInvoices/InvoiceContent';
import { FileSummaryMap } from 'features/files/files-summary/file-summary-google-map/FileSummaryMap';
import OnBehalfOFSearch from 'core/layout-core/core-content/OnBehalfOFSearch';
import Billing from 'core/layout-core/core-content/Payment/Billing';
import PolicyUpload from 'features/services/policyupload/PolicyUpload';
import AdminUserProfileContainer from 'features/admin/AdminUserProfileContainer';
import AdminUserProfileContent from 'features/admin/AdminUserProfileContent';
import PrefferedOfficeContent from 'features/admin/preferredOfficeContent';
import ContactUs from 'utilities/contact-us/contact-us';
import AvlList from 'features/services/agencyVerificationLetters/AvlList';
import IdaasContent from 'features/admin/IdaasContent';
import statements from 'features/statements/Statements';
import MyReports from '../layout-core/core-content/Reports/MyReportsContent';
import documents from 'features/documents/documentsContainer';
import AccountRegistration from '../../utilities/account-registration/account-registration';
import ForgotPassword from '../../utilities/Authentication/ForgotPassword';
import ForgotUserID from '../../utilities/Authentication/ForgotUserID';
import AddressBook from 'features/admin/AddressBook';
import HelpHomePage from 'features/help/HelpHomePage';
import { ArticlePage } from 'features/help/ArticlePage';
import { TopicPage } from 'features/help/TopicPage';
import { SearchResultPage } from 'features/help/SearchResultPage';
import Signature from 'features/admin/e-signatures/Signature';
import autoReporting from 'features/admin/autoReporting/autoReporting';
import ManageUsers from 'features/admin/manageUsers/manageUsers';
import IdaasSignUp from 'features/admin/IdaasSignUp';
import IdaasSignedUp from 'features/admin/IdaasSignedUp';
import UserStatus from 'features/admin/UserStatus';
import { FileSummaryReport } from 'features/pdf/fileSummary/FileSummaryReport';
import OrderInsightsContent from 'features/files/order-insights/OrderInsightsContent';
import FirmRedirect from 'features/firms/FirmRedirect';
import OrderManagement from '../layout-core/core-content/OrderManagement/OrderManagementContent';
import { ServiceOrders } from 'core/layout-core/core-content/Dashboards/ServiceOrders/ServiceOrders';

export type RouteType = {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  isPublic?: boolean;
  layout?: string;
  routes?: Array<RouteType>;
  isAdminView?: boolean;
  location?: any;
  requiredPermission?: string | string[];
  isNested?: boolean;
};

export const routes: Array<RouteType> = [
  {
    path: '/verification',
    component: Verification,
    isPublic: true,
  },
  {
    path: '/contact-us',
    component: ContactUs,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/account-registration',
    component: AccountRegistration,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/Authentication/ForgotPassword',
    component: ForgotPassword,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/Authentication/ForgotUserID',
    component: ForgotUserID,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/searchFirms',
    component: OnBehalfOFSearch,
    layout: 'AuthLayout',
  },
  {
    component: Reports,
    layout: 'AuthLayout',
    path: '/accounting/remittance/:action/:firmId?',
    requiredPermission: ['hasFirmId', 'pay', 'remit'],
  },
  {
    path: '/accounting/service-order-invoices/pay',
    component: Invoices,
    layout: 'AuthLayout',
  },
  {
    path: '/accounting/service-order-invoices/view-invoices/:firmId?',
    component: Invoices,
    layout: 'AuthLayout',
  },
  {
    path: '/services',
    component: ServiceContent,
    routes: [
      {
        path: '/services/sdn-search',
        component: SdnContent,
      },
      {
        path: '/services/back-title-search',
        component: BackTitleContent,
      },
      {
        path: '/services/agency-verification-letters',
        component: AvlList,
      },
      {
        path: '/services/policy-upload',
        component: PolicyUpload,
      },
      {
        path: '/services/policy-upload/upload',
        component: PolicyUpload,
      },
      {
        path: '/services/policy-upload/history',
        component: PolicyUpload,
      },
    ],
    layout: 'AuthLayout',
  },
  {
    path: '/dashboards',
    component: ServiceOrders,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId'],
    routes: [
      {
        path: '/dashboards/service-orders',
        component: ServiceOrders,
      },
    ],
  },
  {
    path: '/accounting/service-order-invoices/pay',
    component: Invoices,
    layout: 'AuthLayout',
    requiredPermission: ['pay', 'remit'],
  },
  {
    path: '/files',
    component: Files,
    layout: 'AuthLayout',
    routes: [
      {
        path: '/files/:fileId/order-insights',
        component: OrderInsightsContent,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/file-info',
        component: FileInfoContainer,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/map/:fileId',
        component: FileSummaryMap,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/service-orders',
        component: ServiceOrdersContainer,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/cpl',
        component: CPLSummary,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/jackets',
        component: JacketsContainer,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/underwriting',
        component: Underwriting,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/back-title',
        component: BackTitleContent,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files/:fileId/documents',
        component: documents,
        requiredPermission: ['hasFirmId'],
      },
      {
        path: '/files',
        component: FilesList,
        requiredPermission: ['hasFirmId'],
      },
    ],
    requiredPermission: ['hasFirmId'],
  },
  {
    path: '/admin/address-book',
    component: AddressBook,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId', 'behalfOfUser'],
  },
  {
    path: '/statements/:firmId?',
    component: statements,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId'],
  },
  {
    path: '/admin/billing',
    component: Billing,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId', 'behalfOfUser'],
  },
  {
    path: '/admin/user/',
    component: AdminUserProfileContainer,
    layout: 'AuthLayout',
    routes: [
      {
        path: '/admin/user/profile/update',
        component: IdaasContent,
        isAdminView: false,
        requiredPermission: ['behalfOfUser'],
      },
      {
        path: '/admin/user/profile',
        component: AdminUserProfileContent,
        isAdminView: false,
        requiredPermission: ['behalfOfUser'],
      },
      {
        path: '/admin/user/preferred-offices',
        component: PrefferedOfficeContent,
        isAdminView: false,
        requiredPermission: ['behalfOfUser'],
      },
    ],
  },
  {
    path: '/authentication/register/:p?',
    component: IdaasSignUp,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/authorization/eula',
    component: IdaasSignedUp,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/authorization/userstatus/507',
    component: UserStatus,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/authorization/userstatus/401',
    component: UserStatus,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/authorization/userstatus/500',
    component: UserStatus,
    layout: 'PublicLayout',
    isPublic: true,
  },
  {
    path: '/admin/auto-reporting',
    component: autoReporting,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId', 'behalfOfUser'],
  },
  {
    path: '/admin/e-signatures',
    component: Signature,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId', 'behalfOfUser'],
  },
  {
    path: '/admin/users/manage',
    component: ManageUsers,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId', 'behalfOfUser'],
  },
  {
    path: '/statements',
    component: statements,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId'],
  },
  {
    path: '/reporting',
    component: MyReports,
    layout: 'AuthLayout',
    requiredPermission: ['hasFirmId'],
  },
  {
    path: '/help',
    component: HelpHomePage,
    layout: 'HelpLayout',
    routes: [
      {
        path: '/help/search',
        component: SearchResultPage,
      },
      {
        path: '/help/:topic/:article',
        component: ArticlePage,
      },
      {
        path: '/help/:topic',
        component: TopicPage,
      },
    ],
  },
  {
    path: '/pdf/fileSummaryReport/:fileId',
    component: FileSummaryReport,
    layout: 'PDFLayout',
    isPublic: true,
  },
  {
    path: '/firms/:firmId',
    component: FirmRedirect,
  },
  // Order Management Section
  {
    path: '/order-management',
    component: OrderManagement,
    layout: 'AuthLayout',
  },
  {
    path: '/:err?',
    component: Dashboard,
    layout: 'AuthLayout',
  },
];
