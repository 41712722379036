import React, { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import './AgentNetButton.scss';

export interface ICustomButtonProps extends ButtonProps {
  hide?: boolean;
  danger?: boolean;
  loading?: boolean;
  'data-qa'?: string;
  plusIcon?: boolean;
}

const AgentNetButton: FC<ICustomButtonProps> = (props: ICustomButtonProps): JSX.Element => {
  return (
    <>
      {!props.hide && (
        <Button
          disableRipple
          disableElevation
          {...props}
          className={`MuiAgentNetButton ${props.danger ? 'Mui-Error' : ''} ${props.className ? props.className : ''}`}
          startIcon={props.plusIcon ? <AddCircleOutlinedIcon /> : props.startIcon}
        >
          {props.loading && <CircularProgress size={20} className="MuiAgentNetButton-Spinner" />}
          {props.children}
        </Button>
      )}
    </>
  );
};

export default AgentNetButton;
