import React from 'react';
import { Typography } from '@material-ui/core';
import NoPartiesIcon from 'ui-kit/icons/NoParties';
import NoBacktitle from 'ui-kit/icons/NoBacktitle';
import AgentNetButton from '../button/AgentNetButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import NoFilesToReview from 'ui-kit/icons/NoFilesToReview';
import NoRatesIcon from 'ui-kit/icons/NoRates';

interface NoResultsProps {
  type: string;
  onClick?: (p: any) => void;
  titleText?: string;
  pText?: string;
  buttonText?: string;
  disableButton?: boolean;
  className?: string;
  dataQA?: string;
  showButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    pText: {
      maxWidth: 472,
      color: theme.palette.text.secondary,
    },
    noResults: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
    },
  }),
);

const NoResults = (props: NoResultsProps) => {
  const { type, onClick, buttonText, disableButton, titleText, pText, className, dataQA, showButton = true } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.noResults, className && className)}>
      <div className={classes.mb3}>
        {type === 'parties' && <NoPartiesIcon />}
        {type === 'results' && <NoBacktitle />}
        {type === 'files' && <NoFilesToReview />}
        {type === 'proposed-policies' && <NoRatesIcon />}
      </div>
      <Typography variant="h3" className={classes.mb1}>
        {titleText ? titleText : 'No Results'}
      </Typography>
      <Typography variant="body1" className={clsx(classes.mb3, classes.pText)}>
        {pText ? pText : 'Would you like to add one now?'}
      </Typography>
      {showButton ? (
        <AgentNetButton
          variant="contained"
          color="primary"
          size="small"
          className={classes.mb2}
          onClick={onClick}
          Data-QA={dataQA}
          disabled={disableButton}
        >
          {buttonText ? buttonText : 'Add New'}
        </AgentNetButton>
      ) : null}
    </div>
  );
};

export default NoResults;
