import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import './PolicyUpload.scss';
import { makeStyles } from '@material-ui/core/styles';
import { KBLinks, PolicyNotice } from 'features/constants';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import History from './History';
import { FileWithPath } from 'react-dropzone';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { deletePolicyUpload, postPolicyUpload } from 'api/policyupload/policyupload-api';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { Files, PolicyUploadResponse } from './type';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import Uploader from 'ui-kit/components/uploader/Uploader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const PolicyUpload = (): JSX.Element => {
  const notice = PolicyNotice;
  const { getAccessToken } = useAuth();
  const classes = useStyles();
  const [documents, setDocuments] = useState<Files[]>([]);
  const [docList, setDocList] = useState<Files[]>([]);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [isSearching, setSearchFlag] = useState(false);
  const [isConfirmUpload, setConfirmUpload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [successUploads, setSuccessUploads] = useState<string[]>([]);
  const [errorUploads, setErrorUploads] = useState<string[]>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();
  const [uploadedDocs, setUploadedDocs] = useState<PolicyUploadResponse[]>([]);
  const [isdeleteDocument, setDeleteDocument] = useState('');
  const [allFiles, setAllFiles] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const cancelUploadPolicy = () => {
    refreshUpload();
  };
  const uploadPolicy = () => {
    let documentNameList: Files[] = [];
    const uploadedDocuments = uploadedDocs?.flatMap((x) => x.DocumentResponse);
    if (docList && uploadedDocuments) {
      documentNameList = docList.map((file) => ({
        Name: uploadedDocuments.find((e) => e.DocumentName == file.Name)?.DocumentKey as string,
        Content: '',
        Size: 0,
      }));
    }
    documentNameList.length > 0 && executePolicyUpload(documentNameList).then(); // uploaded docs name is empty then it should not call it
  };

  const refreshUpload = () => {
    setUploadedDocs([]);
    setDocList([]);
    setAllFiles([]);
  };
  const handleDocuments = async (uploads: FileWithPath[]) => {
    let totalSize = 0;
    setLoading(true);
    setSearchFlag(false);
    //let batchFiles: File[][] = []; // Array to hold batches of files
    let currentBatch: Files[] = []; // Current batch of files
    let currentBatchSize = 0; // Size of the current batch
    const MAX_BATCH_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const documentList: Files[] = [];
    const promises = [];
    if (uploads) {
      for (const file of uploads) {
        totalSize = currentBatchSize + file.size;
        const document: Files = {
          Name: file.name,
          Content: await getAsByteArray(file),
          Size: file.size,
        };
        documentList.push(document);
        currentBatch.push(document);
        currentBatchSize += file.size;
        if (currentBatchSize > MAX_BATCH_SIZE) {
          // If adding this file would exceed the max batch size,
          // start a new batch
          //batchFiles.push(currentBatch);
          const clonedDocumentBatch = JSON.parse(JSON.stringify(currentBatch));
          currentBatch = [];
          currentBatchSize = 0;
          promises.push(executeSavePolicyUpload(clonedDocumentBatch));
        }
      }

      if (currentBatch.length > 0) {
        const clonedDocumentBatch = JSON.parse(JSON.stringify(currentBatch));
        promises.push(executeSavePolicyUpload(clonedDocumentBatch)); //Call API here
      }
      Promise.all(promises)
        .then((results) => {
          setSearchFlag(false);
          setConfirmUpload(true);
        })
        .catch((e) => {
          console.error(e);
          setSearchFlag(false);
        });
      setDocuments(documentList);
    }
  };

  const getAsByteArray = async (file: Blob) => {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  };

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const deleteDocument: any = async (file?: any) => {
    let docName = '';
    const documentResponse = uploadedDocs?.flatMap((x) => x.DocumentResponse).find((e) => e.DocumentName == file.name);
    if (documentResponse?.DocumentName != null) {
      docName = documentResponse.DocumentKey;
    }
    setFile(file);
    setDeleteDocument(docName);
  };

  useEffect(() => {
    if (isdeleteDocument) {
      executeDeletePolicyUpload().then();
      setDocList(docList.filter((e) => e.Name !== file.name));
      setSuccessUploads(docList.filter((e) => e.Name !== file.name).flatMap((e) => e.Name));
      if (docList.length == 1) {
        setConfirmUpload(false);
      }
    }
  }, [isdeleteDocument]);

  const savePolicyUploadDataS3 = async (documentBatch: Files[]): Promise<PolicyUploadResponse> => {
    setSearchFlag(true);
    setLoading(true);
    const token = await getAccessToken();
    return postPolicyUpload(
      {
        FirmId: (userFirm?.firmId as unknown as number) ?? 0,
        Documents: documentBatch,
        Action: 'Save',
      },
      token,
    );
  };

  const policyUploadData = async (documentList: Files[]): Promise<PolicyUploadResponse> => {
    const token = await getAccessToken();
    return postPolicyUpload(
      {
        FirmId: (userFirm?.firmId as unknown as number) ?? 0,
        Documents: documentList,
        Action: 'ConfirmUpload',
      },
      token,
    );
  };

  const deleteUploadData = async (): Promise<boolean> => {
    const token = await getAccessToken();
    return deletePolicyUpload(
      {
        FirmId: (userFirm?.firmId as unknown as number) ?? 0,
        DocumentKey: isdeleteDocument,
      },
      token,
    );
  };

  const {
    execute: executeSavePolicyUpload,
    value: savePolicyUploadDataValue,
    status: savePolicyUploadDataStatus,
    errors: savePolicyUploadDataError,
    errorDetails: savePolicyUploadDataErrorDetails,
  } = useAsync<PolicyUploadResponse>((documentBatch: Files[]) => savePolicyUploadDataS3(documentBatch), false);

  const {
    execute: executePolicyUpload,
    value: policyUploadDataValue,
    status: policyUploadDataStatus,
    errors: policyUploadDataError,
  } = useAsync<PolicyUploadResponse>((documentList: Files[]) => policyUploadData(documentList), false);

  const {
    execute: executeDeletePolicyUpload,
    value: deletePolicyUploadDataValue,
    status: deletePolicyUploadDataStatus,
    errors: deletePolicyUploadDataError,
  } = useAsync<boolean>(deleteUploadData, false);

  useEffect(() => {
    if (savePolicyUploadDataValue) {
      //setSearchFlag(false);
      // setLoading(false);
      const successUploads = savePolicyUploadDataValue?.DocumentResponse.flatMap((x) => x.DocumentName);
      setSuccessUploads((prevDocs) => [...prevDocs, successUploads] as string[]);
      setUploadedDocs((prevDocs) => [...prevDocs, savePolicyUploadDataValue]);
    }
  }, [savePolicyUploadDataValue]);

  useEffect(() => {
    if (savePolicyUploadDataErrorDetails) {
      setSearchFlag(false);
      setConfirmUpload(false);
      const errorUploads = savePolicyUploadDataErrorDetails.args.flat().flatMap((e: any) => e.Name);
      setErrorUploads((prevDocs) => [...prevDocs, errorUploads]);
    }
  }, [savePolicyUploadDataErrorDetails]);

  useEffect(() => {
    if (policyUploadDataStatus === 'success') {
      addSnackbarMessage({
        message: `Upload Successful`,
        type: 'success',
      });
      refreshUpload();
      setSearchFlag(false);
      setConfirmUpload(false);
    }
  }, [policyUploadDataStatus]);

  useEffect(() => {
    setDisplayError(savePolicyUploadDataStatus === 'error');
  }, [savePolicyUploadDataStatus]);

  useEffect(() => {
    const errorMessages = savePolicyUploadDataError?.length
      ? savePolicyUploadDataError
      : policyUploadDataError?.length
      ? policyUploadDataError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    setDocList([...docList, ...documents]);
  }, [documents]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  const isPolicyUpload = () => location.pathname.endsWith('policy-upload/upload');

  return (
    <ContentContainer>
      <div className="paper-layout contained">
        <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
      </div>

      {isPolicyUpload() ? (
        <div className="policy-search-container">
          <PageHeader
            title="Upload Policies"
            subtitle="The policy upload tool is for uploading policy schedules and endorsements that are reported or being reported. It does not submit policies for accounting or remittance purposes. Policies typically appear in the Back Title database within 2 weeks."
            menuItems={[
              {
                label: 'Knowledge Base',
                link: KBLinks.policyUpload,
              },
            ]}
          />
          <Grid item sm={12} className="policy-search-content">
            <Uploader
              handleFiles={handleDocuments}
              onDeleteItem={deleteDocument}
              files={allFiles}
              clearAll={docList.length === 0}
              listTitle="Uploaded Document(s)"
              successUploads={successUploads}
              errorUploads={errorUploads}
              isServerUpload={isLoading}
              maxSize={30}
              acceptTypes={{
                'application/pdf': ['.pdf'],
                'image/tif': ['.tif'],
                'image/tiff': ['.tiff'],
                'image/jpg': ['.JPG'],
                'image/bmp': ['.bmp'],
                'application/msword': ['.doc'],
                'application/x-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/rtf': ['.rtf'],
                'application/txt': ['.txt'],
                'application/gif': ['.GIF'],
                'application/msg': ['.msg'],
                'application/htm': ['.htm'],
                'application/png': ['.png'],
                'application/xml': ['.xml'],
              }}
              Data-QA="PolicyUploadDocumentUpload"
            />
          </Grid>
        </div>
      ) : (
        <History firmId={userFirm?.firmId ?? ''} />
      )}

      {isPolicyUpload() && (
        <BottomAppBar
          onCancel={cancelUploadPolicy}
          primaryActionLabel="Confirm Upload"
          primaryActionDataQa="ConfirmUpload"
          cancelDataQa="UploadCancel"
          onPrimaryClick={uploadPolicy}
          disableCancel={false}
          showPrimary={true}
          disablePrimary={!isConfirmUpload}
          accordionView
        />
      )}
    </ContentContainer>
  );
};

export default PolicyUpload;
