import React, { useEffect, useState } from 'react';
import { Firm, Office, Profile } from '../api/profile/types';
import { ADMIN_ROLE_ID, getDefaultFirm } from '../api/profile/profile-utilities';
import { useAuth } from '@agentnet/auth';
import { getProfile } from 'api/profile/profile-api';
import useAsync, { NoSessionError } from './useAsync';
import { PrismUserProfile } from 'api/prismPropertyProfile/types';
import { getPrefferedOffices } from 'api/admin/preferred-offices-api';
import { getProfileDetails } from 'api/admin/user-profile-api';

export interface ProfileContextInterface {
  userFirm?: Firm | null;
  currentOffice?: Office | null;
  setUserFirm?: (userFirm: Firm) => void;
  setCurrentOffice?: (currentOffice: Office) => void;
  profile?: Profile | null;
  latestProfile?: any;
  setProfile?: (profile: Profile) => void;
  prismUserProfile?: PrismUserProfile | null;
  pfNotifications?: number;
  setPfNotifications?: (pfNotifications: number) => void;
  children?: React.ReactNode;
  isAdmin?: boolean;
  setNavigated?: any;
  profileStatus?: 'idle' | 'pending' | 'success' | 'error';
  preferredOffice?: any;
  setpreferredOffice?: (preferredOffice: any) => void;
  getFirmNameByFirmId?: (firmId: string) => string;
  reloadProfile?: () => void;
}

const ProfileContext = React.createContext<ProfileContextInterface | null>(null);

const ProfileProvider = (props: ProfileContextInterface): JSX.Element => {
  const { getAccessToken, account, signOut } = useAuth();
  const selectedUserFirm = JSON.parse(localStorage.getItem('userFirm') ?? '{}');
  const [userFirm, setUserFirm] = useState<Firm>(selectedUserFirm ?? { firmId: '', name: '' });
  const [currentOffice, setCurrentOffice] = useState<Office>();
  const storedProfile = JSON.parse(localStorage.getItem('profile') ?? 'null');
  const [profile, setProfile] = useState<Profile>(storedProfile);
  const [latestProfile, setLatestProfile] = useState<any>();
  const [prismUserProfile, setPrismUserProfile] = useState<PrismUserProfile>();
  const [pfNotifications, setPfNotifications] = useState(0);
  const selectedPreferredOffice = JSON.parse(localStorage.getItem('preferredOffice') ?? 'null');
  const [preferredOffice, setpreferredOffice] = useState<any>(selectedPreferredOffice);
  const setNavigated = (value: any) => {
    window.localStorage.setItem('isNavigated', value);
  };
  const isAdmin = profile?.roleId == ADMIN_ROLE_ID;

  const getProfileData = async (): Promise<Profile> => {
    const token = await getAccessToken();
    const prefferedOfficeresponse = await getPrefferedOffices(token);
    window.localStorage.setItem('preferredOffice', JSON.stringify(prefferedOfficeresponse));
    setpreferredOffice(prefferedOfficeresponse);
    return getProfile(token);
  };

  const {
    execute: executeGetProfile,
    value: profileData,
    status: profileStatus,
  } = useAsync<Profile>(getProfileData, false);

  const reloadProfile = async () => {
    executeGetProfile()
      .then()
      .catch((error) => {
        if (error instanceof NoSessionError) signOut().then();
      });
    const token = await getAccessToken();
    const reponseLatestProfile = await getProfileDetails(token);
    setLatestProfile(reponseLatestProfile);
  };

  useEffect(() => {
    let apiCallActive = true;
    if (apiCallActive && account) {
      reloadProfile();
    }
    return () => {
      apiCallActive = false;
    };
  }, [account]);

  useEffect(() => {
    if (profileData && setProfile) {
      if (!profileData?.userId) signOut().then();

      setProfile(profileData);
      window.localStorage.setItem('profile', JSON.stringify(profileData));
      const defaultFirm = getDefaultFirm(profileData.firms);
      if (defaultFirm && !userFirm?.firmId) {
        window.localStorage.setItem('userFirm', JSON.stringify(defaultFirm));
        setUserFirm && setUserFirm(defaultFirm);
      }

      const prismUser: PrismUserProfile = {
        firstName: profileData?.firstName ?? '',
        lastName: profileData?.lastName ?? '',
        email: profileData?.emailAddress ?? '',
        primaryPhone: profileData?.phoneNumber
          ? profileData?.phoneNumber.substring(profileData?.phoneNumber.length - 10)
          : '',
        idaaSguid: profileData?.azureObjectId ?? '',
        idaaSuserId: profileData?.login ?? '',
        idaaStype: 'B2C',
      };
      setPrismUserProfile(prismUser);
    }
  }, [profileData]);

  const getFirmNameByFirmId = (firmId: string) => {
    return profileData?.firms?.find((firm: Firm) => String(firm.firmId) === String(firmId))?.name ?? 'N/A';
  };

  const ctx: ProfileContextInterface = {
    currentOffice,
    userFirm,
    setCurrentOffice,
    setUserFirm,
    profile,
    latestProfile,
    setProfile,
    prismUserProfile,
    pfNotifications,
    setPfNotifications,
    profileStatus,
    setNavigated,
    setpreferredOffice,
    preferredOffice,
    getFirmNameByFirmId,
    reloadProfile,
  };

  return <ProfileContext.Provider value={ctx}>{props.children}</ProfileContext.Provider>;
};

export { ProfileContext, ProfileProvider };
