import React, { useEffect, useState } from 'react';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Popper, Typography, IconButton, TextField } from '@material-ui/core';
import { AgentNetDropdownSelector, AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import './StandaloneEndorsement.scss';
import { FieldValidationError } from 'utilities/validation/validation';
import { JacketEndorsement } from './types';
import CloseIcon from 'ui-kit/icons/Close';
import PDFIcon from 'ui-kit/icons/PDF';
import { pxToRem } from '@fluentsms/agentnet-web-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    endorsementsComponentWrap: {
      width: '100%',
      position: 'relative',
    },
    popper: {
      backgroundColor: '#FFF',
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      zIndex: 2000,
    },
    popperContent: {
      width: 400,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    },
    popperHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #c4c4c4',
      padding: '0 8px 0 24px',
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '8px 16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    popperDisablePortal: {
      position: 'relative',
      width: '100% !important',
    },
    popperClose: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    inputBaseWrap: {
      padding: '16px 12px 12px',
      borderBottom: '1px solid #c4c4c4',
    },
    inputBase: {
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #C4C4C4',
        fontSize: 14,
        '&:focus': {
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          borderColor: '#c4c4c4',
        },
      },
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiSvgIcon-colorDisabled': {
        color: 'inherit',
      },
    },
    miscEndoAsterisk: {
      color: theme.palette.error.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
    lastColumnContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statCode: {
      justifyContent: 'space-between',
    },
    icons: {
      justifyContent: 'flex-end',
    },
    statCodeDropdown: {
      minWidth: '132px',
    },
    pdf: {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    statCodeHeader: {
      display: 'flex',
      paddingLeft: theme.spacing(1),
    },
    rowDisable: {
      opacity: 0.75,
    },
  }),
);

interface EndorsementsTableProps {
  index?: number;
  endorsements?: JacketEndorsement[];
  onChangeEndorsement: (
    jacketId: string | null,
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null,
  ) => void;
  isEditDisabled?: boolean;
  filterEndorsements?: () => void;
  endorsementOptions: Array<any>;
  validationErrors: FieldValidationError[];
  shouldShowValidation: boolean;
  defaultEndorsements?: JacketEndorsement[];
  closeEndorsement?: boolean;
  showStatCode?: boolean;
  openStatCodePDF?: () => void;
  shouldDisableScroll?: boolean;
}

const Endorsements = ({
  index,
  onChangeEndorsement,
  endorsements,
  isEditDisabled,
  filterEndorsements,
  endorsementOptions,
  validationErrors,
  shouldShowValidation,
  defaultEndorsements,
  closeEndorsement,
  showStatCode = false,
  shouldDisableScroll = false,
  openStatCodePDF,
}: EndorsementsTableProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [defaultCollection, setDefaultCollection] = useState<JacketEndorsement[]>([]);
  const open = Boolean(anchorEl);
  const handleClose = (e: any, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
      return;
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    let defaultCollection: JacketEndorsement[] = [];
    if (defaultEndorsements && defaultEndorsements.length > 0) {
      defaultCollection = [...defaultEndorsements];
    } else if (endorsements && endorsements.length > 0) {
      defaultCollection = endorsements.filter((e) => e.group === 'Default Endorsements');
    }
    setDefaultCollection(defaultCollection);
  }, [defaultEndorsements]);

  useEffect(() => {
    setAnchorEl(null);
  }, [closeEndorsement]);

  const isRemoveVisible = (starsNumber: string, endorsements: JacketEndorsement[]): boolean => {
    const isDefaultEndorsement = defaultCollection?.find((x) => x.endorsementFormNumber === starsNumber);
    const occurrences = endorsements.filter((e) => e.endorsementFormNumber === starsNumber);

    if (isDefaultEndorsement && occurrences?.length == 1) {
      return false;
    }

    return true;
  };

  const tableRef = React.useRef<null | any>(null);

  React.useEffect(() => {
    const lastEndo = endorsements && endorsements.length > 0 ? tableRef.current.lastChild : null;
    if (!shouldDisableScroll && endorsements && endorsements.length > 0 && tableRef.current) {
      if (lastEndo) {
        lastEndo.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [endorsements, shouldDisableScroll]);

  return (
    <div className={classes.endorsementsComponentWrap}>
      <div className="componentTitle">
        <AgentNetDivider
          typoVariant="h3"
          title={'Endorsements'}
          primaryButtonName={'Add Endorsements'}
          primaryButtonQaAttr={'JacketAddEndorsements' + [index]}
          primaryButtonDisable={isEditDisabled}
          onClickPrimary={(e: any) => {
            if (!anchorEl) {
              setAnchorEl(e.currentTarget);
              filterEndorsements?.();
            } else {
              setAnchorEl(null);
            }
          }}
          disableButton={isEditDisabled}
          disablePadding
        />
        <Popper className={classes.popper} placement="bottom-end" anchorEl={anchorEl} open={open}>
          <div className={classes.popperContent}>
            <div className={classes.popperHeader}>
              <Typography variant="h2" color="textPrimary">
                Add Endorsements
              </Typography>
              <IconButton
                className={classes.popperClose}
                aria-label="Close Endorsements Popper"
                onClick={() => setAnchorEl(null)}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            <Autocomplete
              options={endorsementOptions}
              getOptionLabel={(option) => option.endorsementName}
              open
              onClose={handleClose}
              multiple
              classes={{
                option: classes.popperOption,
                popperDisablePortal: classes.popperDisablePortal,
                paper: classes.popperPaper,
              }}
              value={endorsements}
              onChange={(e, v, r, d) => {
                onChangeEndorsement(
                  null,
                  null,
                  null,
                  d?.option.endorsementName ?? '',
                  null,
                  'add',
                  'Endorsement',
                  d?.option.endorsementFormNumber,
                );
              }}
              disablePortal
              renderTags={() => null}
              noOptionsText="No labels"
              disableCloseOnSelect
              renderInput={(params) => (
                <div className={classes.inputBaseWrap}>
                  <TextField
                    variant="outlined"
                    label="Search Endorsements"
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    className={classes.inputBase}
                    InputLabelProps={{ shrink: true }}
                    data-qa={'JacketSearchEndorsements' + [index]}
                  />
                </div>
              )}
            />
          </div>
        </Popper>
      </div>
      {endorsements && endorsements.length > 0 ? (
        <div className="endorsementsTable">
          <Grid container className="endorsementsTableHeader">
            <Grid item sm={3}>
              Endorsement
            </Grid>
            <Grid item sm={showStatCode ? 6 : 9}>
              Description
            </Grid>
            {showStatCode && (
              <Grid item sm={3} className={classes.statCodeHeader}>
                Stat Code{' '}
                <div title="Stat Codes">
                  <PDFIcon
                    className={classes.pdf}
                    fontSize="large"
                    onClick={openStatCodePDF}
                    data-qa={'statCodeIconEndorsement'}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container className="endorsementsTableList" ref={tableRef}>
            {endorsements.map((e: any, i) => (
              <Grid
                container
                key={i}
                className={clsx('tableRow', {
                  [classes.rowDisable]: e.isDisabled,
                })}
              >
                <Grid item sm={3} className="tableCell">
                  <Typography variant="body2" data-qa={'Jacket' + [index] + 'EndorsementType' + [i + 1]}>
                    {(e.endorsementFormNumber && e.endorsementFormNumber === 'MISC. END.') ||
                    (e.endorsementType && e.endorsementType === 'Miscellaneous') ? (
                      <>
                        MISC. END <span className={classes.miscEndoAsterisk}>*</span>
                      </>
                    ) : (
                      e.endorsementFormNumber
                    )}
                  </Typography>
                </Grid>
                <Grid item sm={showStatCode ? 6 : 8} className="tableCell">
                  {(e.endorsementFormNumber && e.endorsementFormNumber === 'MISC. END.') ||
                  (e.endorsementType && e.endorsementType === 'Miscellaneous') ? (
                    <Grid container spacing={1}>
                      <Grid item xs={8} data-qa={'Jacket' + [index] + 'MISCENDOName' + [i + 1]}>
                        <AgentNetTextInput
                          variant="outlined"
                          value={e.endorsementName}
                          name={'endorsementName' + [e.randomId]}
                          disabled={isEditDisabled || e.isDisabled}
                          onChange={(el) => {
                            onChangeEndorsement(
                              null,
                              e.randomId,
                              e.rateFeeLineItemId ?? e.id,
                              el.target.value,
                              null,
                              'update-name',
                              'MISC',
                              e.endorsementFormNumber,
                            );
                          }}
                          required={true}
                          errs={validationErrors}
                          showValidation={shouldShowValidation}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4} data-qa={'Jacket' + [index] + 'MISCENDOPremium' + [i + 1]}>
                        <CurrencyField
                          variant="outlined"
                          value={e.calculatedAmount}
                          disabled={isEditDisabled || e.isDisabled}
                          fullWidth
                          compact
                          max={100000000000}
                          allowNegative={false}
                          onChange={(el) => {
                            onChangeEndorsement(
                              null,
                              e.randomId,
                              e.rateFeeLineItemId ?? e.id,
                              null,
                              Number(el.target.value),
                              'update-amount',
                              'MISC',
                              e.endorsementFormNumber,
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Typography variant="body2" data-qa={'Jacket' + [index] + 'EndorsementName' + [i + 1]}>
                        {e.endorsementName}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  sm={showStatCode ? 3 : 1}
                  className={clsx(classes.lastColumnContainer, {
                    [classes.statCode]: showStatCode && e?.fields?.length > 0,
                    [classes.icons]: !(showStatCode && e?.fields?.length > 0),
                  })}
                >
                  {showStatCode &&
                    e?.fields?.map((field: any, j: number) => (
                      <div key={`${i}_${j}`} className={classes.statCodeDropdown}>
                        <AgentNetDropdownSelector
                          name={field.name}
                          qaAttribute={'Endorsement' + field.name}
                          options={field?.options?.map((x: any, i: any) => {
                            return { name: x.text, value: x.value };
                          })}
                          value={field.value}
                          menuOption={(val: string) => {
                            onChangeEndorsement(
                              null,
                              e.randomId,
                              e.rateFeeLineItemId ?? e.id,
                              val,
                              null,
                              'update-statcode',
                              'update-statcode',
                              e.endorsementFormNumber,
                            );
                          }}
                          dropdowntype="outlined"
                          disabled={field.isDisabled}
                          required={field.isRequired}
                        />
                      </div>
                    ))}
                  {isRemoveVisible(e.endorsementFormNumber, endorsements) && (
                    <IconButton
                      className={classes.deleteRow}
                      data-qa={'Jacket' + [index] + 'RemoveENDOIcon' + [i + 1]}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      disabled={isEditDisabled || e.isDisabled}
                      aria-label="delete endorsement"
                      onClick={() => {
                        onChangeEndorsement(
                          null,
                          e.randomId,
                          e.rateFeeLineItemId ?? e.id,
                          null,
                          null,
                          e.endorsementType == 'Endorsement' ? 'remove' : 'remove-misc',
                          e.endorsementType == 'Endorsement' ? 'Endorsement' : 'MISC',
                          e.endorsementFormNumber,
                        );
                      }}
                    >
                      <Close color="disabled" fontSize="large" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Endorsements;
