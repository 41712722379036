import { getAgencyApiHost } from 'api/host-by-env';

export type GetServicerOrders = {
  action?: string | null;
  dateFrom?: string;
  dateTo?: string;
  firmId: number;
};

export type GetServiceOrdersResult = {
  BusinessSegment: string;
  ETA: string;
  FileId: number;
  FileNumber: string;
  OrderNumber: string;
  ProductName: string;
  PropertyState: string;
  ServiceOrderReferenceId: number;
  Status: string;
  SubmittedDate: string;
  TransactionType: string;
};

export const getServiceOrders = async (
  payload: GetServicerOrders,
  token: string,
): Promise<GetServiceOrdersResult[]> => {
  const url = `${getAgencyApiHost()}service-order/v2/reports`;
  const response = await fetch(url, {
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    method: 'POST',
  });
  const json = await response.json();
  return json.Result;
};
