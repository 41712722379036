export const underwritingText = {
  CREATE_NEW_UNDERWRITING: `Would you like to create one now?`,
  NO_UNDERWRITING_SUPPORT: `No Underwritings Found.  File Status does not support Underwriting Request creation`,
  MAX_LIABILITY_TOOLTIP_MSG: `This number represents the Company's largest title policy liability
exposure in this transaction, not the combined Amount of Insurance for all the policies to be issued. For example,
if the proposed transaction involves a $10M owners policy and $7M loan policy, the Maximum Liability Amount is $10M`,
  ASSIGN_TO_TOOLTIP_MSG: `Default group inbox is encourged but you may select a specific underwriter 
or other group below`,
  // DUPLICATE_POLICY_TYPE_WARNING_MSG: `is already selected for the Underwriting Request.`,
  MISC_ENDO_OPTION: `MISC. END`,
  DELETE_ENDO_CONFIRM_MSG: `You are about to delete this endorsement. Are you sure you want to proceed?`,
  DELETE_POLICY_CONFIRM_MSG: `You are about to delete this policy. Are you sure you want to proceed?`,
  SUBMIT_UWR_CONFIRM_MSG: `You are about to submit this underwriting request to First American Underwriters. Are you sure you want to proceed?`,
  UWR_CANCEL_REQUEST_MSG: `Please leave your reason for cancelling this request`,
  UWR_REOPEN_REQUEST_MSG: `Optional: Leave a note below before reopening request`,
  UWR_AGENTNAME_ADDRESS: ` If an agent or an agent's address is not found, please specify all relevant information.`,
  UWR_SECONDPARTY: `Additional Parties: (The ability to add a 2nd Party to the Closing Protection Letter (CPL) is optional and used when two different parties are involved in the transaction. This may include Agent A acting as the Escrow/Settlement agent, and Agent B acting as the Title agent. Some lenders may require both parties be listed on the CPL and using the 2nd Party feature supports this need.)`,
  UWR_ADDITIONAL_ADDRESS_Note1: `There are scenarios where additional parties will be insureds on a single policy, such as when a lender like Wells Fargo makes the loan and it will be guaranteed by a governmental entity, such as the Secretary of Housing and Urban Development or a GSE, or where there is an additional purchaser with a separate address. Accordingly, the proposed insured would like this reflected on the “Lender” or “Buyer” CPL, as well.`,
  UWR_ADDITIONAL_ADDRESS_Note2: `Note that the Company has concerns about transactions involving multiple non-institutional lenders or multiple purchasers, such as when a “Tenancy-in-Common Investment Program” or similar scheme is involved. In either of those cases, consult Corporate Underwriting.`,
  UWR_ADDITIONAL_ADDRESS_TEXTBOX: `Addt'l Lender(s)/Addressee(s) name and address as it should appear on Coverage Letter`,
};

export const approvedAttorneyStates = [
  'AL',
  'DC',
  'DE',
  'GA',
  'IA',
  'LA',
  'MA',
  'MD',
  'NC',
  'OK',
  'PA',
  'SC',
  'VA',
  'WI',
  'WV',
];

export const agentClosingAttorneyStates = ['SC'];

export const myClosingAttorneyStates = ['NJ'];
