import resolveEnv, {
  CERT,
  DEV,
  DEV1,
  DEV2,
  IP,
  LOCAL,
  PROD,
  QA,
  QA1,
  QA2,
  SUPPORT,
  STAGE,
  TEST,
  UAT,
} from '@agentnet/utils';

// The host for most api calls, like get file info and search
export function getApiHost(): string {
  return `${environmentConfig.url}`;
}

//This url will be used only for document related APIs
//API Gateway has a limitation for Portal APIs
export function getAgencyApiHost(): string {
  return `${environmentConfig.agencyApiUrl}`;
}

//This url will be used only for Property Reports
export function getPrismUrl(): string {
  return `${environmentConfig.prismUrl}`;
}

//This url will be used only for AgentNet Knowledge Url
export function getAgentNetKnowledgeUrl(): string {
  return `${environmentConfig.agentNetKnowledgeUrl}`;
}

export function getContentfulSpaceId(): string {
  return environmentConfig.contentfulSpaceId;
}

export function getContentfulAccessToken(): string {
  return environmentConfig.contentfulAccessToken;
}

export function isContentfulPreview(): boolean {
  return environmentConfig.contentfulIsPreview;
}

export function isEnableAllFeatures(): boolean {
  return environmentConfig.enableAllFeatures ?? false;
}

export function getAnteUrl(): string {
  return environmentConfig.anteUrl ?? '';
}

const config: {
  [env: string]: {
    url: string;
    agencyApiUrl: string; //Agency API Url for consuming document related APIs
    prismUrl: string; //Prism Client Url for Property Reports
    agentNetKnowledgeUrl: string; //AgentNet Knowledge Url
    contentfulSpaceId: string; //Contentful Space Id
    contentfulAccessToken: string; //Contentful Access Token for either Preview or Delivery
    contentfulIsPreview: boolean; //Flag for is preview is enabled for contentful queries
    enableAllFeatures?: boolean; //Release Feature Toggle  for Policy Upload, Underwriter, Report & Pay
    anteUrl?: string; //AgentNet 3.0 Url
  };
} = {
  [LOCAL]: {
    url: 'https://env1.dev.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://env1.dev.agencyapi.firstam.net/',
    prismUrl: 'https://dev.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://dev.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'fS8SqZxxuRM4NyCbki85yzduHQ38_48r9RikdRt0IAU',
    contentfulIsPreview: true,
    enableAllFeatures: true,
    anteUrl: 'https://dev1.agentnet.firstam.net/',
  },
  [DEV]: {
    url: 'https://dev.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://dev.agencyapi.firstam.net/',
    prismUrl: 'https://dev.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://dev.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://dev.agentnet.firstam.net/',
  },
  [DEV1]: {
    url: 'https://env1.dev.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://env1.dev.agencyapi.firstam.net/',
    prismUrl: 'https://dev.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://dev.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://dev1.agentnet.firstam.net/',
  },
  [DEV2]: {
    url: 'https://env2.dev.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://env2.dev.agencyapi.firstam.net/',
    prismUrl: 'https://dev.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://dev.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://dev2.agentnet.firstam.net/',
  },
  [QA]: {
    url: 'https://qa.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://qa.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://qa.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'fS8SqZxxuRM4NyCbki85yzduHQ38_48r9RikdRt0IAU',
    contentfulIsPreview: true,
    enableAllFeatures: true,
    anteUrl: 'https://qa.agentnet.firstam.net/',
  },
  [QA1]: {
    url: 'https://env1.qa.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://env1.qa.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://qa.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'fS8SqZxxuRM4NyCbki85yzduHQ38_48r9RikdRt0IAU',
    contentfulIsPreview: true,
    enableAllFeatures: true,
    anteUrl: 'https://qa1.agentnet.firstam.net/',
  },
  [QA2]: {
    url: 'https://env2.qa.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://env2.qa.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://qa.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'fS8SqZxxuRM4NyCbki85yzduHQ38_48r9RikdRt0IAU',
    contentfulIsPreview: true,
    enableAllFeatures: true,
    anteUrl: 'https://qa2.agentnet.firstam.net/',
  },
  [TEST]: {
    url: 'https://test.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://test.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: false,
    // anteUrl: 'https://qa2.agentnet.firstam.com/',
  },
  [UAT]: {
    url: 'https://uat.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://uat.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: false,
    anteUrl: 'https://uat.agentnet.firstam.com',
  },
  [CERT]: {
    url: 'https://cert.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://cert.agencyapi.firstam.net/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://cert.agentnet.firstam.com',
  },
  [IP]: {
    url: 'https://api.agentnetstage.firstam.com/agentnet-service/api/v1.0/',
    agencyApiUrl: 'https://ip.agencyapi.firstam.com/',
    prismUrl: 'https://cert.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: false,
    anteUrl: 'https://ip.agentnet.firstam.com/',
  },
  [SUPPORT]: {
    url: 'https://support.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://support.agencyapi.firstam.net/',
    prismUrl: 'https://support.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://support.agentnet.firstam.net',
  },
  [STAGE]: {
    url: 'https://stage.services.agentnetportal.firstam.net/api/v1.0/',
    agencyApiUrl: 'https://stage.agencyapi.firstam.net/',
    prismUrl: 'https://stage.prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://uat.knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: true,
    anteUrl: 'https://stage.agentnet.firstam.net',
  },
  [PROD]: {
    url: 'https://api.agentnet.firstam.com/agentnet-service/api/v1.0/',
    agencyApiUrl: 'https://agencyapi.firstam.com/',
    prismUrl: 'https://prismpowered.com/agentnet/account/signin',
    agentNetKnowledgeUrl: 'https://knowledge.agentnet.firstam.com/auth/auto-signin',
    contentfulSpaceId: 'ux7wpvh2chlw',
    contentfulAccessToken: 'GBe_jCpBvSpdCYlidgF4lXVygv28wUA1P4ct2sQQU2o',
    contentfulIsPreview: false,
    enableAllFeatures: false,
    anteUrl: 'https://agency.myfirstam.com/',
  },
};

const environmentConfig = config[resolveEnv()];
