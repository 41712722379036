import { useAuth } from '@agentnet/auth';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { generateFileSummaryReport } from 'api/pdf/pdf-api';
import formatISO from 'date-fns/formatISO';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import _debounce from 'lodash/debounce';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import PDFIcon from 'ui-kit/icons/PDF';
import { dateTooltipValue, openDocument } from 'utilities/utilities';
import { getFileDetail, searchFiles } from '../../../../api/file/file-api';
import { Office } from '../../../../api/profile/types';
import useAsync from '../../../../hooks/useAsync';
import LoadingSpinner from '../../../../ui-kit/components/LoadingSpinner';
import { Notification } from '../../../../ui-kit/components/notification/Notification';
import NoResultFoundIcon from '../../../../ui-kit/icons/NoResultFound';
import { DocumentType, fileStatus, httpErrorCodes, newWindow, WindowFeatures } from '../../../constants';
import SearchField from '../search-form/SearchField';
import { FileResults, searchOptionsType } from '../types';
import './FilesList.scss';
import {
  FormatDate as CreateDateRenderer,
  FileNumber as FileNumberRenderer,
  getDateRange,
  StatusRenderer,
} from './filesListConfigs';
import './FilesList.scss';
import { KBLinks } from '../../../constants';
import FloatingFilterComponent from './FloatingFilterComponent';
import { DataTable } from '@fluentsms/agentnet-web-components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableStyles: {
    marginTop: '0px !important',
    height: 'calc(100vh - 250px) !important',
    width: '100% !important',
  },
  noResultsType: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface IGridFilterState {
  filters: { [key: string]: any };
  number: number;
}
const selectAllOption = 'All';

const FilesList: React.FC = () => {
  const { getAccessToken } = useAuth();
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const defaultSearchPayLoad = {
    firmId: userFirm?.firmId ?? null,
    pageSize: 500,
    searchText: '',
    status: fileStatus[0].value,
    openDateFrom: formatISO(new Date().setFullYear(new Date().getFullYear() - 10)),
    openDateTo: formatISO(new Date()),
  };
  const history = useHistory();
  const [searchPayload, setSearchPayload] = useState<searchOptionsType>(defaultSearchPayLoad);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = React.useState<FileResults>({ files: [], total: 0 });
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [offices, setOffices] = useState<Office[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string>('');

  const { addGlobalMsg } = useGlobalMessages();

  useEffect(() => {
    if (!userFirm?.firmId) {
      history.push('/err');
    }
  }, [userFirm]);

  // This is called by the useAsync execute
  const search = async (): Promise<{ result: FileResults; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await searchFiles(searchPayload, token);
  };
  const {
    execute,
    status,
    value: fileResults,
  } = useAsync<{ result: FileResults; errorCode?: string | number; message?: string }>(search, false);

  const getFilePdf = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return getFileDetail(selectedFileId ?? '', token);
  };

  const { execute: executeFilePdf, value: pdfValue, status: pdfStatus } = useAsync<any>(getFilePdf, false);

  useEffect(() => {
    if (pdfStatus === 'success') {
      if (pdfValue?.pdfData) openDocument(pdfValue.pdfData, DocumentType, newWindow, WindowFeatures);
    }
  }, [pdfStatus]);

  const filterOnSearch = useCallback(
    _debounce((searchText: string) => {
      setSearchPayload((prevPayload) => ({ ...prevPayload, searchText }));
    }, 250),
    [rows],
  );

  const resetGridInputFocus = () => {
    const focusElement = document.querySelectorAll('.ag-header-cell.ag-floating-filter.ag-focus-managed');
    focusElement.forEach((item) => {
      item.removeAttribute('tabindex');
    });
  };

  useEffect(() => {
    if (selectedFileId !== '') {
      executeFilePdf().then();
      setTimeout(() => setSelectedFileId(''), 3000);
    }
  }, [selectedFileId]);

  useEffect(() => {
    if (userFirm?.offices) {
      const sortedOffices: Office[] =
        userFirm?.offices.sort((a, b) => {
          const officeNameA = a.name ? a.name.toLowerCase().replace(/\s/g, '') : '';
          const officeNameB = b.name ? b.name.toLowerCase().replace(/\s/g, '') : '';
          if (officeNameA < officeNameB) return -1;
          if (officeNameA > officeNameB) return 1;
          return 0;
        }) ?? [];
      setOffices(sortedOffices);
    }
  }, [userFirm]);

  useEffect(() => {
    const hasOneCharValue = Object.entries(searchPayload)
      .filter(([key]) => {
        if (key === 'firmId' || key === 'state') return false;
        return true;
      })
      .some(([key, value]) => {
        if (value) return (value as string).length < 3;
        return false;
      });

    if (!hasOneCharValue) execute().then();
    try {
      if (gridRef.current) {
        gridRef.current?.api.hideOverlay();
      }
    } catch (error) {
      console.warn('AgGrid fail to hide Overlay');
    }
  }, [searchPayload]);

  useEffect(() => {
    if (fileResults?.result) {
      setDisplayError(false);
      setRows(fileResults?.result);

      if (!fileResults?.result.total) gridRef.current?.api.showNoRowsOverlay();
      setRowData(fileResults?.result);
    } else {
      if (fileResults?.errorCode && gridRef.current) gridRef.current?.api.hideOverlay();
      if (
        fileResults?.errorCode == httpErrorCodes.notFound ||
        fileResults?.errorCode == httpErrorCodes.badRequest ||
        fileResults?.errorCode == httpErrorCodes.forbidden ||
        fileResults?.errorCode == httpErrorCodes.internalServerError
      ) {
        setErrMsg(fileResults?.message ?? 'Error.  Failed to GET.');
        setDisplayError(true);
      }
    }
  }, [fileResults]);

  const gridRef = useRef<any>(null);
  const secondaryFilterOptions = useRef<{ [key: string]: any }>({ searchText: '', state: '' });
  const defaultGridFilter = {
    status: { filterType: 'text', type: 'Open' },
    transactionType: { filterType: 'text', type: selectAllOption },
  };
  const [gridFilterState, setGridFilterState] = useState<IGridFilterState>({
    filters: defaultGridFilter,
    number: 3,
  });

  const hideOverlay = useCallback(() => {
    if (gridRef.current) {
      const rowCount = gridRef.current?.api.getDisplayedRowCount();
      if (rowCount) {
        gridRef.current?.api.hideOverlay();
      }
    }
    resetGridInputFocus();
  }, []);

  const savedFilterModel = useCallback((event: FilterChangedEvent) => {
    const filterModel = gridRef.current?.api.getFilterModel() ?? {};
    const payload: searchOptionsType = {
      ...defaultSearchPayLoad,
      searchText: secondaryFilterOptions.current.searchText,
    };
    if (filterModel.status) {
      payload.status = filterModel.status.type;
    }
    if (filterModel.fileNumber) {
      payload.fileNumber = filterModel.fileNumber.filter;
    }
    if (filterModel.address) {
      payload.address = filterModel.address.filter;
    }
    if (filterModel.buyer) {
      payload.buyer = filterModel.buyer.filter;
    }
    if (filterModel.lender) {
      payload.lender = filterModel.lender.filter;
    }
    if (filterModel.seller) {
      payload.seller = filterModel.seller.filter;
    }
    if (filterModel.modifiedDate) {
      const dateRange = getDateRange(
        filterModel.modifiedDate.type,
        filterModel.modifiedDate.dateFrom,
        filterModel.modifiedDate.dateTo,
      );
      payload.modifiedDateFrom = dateRange.dateFromFormatISO;
      payload.modifiedDateTo = dateRange.dateToFormatISO;
      if (filterModel.modifiedDate.type !== 'inRange')
        filterModel.modifiedDate.dateTo = filterModel.modifiedDate.dateTo ?? filterModel.modifiedDate.dateFrom;
    }
    setSearchPayload(payload);
    setGridFilterState({ filters: filterModel, number: Object.keys(filterModel).length ?? 0 });
    hideOverlay();
  }, []);

  const applyFilterModel = () => {
    if (gridRef.current) {
      gridRef.current?.api.setFilterModel(gridFilterState.filters);
    }
    hideOverlay();
  };

  const getStartOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="no-rows">
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const CustomOverlayNoRows = () => {
    return (
      <div className="no-rows">
        <NoResultFoundIcon />
        <Typography variant="h3" className={classes.noResultsType}>
          No Results Found
        </Typography>
        <Typography variant="body2" className="adjust-your-search" color="textSecondary">
          Try adjusting your search or filter to find what you’re looking for
        </Typography>
      </div>
    );
  };

  const setRowData = (rows: FileResults) => {
    const rowData = rows?.files ?? [];
    if (gridRef.current) {
      gridRef.current.api.setGridOption(
        'rowData',
        rowData.map((data) => {
          data.summary = data.summary ? data.summary : '';
          data.openDate = data.openDate ? data.openDate : null;
          data.modifiedDate = data.modifiedDate ? data.modifiedDate : null;
          return data;
        }),
      );
    }
    applyFilterModel();
  };

  const downloadReportPdf = async (fileId: string, fileNumber: string) => {
    addSnackbarMessage({
      message: `Generating File Summary Report...`,
      type: 'success',
    });

    const token = await getAccessToken();
    await generateFileSummaryReport(fileId, fileNumber, token, addGlobalMsg);
  };

  resetGridInputFocus();

  const { addSnackbarMessage } = useSnackBars();

  const debouncedSizeColumnsToFit = useCallback(
    _debounce((api) => {
      api.sizeColumnsToFit();
    }, 100),
    [],
  );

  const onGridSizeChanged = useCallback((params: GridSizeChangedEvent) => {
    debouncedSizeColumnsToFit(params.api);
  }, []);

  const colDefs: ColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      tooltipField: 'status',
      cellRenderer: StatusRenderer,
      width: 120,
      minWidth: 120,
      filter: true,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" data-qa="FileListStatusIcon" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'fileNumber',
      floatingFilterComponent: 'customFloatingFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      width: 220,
      minWidth: 220,
      headerName: 'File Number',
      tooltipField: 'fileNumber',
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
        accountNumber: row.data.accountNumber,
      }),
      cellRenderer: 'fileNumberRenderer',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'address',
      headerName: 'Property Address',
      tooltipField: 'address',
      width: 300,
      minWidth: 300,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      tooltipField: 'buyer',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      filterParams: {
        qaAttribute: 'FileListBuyer',
        hideFilterButton: true,
      },
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'office',
      headerName: 'Office',
      tooltipField: 'office',
      minWidth: 370,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'transactionType',
      headerName: 'Transaction Type',
      tooltipField: 'transactionType',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: { success: (arg0: (string | null)[]) => void }) => {
          params.success([
            'Sale/Cash',
            'Sale w/Mortgage',
            'Refinance',
            'Equity Loan',
            'Sale w/Construction Loan',
            'Other',
            null,
          ]);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'openDate',
      headerName: 'Created Date',
      cellRenderer: 'createDateRenderer',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
      tooltipValueGetter: dateTooltipValue,
      filter: 'agDateColumnFilter',
      filterParams: {
        type: 'date',
        qaAttribute: 'FileListCreatedDate',
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const cellDate = getStartOfDay(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
            return -1;
          }
          if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
            return 1;
          }
          return 0;
        },
        browserDatePicker: true,
        dateFormat: 'yyyy-mm-dd',
        inRangeInclusive: true,
      },
      sort: 'desc',
    },
    {
      field: 'pdf',
      headerName: '',
      cellClass: 'center-align-column jacket-pdf-cell',
      cellRenderer: 'summaryRenderer',
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
      }),
      width: 40,
      minWidth: 40,
      filter: false,
      floatingFilter: false,
      pinned: 'right',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
  ];

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api.setFilterModel?.({
      status: {
        values: ['Open'],
        filterType: 'set',
      },
      transactionType: {
        values: ['Sale/Cash', 'Sale w/Mortgage', 'Refinance', 'Equity Loan', 'Sale w/Construction Loan', 'Other', ''],
        filterType: 'set',
      },
    });
  };

  return (
    <ContentContainer fullWidth mTop={'0'}>
      <div>
        {displayError && (
          <Notification
            msg={errMsg}
            severity="error"
            className="files-container--alert"
            action={
              <Button color="inherit" variant="text" endIcon={<CloseIcon />} onClick={() => setDisplayError(false)}>
                ACTION
              </Button>
            }
          />
        )}
        <PageHeader
          title="All Files"
          contentRight={
            <div className={classes.searchWrap}>
              <SearchField
                onChange={(e) => {
                  setSearchText(e.target.value);
                  secondaryFilterOptions.current.searchText = e.target.value;
                  filterOnSearch(searchText);
                }}
                value={searchText}
                qaAttribute="FileListMyFile"
                onSearch={filterOnSearch}
                placeholder="Filter any column..."
                compact
              />
            </div>
          }
          subtitle="This page shows all your files. You can sort and filter the grid by any column."
          divider={false}
          menuItems={[{ label: 'Knowledge Base', link: KBLinks.filesList }]}
        />
        <Grid className={classes.paper}>
          {status === 'error' ? (
            <h3>Error in retrieving files</h3>
          ) : (
            <div className="grid-summary">
              <div className="files-list-grid">
                <div className={`ag-theme-alpine table-grid ${classes.tableStyles}`}>
                  <DataTable
                    loading={fileResults?.result === undefined}
                    ref={gridRef}
                    columnDefs={colDefs}
                    onGridSizeChanged={onGridSizeChanged}
                    components={{
                      statusCellRenderer: StatusRenderer,
                      fileNumberRenderer: FileNumberRenderer,
                      createDateRenderer: CreateDateRenderer,
                      summaryRenderer: ({ id, fileNumber }: any) => (
                        <PDFIcon
                          fontSize="small"
                          onClick={() => {
                            downloadReportPdf(id, fileNumber);
                          }}
                        />
                      ),
                      customOverlayLoading: CustomOverlayLoading,
                      customOverlayNoRows: CustomOverlayNoRows,
                      customFloatingFilter: FloatingFilterComponent,
                    }}
                    loadingOverlayComponent={'customOverlayLoading'}
                    noRowsOverlayComponent={'customOverlayNoRows'}
                    defaultColDef={{
                      cellClass: 'left-align-column',
                      headerClass: 'left-align-header',
                      sortable: true,
                      filter: 'agTextColumnFilter',
                      floatingFilter: true,
                      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                      suppressMenu: true,
                      width: 180,
                      minWidth: 180,
                      resizable: true,
                      suppressSizeToFit: false,
                      flex: 1,
                    }}
                    onFilterChanged={savedFilterModel}
                    onFirstDataRendered={onFirstDataRendered}
                  />
                </div>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </ContentContainer>
  );
};

export default FilesList;
