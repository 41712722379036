import { MyLenderDropdown, PartyTypeValue } from 'api/file/constants';
import { nameNotEntered, namesNotEntered, objectContainsOnlyEmptyString } from 'utilities/object-utilities';
import { IGetBuyerSellerResponse } from '../interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';

/* Handle Dynamic Button Disable or Enable  */
export const isSelectionMarriedCouple = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean =>
  party.partyType === PartyTypeValue.married;

export const isSelectionIndividual = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return party.partyType === PartyTypeValue.individual;
};
export const isSelectedEntityOrTrust = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return party.partyType === PartyTypeValue.entity || party.partyType === PartyTypeValue.trust;
};

export const isSelectedLender = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return party.lenderTypeOptions === MyLenderDropdown.noMatchingLender;
};

/* Check if the name input fields are completed  */
export const isSelectedHasName = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  if (
    isSelectionMarriedCouple(party) &&
    namesNotEntered([party.couple?.spouse1?.firstName, party.couple?.spouse1?.lastName]) &&
    namesNotEntered([party.couple?.spouse2?.firstName, party.couple?.spouse2?.lastName])
  )
    return true;

  if (isSelectionIndividual(party) && namesNotEntered([party.individual?.firstName, party.individual?.lastName]))
    return true;
  return false;
};

export const isSelectedEntityHasName = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return isSelectedEntityOrTrust(party) && nameNotEntered(party?.entity?.name || party?.trustEstate?.name);
};

export const isSelectedLenderHasName = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return isSelectedLender(party) && nameNotEntered(party?.name);
};

export const isDefaultSetting = (party: IGetBuyerSellerResponse & IGetLenderResponse): boolean => {
  return (
    !!objectContainsOnlyEmptyString({ ...party }) ||
    (party.individual === undefined &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      party.individual.maritalStatus === undefined &&
      !!party.entity?.name &&
      party.entity?.name.length < 1)
  );
};
