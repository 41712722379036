import { useAuth } from '@agentnet/auth';
import { CellLink, CellStatus, Content, DashboardMetricCard, DataTable } from '@fluentsms/agentnet-web-components';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { FilterModel, GridApi, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import { getServiceOrders, GetServiceOrdersResult } from 'api/dashboards/service-orders';
import { KBLinks } from 'features/constants';
import { filterParams, formatDate } from 'features/Remittance/reportsListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { dateTooltipValue } from 'utilities/utilities';
import NoResultFoundIcon from 'ui-kit/icons/NoResultFound';
import moment from 'moment';
import { HashUtils } from '@agentnet/utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        minHeight: 220,
      },
    },
    heading: {
      margin: 0,
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 220,
      justifyContent: 'center',
    },
    subHeading: {
      textTransform: 'none',
    },
  }),
);

export function ServiceOrders() {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { userFirm }: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const gridApiRef = useRef<GridApi | null>(null);

  const firmId = userFirm?.firmId ?? '';

  const getServiceOrdersData = async (): Promise<GetServiceOrdersResult[]> => {
    const token = await getAccessToken();
    const response = await getServiceOrders(
      {
        firmId: +firmId,
      },
      token,
    );

    return response;
  };

  const { execute: serviceOrdersExecute, value: serviceOrdersResults } = useAsync<GetServiceOrdersResult[]>(
    getServiceOrdersData,
    false,
  );

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const onTileFilterClick = (status: string): void => {
    if (gridApiRef.current) {
      const filterModel: FilterModel = gridApiRef.current.getFilterModel();
      const isStatusFilterActive = !!filterModel['Status'];

      if (isStatusFilterActive && currentStatus === status) {
        gridApiRef.current.setFilterModel(null);
        setCurrentStatus(null);
        return;
      }

      gridApiRef.current.setFilterModel({
        Status: {
          filterType: 'set',
          values: [status],
        },
      });
      gridApiRef.current.onFilterChanged();
      setCurrentStatus(status);
    }
  };

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const CustomOverlayNoRows = () => {
    return (
      <div className={classes.noRows}>
        <NoResultFoundIcon />
        <Typography variant="h3">No Results Found</Typography>
        <Typography variant="body2" className="adjust-your-search" color="textSecondary">
          Try adjusting your search or filter to find what you’re looking for
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (userFirm?.firmId) {
      serviceOrdersExecute();
    }
  }, []);

  if (!serviceOrdersResults) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  const ordersMTDComplete: GetServiceOrdersResult[] = [];
  const ordersYTDComplete: GetServiceOrdersResult[] = [];
  const ordersSearching: GetServiceOrdersResult[] = [];
  const ordersExamining: GetServiceOrdersResult[] = [];
  const ordersQualityReview: GetServiceOrdersResult[] = [];
  const ordersActionRequired: GetServiceOrdersResult[] = [];
  const nonCompleteOrders: GetServiceOrdersResult[] = [];

  serviceOrdersResults.forEach((result: GetServiceOrdersResult) => {
    const etaMoment: moment.Moment = moment(result.ETA);
    const isETAInCurrentMonth: boolean = etaMoment.isSame(moment(), 'month');

    if (isETAInCurrentMonth) {
      ordersMTDComplete.push(result);
    }

    switch (result.Status) {
      case 'OrderComplete':
        ordersYTDComplete.push(result);
        break;
      case 'Searching':
        ordersSearching.push(result);
        break;
      case 'Examining':
        ordersExamining.push(result);
        break;
      case 'Quality Review':
        ordersQualityReview.push(result);
        break;
      case 'Action Required':
        ordersActionRequired.push(result);
        break;
    }

    if (result.Status !== 'OrderComplete') {
      nonCompleteOrders.push(result);
    }
  });

  const agGridConfig: AgGridReactProps = {
    rowData: nonCompleteOrders ?? [],
    columnDefs: [
      {
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.value} />;
        },
        field: 'Status',
        filter: 'agSetColumnFilter',
        tooltipField: 'Status',
        suppressHeaderFilterButton: true,
        sortable: true,
      },
      {
        headerName: 'File Number',
        field: 'FileNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'FileNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Service Order No.',
        field: 'OrderNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'OrderNumber',
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${HashUtils.encode(row.data.FileId)}/service-orders`,
        }),
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Office',
        field: 'OfficeName',
        filter: 'agTextColumnFilter',
        tooltipField: 'OfficeName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 256,
        minWidth: 256,
      },
      {
        headerName: 'Property State',
        field: 'PropertyState',
        filter: 'agTextColumnFilter',
        tooltipField: 'PropertyState',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Date Submitted',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'SubmittedDate',
        cellRenderer: (params: any) => {
          return params?.data?.SubmittedDate?.length > 0 ? params?.data?.SubmittedDate.substring(0, 10) : '';
        },
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'ETA',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'ETA',
        cellRenderer: ({ value }: any) => formatDate(value),
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Transaction Type',
        field: 'TransactionType',
        filter: 'agSetColumnFilter',
        tooltipField: 'TransactionType',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Buyer',
        field: 'Buyer',
        filter: 'agTextColumnFilter',
        tooltipField: 'Buyer',
        suppressHeaderFilterButton: true,
        width: 256,
        minWidth: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Product Name',
        field: 'ProductName',
        filter: 'agTextColumnFilter',
        tooltipField: 'ProductName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Business Segment',
        field: 'BusinessSegment',
        filter: 'agSetColumnFilter',
        tooltipField: 'BusinessSegment',
        suppressHeaderFilterButton: true,
      },
      // TODO: Will be added with a follow up PR for messaging service
      // {
      //   field: '',
      //   floatingFilter: false,
      //   cellRenderer: CellMessage,
      //   onCellClicked: (e: CellClickedEvent) => {
      //     console.log(e.data);
      //   },
      //   width: 40,
      //   minWidth: 40,
      //   tooltipValueGetter: () => 'Open Rates and Fees',
      //   pinned: 'right',
      //   suppressHeaderFilterButton: true,
      // },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      suppressRowClickSelection: true,
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    components: {
      customOverlayNoRows: CustomOverlayNoRows,
      customOverlayLoading: CustomOverlayLoading,
    },
    loadingOverlayComponent: 'customOverlayLoading',
    noRowsOverlayComponent: 'customOverlayNoRows',
    alwaysMultiSort: true,
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
  };

  return (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader
              className={classes.heading}
              divider={false}
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: KBLinks.jacketsCreate,
                },
              ]}
              title="Service Orders"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard heading="MTD - Orders Completed" value={`${ordersMTDComplete.length}`} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard heading="YTD - Orders Completed" value={`${ordersYTDComplete.length}`} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DashboardMetricCard heading="Orders in Process" value={`${nonCompleteOrders.length}`} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subHeading} variant="h3">
              Orders in Process
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <DashboardMetricCard
              active={currentStatus === 'Searching'}
              heading="Searching"
              status="Searching"
              value={`${ordersSearching.length}`}
              onClick={() => onTileFilterClick('Searching')}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DashboardMetricCard
              active={currentStatus === 'Examining'}
              heading="Examining"
              status="Examining"
              value={`${ordersExamining.length}`}
              onClick={() => onTileFilterClick('Examining')}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DashboardMetricCard
              active={currentStatus === 'Quality Review'}
              heading="Quality Review"
              status="Quality Review"
              value={`${ordersQualityReview.length}`}
              onClick={() => onTileFilterClick('Quality Review')}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DashboardMetricCard
              active={currentStatus === 'Action Required'}
              heading="Action Required"
              status="Action Required"
              value={`${ordersActionRequired.length}`}
              onClick={() => onTileFilterClick('Action Required')}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable {...agGridConfig} />
            <Typography variant="body2">
              Showing {nonCompleteOrders.length} of {serviceOrdersResults.length} orders
            </Typography>
          </Grid>
        </Grid>
      </Content>
    </ContentContainer>
  );
}
