import React from 'react';
import clsx from 'clsx';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AgentNetCheckBox, AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import { JacketEndorsement, JacketEndorsementField } from './types';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface IncorporatedEndorsementsProps {
  index?: number;
  endorsements?: Array<any>;
  onChangeIncorporatedEndorsement?: (
    jacketId: string | null,
    endoId: string | null,
    starsNumber: string | null,
    type: string | null,
    value: string | null,
    fieldName: string | null,
  ) => void;
  contentDisabled?: boolean;
  radioOptions?: Array<RadioProps> | null;
  selectedRadioOption?: RadioProps | null;
  isFromJacket?: boolean;
  showStatCode?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    componentTitle: {
      width: '100%',
    },
    statCode: {
      alignItems: 'center',
      display: 'flex',
    },
    statCodeDropdown: {
      minWidth: '132px',
      width: '100%',
    },
    endorsements: {
      width: '100%',
      margin: '8px 16px',
    },
    reportEndorsements: {
      marginLeft: '5px',
    },
    endorsementsHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: pxToRem(14),
      padding: pxToRem(12),
    },
    leftSpace: {
      paddingLeft: pxToRem(45),
    },
    popper: {
      backgroundColor: '#FFF',
      border: '1px solid #c4c4c4',
      borderRadius: '6px',
      zIndex: 10,
    },
    popperContent: {
      width: 400,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    },
    popperOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperPaper: {
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    popperDisablePortal: {
      position: 'relative',
    },
    inputBase: {
      padding: 16,
      width: '100%',
      borderBottom: '1px solid #c4c4c4',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #C4C4C4',
        fontSize: 14,
        '&:focus': {
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          borderColor: '#c4c4c4',
        },
      },
    },
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: pxToRem(12),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    descriptionPadding: {
      paddingLeft: pxToRem(30),
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    incorporatedHeader: {
      marginRight: theme.spacing(1),
    },
    incorporatedCheckbox: {
      marginRight: theme.spacing(1),
    },
    incorporatedCell: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    incorporatedCellDescription: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    incorporatedMultiline: {
      '& .MuiOutlinedInput-multiline': {
        padding: '8px',
      },
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const IncorporatedEndorsements = ({
  index,
  onChangeIncorporatedEndorsement,
  endorsements,
  contentDisabled,
  radioOptions,
  selectedRadioOption,
  isFromJacket = true,
  showStatCode = false,
}: IncorporatedEndorsementsProps) => {
  const classes = useStyles();

  const renderEndorsementInputField = (x: JacketEndorsementField, e: JacketEndorsement) => {
    return (
      <>
        {x.type && x.type === 'text' ? (
          <>
            <AgentNetTextInput
              variant="outlined"
              value={x.value}
              onChange={(el) => {
                onChangeIncorporatedEndorsement &&
                  onChangeIncorporatedEndorsement(
                    e.randomId,
                    e.endorsementId,
                    e.endorsementFormNumber,
                    'text',
                    el.target.value,
                    x.name,
                  );
              }}
              fullWidth
              disabled={contentDisabled}
            />
            {x.name === 'DELPAR42' && radioOptions && (
              <RadioGroup
                options={radioOptions}
                onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
                  const fieldName = el.target.value;
                  if (onChangeIncorporatedEndorsement) {
                    if (fieldName === 'chkProcedRuleAdded') {
                      onChangeIncorporatedEndorsement(
                        e.randomId,
                        e.endorsementId,
                        e.endorsementFormNumber,
                        'radio',
                        'true',
                        fieldName,
                      );
                      onChangeIncorporatedEndorsement(
                        e.randomId,
                        e.endorsementId,
                        e.endorsementFormNumber,
                        'radio',
                        'false',
                        'chkNoProcedRuleAdded',
                      );
                    } else {
                      onChangeIncorporatedEndorsement(
                        e.randomId,
                        e.endorsementId,
                        e.endorsementFormNumber,
                        'radio',
                        'true',
                        fieldName,
                      );
                      onChangeIncorporatedEndorsement(
                        e.randomId,
                        e.endorsementId,
                        e.endorsementFormNumber,
                        'radio',
                        'false',
                        'chkProcedRuleAdded',
                      );
                    }
                  }
                }}
                selected={selectedRadioOption}
                compact
                disabled={contentDisabled}
              />
            )}
          </>
        ) : (
          ''
        )}
        {x.type && x.type === 'textarea' ? (
          <>
            <AgentNetTextInput
              multiline
              variant="outlined"
              value={x.value}
              onChange={(el) => {
                onChangeIncorporatedEndorsement &&
                  onChangeIncorporatedEndorsement(
                    e.randomId,
                    e.endorsementId,
                    e.endorsementFormNumber,
                    'text',
                    el.target.value,
                    x.name,
                  );
              }}
              fullWidth
              disabled={contentDisabled}
            />
          </>
        ) : (
          ''
        )}
        {x.type && (x.type === 'Label' || x.type === 'label') ? (
          <Typography variant="body2" className={classes.mb1}>
            {x.label}
          </Typography>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      <Grid item sm={12}>
        <AgentNetDivider
          typoVariant="h3"
          title={'Incorporated Endorsements'}
          disablePadding
          subtitle={
            isFromJacket
              ? ''
              : 'This is a summary of your chosen incorporated endorsements. To edit your selections, please update your policy within jackets.'
          }
        />
      </Grid>
      {endorsements && endorsements.length > 0 && (
        <div
          className={clsx(classes.endorsements, 'endorsementsTableWrapper', {
            [classes.reportEndorsements]: !isFromJacket,
          })}
        >
          <Grid container className={clsx(classes.endorsementsHeader, { [classes.leftSpace]: !isFromJacket })}>
            <Grid item sm={3}>
              Endorsement
            </Grid>
            <Grid item sm={showStatCode ? 6 : 9}>
              Description
            </Grid>
            {showStatCode && (
              <Grid item sm={3}>
                Stat Code
              </Grid>
            )}
          </Grid>
          <Grid container>
            {endorsements.map((e: any, i) => (
              <>
                {isFromJacket ? (
                  <>
                    <Grid container key={i} className={classes.tableRow}>
                      <Grid item sm={3} className={clsx(classes.tableCell, { [classes.incorporatedCell]: true })}>
                        <AgentNetCheckBox
                          value={e.isChecked}
                          label={e.endorsementFormNumber}
                          checkHandler={(el: any) =>
                            onChangeIncorporatedEndorsement &&
                            onChangeIncorporatedEndorsement(
                              e.randomID,
                              e.endorsementId,
                              e.endorsementFormNumber,
                              'checkBox',
                              el,
                              e.endorsementName,
                            )
                          }
                          disabled={
                            isFromJacket ? (e.endorsementType === 'Required' ? true : false || contentDisabled) : true
                          }
                          className={classes.incorporatedCheckbox}
                          Data-QA={'Jacket' + [index] + 'IncorporatedEndo' + [i + 1]}
                        />
                        <Typography variant="body2" className={clsx({ [classes.incorporatedHeader]: true })}>
                          {e.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={9}
                        className={clsx(classes.tableCell, {
                          [classes.incorporatedCellDescription]: true,
                          [classes.descriptionPadding]: !isFromJacket,
                        })}
                      >
                        <Typography variant="body2" className={clsx({ [classes.incorporatedHeader]: true })}>
                          {e.endorsementName}
                        </Typography>
                        {isFromJacket &&
                          e.endorsementInputFields.map((x: any) => <>{renderEndorsementInputField(x, e)}</>)}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {e.endorsementFormNumber && (
                      <Grid container key={i} className={classes.tableRow}>
                        <Grid item sm={3} className={clsx(classes.tableCell, { [classes.incorporatedCell]: true })}>
                          <AgentNetCheckBox
                            value={e.isChecked}
                            label={e.endorsementFormNumber}
                            checkHandler={(el: any) =>
                              onChangeIncorporatedEndorsement &&
                              onChangeIncorporatedEndorsement(
                                e.randomID,
                                e.endorsementId,
                                e.endorsementFormNumber,
                                'checkBox',
                                el,
                                e.endorsementName,
                              )
                            }
                            disabled={
                              isFromJacket ? (e.endorsementType === 'Required' ? true : false || contentDisabled) : true
                            }
                            className={classes.incorporatedCheckbox}
                            Data-QA={'Jacket' + [index] + 'IncorporatedEndo' + [i + 1]}
                          />
                          <Typography variant="body2" className={clsx({ [classes.incorporatedHeader]: true })}>
                            {e.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={showStatCode ? 6 : 8}
                          className={clsx(classes.tableCell, {
                            [classes.incorporatedCellDescription]: true,
                            [classes.descriptionPadding]: !isFromJacket,
                          })}
                        >
                          <Typography variant="body2" className={clsx({ [classes.incorporatedHeader]: true })}>
                            {e.endorsementName}
                          </Typography>
                          {isFromJacket &&
                            e.endorsementInputFields.map((x: any) => <>{renderEndorsementInputField(x, e)}</>)}
                        </Grid>

                        {showStatCode && (
                          <Grid item sm={3} className={classes.statCode}>
                            {e?.fields?.map((field: any, j: number) => (
                              <div key={`${i}_${j}`} className={classes.statCodeDropdown}>
                                <AgentNetDropdownSelector
                                  name={field.name}
                                  options={field?.options?.map((x: any, i: any) => {
                                    return { name: x.text, value: x.value };
                                  })}
                                  value={field.value}
                                  menuOption={(val: string) => {
                                    onChangeIncorporatedEndorsement?.(
                                      e.randomId,
                                      e.endorsementId,
                                      e.endorsementFormNumber,
                                      'update-statcode',
                                      val,
                                      e.endorsementName,
                                    );
                                  }}
                                  dropdowntype="outlined"
                                  disabled={field.isDisabled}
                                  required={field.isRequired}
                                />
                              </div>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};

export default IncorporatedEndorsements;
