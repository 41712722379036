import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, DialogTitle, IconButton, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ANFooterLogo } from 'ui-kit/icons/Logo';
import { Close, MessageOutlined, PhoneOutlined } from '@material-ui/icons';
import Logo from 'ui-kit/icons/AgentNetLogo';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface AboutAppDialogProps {
  onClose: (value: any) => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: pxToRem(64, 0, 0),
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    fontSize: pxToRem(14),
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  dialogWrap: {
    maxWidth: 400,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    padding: pxToRem(24),
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& svg': {
      width: '231px',
      height: 'auto',
    },
  },
  menuIconBtn: {
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: theme.palette.primary.dark,
    },
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

const AboutAppDialog = ({ onClose, open }: AboutAppDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="about-app"
      open={open}
      maxWidth={'xs'}
      PaperProps={{ className: classes.dialogWrap }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" color="textPrimary">
            About App
          </Typography>
          <IconButton className={classes.dialogClose} onClick={onClose}>
            <Close fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <div className={clsx(classes.logoWrap, classes.mb2)}>
          <Logo color={'#013A6F'} />
          <Typography variant="h6" color="textSecondary" className={classes.mb1}>
            Version 4.0.0.0
          </Typography>
        </div>

        <ListItem disableGutters>
          <MessageOutlined fontSize="large" color="primary" />
          <Typography variant="body1" className={classes.menuIconBtn}>
            <a href="mailto:AgencySupport@firstam.com" className={classes.link}>
              AgencySupport@firstam.com
            </a>
          </Typography>
        </ListItem>

        <ListItem disableGutters className={classes.mb3}>
          <PhoneOutlined fontSize="large" color="primary" />
          <Typography variant="body1" className={classes.menuIconBtn}>
            <a href="tel:+18667013361" className={classes.link}>
              1-866-701-3361
            </a>
          </Typography>
        </ListItem>

        <div className={classes.mb2}>
          <ANFooterLogo />
        </div>

        <div className={clsx(classes.footerTos, classes.mb3)}>
          <a href="https://www.firstam.com/terms-of-use/" target="_blank" rel="noreferrer" className={classes.link}>
            Terms of Use
          </a>
          <span>|</span>
          <a href="https://www.firstam.com/privacy-policy/" target="_blank" rel="noreferrer" className={classes.link}>
            Privacy Policy
          </a>
        </div>
        <Typography variant="h6" color="textSecondary">
          © 2023 First American Financial Corporation and/or its affiliates. All rights reserved.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AboutAppDialog;
