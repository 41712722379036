import React from 'react';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { keyPress } from 'utilities/utilities';
import ErrorSection from 'features/files/file-create/ErrorSection';
import { FieldValidationError } from 'utilities/validation/validation';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface SearchFormProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSearch: (searchText: string) => void;
  onClose?: () => void;
  value: string;
  qaAttribute?: string;
  name?: string;
  errs?: FieldValidationError[];
  executeOnSearchOnChange?: boolean;
  enableNumCode?: boolean;
  displayError?: boolean;
  showCLoseIcon?: boolean;
  compact?: boolean;
  placeholder?: string;
  width?: number | string;
}

const SearchForm: React.FC<SearchFormProps> = ({
  value,
  onSearch,
  onClose,
  onChange,
  qaAttribute,
  errs,
  name = '',
  executeOnSearchOnChange = true,
  enableNumCode = false,
  displayError = false,
  showCLoseIcon = false,
  compact,
  placeholder,
  width,
}: SearchFormProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formElements: {
        display: 'flex',
        flexDirection: 'row',
      },
      fdCol: {
        flexDirection: 'column',
      },
      searchInput: {
        color: theme.palette.text.primary,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        width: width ? width : 376,
        border: '1px solid #c4c4c4',
        padding: pxToRem(0, 7),
      },
      extendedWidth: {
        width: width ? width : 464,
      },
      searchIconWrap: {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        marginRight: theme.spacing(1),
      },
      searchText: {
        width: '100%',
        '& input': {
          lineHeight: 'normal',
          padding: `${theme.spacing(1)}px 0`,
          '&:placeholder': {
            color: theme.palette.text.disabled,
          },
        },
      },
      searchTextMini: {
        '& input': {
          padding: '6px 0',
        },
      },
      closeIcon: {
        position: 'relative',
        cursor: 'pointer',
      },
    }),
  );
  const classes = useStyles();
  return (
    <form noValidate autoComplete="off" className={classes.formElements}>
      <div className={clsx(classes.fdCol)}>
        <div className={clsx(classes.searchInput, { [classes.extendedWidth]: showCLoseIcon })}>
          <div className={classes.searchIconWrap}>
            <SearchIcon
              className="search-icon"
              onClick={() => {
                onSearch(value);
              }}
              fontSize="small"
              color="secondary"
            />
          </div>
          <InputBase
            className={clsx(classes.searchText, { [classes.searchTextMini]: compact })}
            value={value}
            inputProps={{ 'aria-label': 'Search File(s)', placeholder: placeholder }}
            onChange={(e) => {
              onChange(e);
              if (executeOnSearchOnChange) onSearch(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              keyPress(e, () => onSearch(value), enableNumCode)
            }
            {...(qaAttribute ? { 'data-qa': qaAttribute } : {})}
          />
          {showCLoseIcon ? (
            <CloseIcon className={classes.closeIcon} fontSize="small" onClick={onClose} color="secondary" />
          ) : null}
        </div>
        {displayError ? <ErrorSection errs={errs} field={name} /> : null}
      </div>
    </form>
  );
};

export default SearchForm;
