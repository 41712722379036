import React from 'react';
import { useAuth } from '@agentnet/auth';

const ForgotUserID = (): JSX.Element => {
  const idaaSAuth = useAuth();
  idaaSAuth.forgotUsername();
  return <div />;
};

export default ForgotUserID;
