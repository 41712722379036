import { Risk } from 'api/file/interfaces/get-file-order-insights';
import React from 'react';
import OrderInsightsRiskIcon from './OrderInsightsRiskIcon';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { pxToRem } from '@fluentsms/agentnet-web-components';

type OrderInsightRisksProps = {
  alerts: Risk[];
  warnings: Risk[];
  unknowns: Risk[];
  errors: Risk[];
  isPdf?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    danger: {
      background: '#FDECEA',
      color: '#B3150A',
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      fontSize: '9pt',
      marginTop: theme.spacing(1),
      '& a': {
        color: '#B3150A',
        '&:hover': {
          opacity: '0.8',
        },
      },
    },
    boldTitle: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& p': {
        fontWeight: 600,
        '&:nth-child(1)': {
          fontWeight: 400,
        },
      },
    },
    mt0: {
      margin: '0',
    },
    warning: {
      background: '#FDF4E7',
      color: '#C77700',
      gap: theme.spacing(1),
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '9pt',
      padding: theme.spacing(1),
      margin: theme.spacing(1, 0),
      '& a': {
        color: '#663C00',
        '&:hover': {
          opacity: '0.8',
        },
      },
    },
    unknown: {
      background: '#F5F5F5',
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      padding: theme.spacing(1),
      fontSize: '9pt',
      color: '#666',
      margin: theme.spacing(1, 0),
      '& a': {
        color: theme.palette.text.secondary,
        '&:hover': {
          opacity: '0.8',
        },
      },
    },
    grey: {
      color: theme.palette.text.secondary,
      fontSize: '9pt',
      margin: '0',
    },
    f14: {
      fontSize: pxToRem(12),
    },
  }),
);

const OrderInsightRisks: React.FC<OrderInsightRisksProps> = ({ alerts, warnings, unknowns, errors, isPdf }) => {
  const classes = useStyles();

  return (
    <>
      {alerts.length > 0 && (
        <>
          {alerts.map((risk: Risk, index: number) => (
            <div className={classes.danger} key={index}>
              <OrderInsightsRiskIcon isPdf={isPdf} riskSeverity={alerts[0].severityName} />
              <span className={classes.boldTitle}>
                <p className={classes.mt0} dangerouslySetInnerHTML={{ __html: risk.description }} />
                <p className={classes.mt0}>{risk.title}</p>
              </span>
            </div>
          ))}
        </>
      )}
      {warnings.length > 0 && (
        <>
          {warnings.map((risk: Risk, index: number) => (
            <div className={clsx(classes.warning, isPdf ? '' : classes.f14)} key={index}>
              <OrderInsightsRiskIcon isPdf={isPdf} riskSeverity={warnings[0].severityName} />
              <span>
                <p className={classes.mt0}>{risk.title}</p>
                <p className={classes.mt0} dangerouslySetInnerHTML={{ __html: risk.description }} />
              </span>
            </div>
          ))}
        </>
      )}
      {unknowns.length > 0 && (
        <>
          {unknowns.map((risk: Risk, index: number) => (
            <div className={classes.unknown} key={index}>
              <OrderInsightsRiskIcon isPdf={isPdf} riskSeverity={unknowns[0].severityName} />
              <p
                className={clsx(classes.grey, isPdf ? '' : classes.f14)}
                dangerouslySetInnerHTML={{ __html: risk.description }}
              />
            </div>
          ))}
        </>
      )}
      {errors.length > 0 && (
        <>
          {errors.map((risk: Risk, index: number) => (
            <div className={classes.unknown} key={index}>
              <OrderInsightsRiskIcon isPdf={isPdf} riskSeverity={errors[0].severityName} />
              <span className={classes.boldTitle}>
                <p className={classes.mt0} dangerouslySetInnerHTML={{ __html: risk.description }} />
                <p className={classes.mt0}>{risk.title}</p>
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default OrderInsightRisks;
