import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { Firm } from 'api/profile/types';
import { useSearchParams } from 'features/help/hooks/useSearchParams';
import { HashUtils } from '@agentnet/utils';

const FirmRedirect = () => {
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm, profile, userFirm } = profileCtx;
  const redirectUrl = useSearchParams<{ redirect_url?: string }>();

  useEffect(() => {
    if (profile) {
      const firms = profile?.firms;
      const firmIdEncoded = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
      const firmId = HashUtils.decode(firmIdEncoded);

      if (userFirm?.firmId === firmId.toString()) {
        history.push(redirectUrl.redirect_url || '/files');
      } else {
        const matchedFirmData = firms?.find((firm: Firm) => firm.firmId === firmId.toString());
        if (matchedFirmData) {
          window.localStorage.setItem('userFirm', JSON.stringify(matchedFirmData));
          setUserFirm && setUserFirm(matchedFirmData);
          history.push(redirectUrl.redirect_url || '/files');
        } else {
          history.push('/files');
        }
      }
    }
  }, [profile]);

  return null;
};

export default FirmRedirect;
