import React, { useState } from 'react';
import clsx from 'clsx';
import { ButtonBase, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { KeyboardDatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { pxToRem } from '@fluentsms/agentnet-web-components';

export type DateTileProps = Omit<KeyboardDatePickerProps, 'onChange'> & {
  title?: string;
  onChange?: (ev: Date | null) => void;
};

export type DateTileState = {
  month: string | null;
  year: string | null;
  day: string | null;
};

const DEFAULT_VARIANT_TYPE = 'dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tile: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(0),
      height: '120px',
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      height: '32px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary[100],
      fontSize: pxToRem(10),
      fontStyle: 'normal',
      textTransform: 'uppercase',
    },
    dateDisplay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '88px',
      fontSize: pxToRem(24),
      fontWeight: 200,
    },
    date: {},
    day: {
      fontWeight: 'bold',
    },
    year: {
      fontSize: pxToRem(12),
    },
    expanded: {
      height: '120px',
    },
  }),
);

const DateTile = ({ className, onChange, ref, title, value, ...props }: DateTileProps): JSX.Element => {
  const classes = useStyles();
  const defaultDate = new Date(value as string | number | Date);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTileState>({
    month: format(defaultDate, 'MMM'),
    year: format(defaultDate, 'yyyy'),
    day: format(defaultDate, 'd'),
  });

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const handleChange = (date: Date | null) => {
    setSelectedDate({
      month: date ? format(date, 'MMM') : '',
      day: date ? format(date, 'd') : '',
      year: date ? format(date, 'yyyy') : '',
    });

    if (onChange) onChange(date);
  };

  const handleAccept = (date: Date | null) => {
    handleChange(date);
    toggleCalendar();
  };

  return (
    <>
      <KeyboardDatePicker
        {...props}
        inputRef={ref}
        value={selectedDate}
        open={calendarOpen}
        onClose={toggleCalendar}
        onChange={handleChange}
        onAccept={handleAccept}
        TextFieldComponent={() => null}
        variant={DEFAULT_VARIANT_TYPE}
      />
      <ButtonBase className={clsx(classes.tile, className)} onClick={toggleCalendar}>
        {title ? <Typography className={classes.title}>{title}</Typography> : null}
        <div className={clsx(classes.dateDisplay, title ? null : classes.expanded)}>
          <Typography className={classes.date}>
            {selectedDate.month}{' '}
            <Typography display="inline" className={classes.day}>
              {selectedDate.day}
            </Typography>
          </Typography>
          <Typography className={classes.year}>{selectedDate.year}</Typography>
        </div>
      </ButtonBase>
    </>
  );
};

export default DateTile;
