import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { SearchBar } from './SearchBar';
import clsx from 'clsx';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { HELP_SEARCH_ROUTE } from 'features/help/constants';
import { ContentfulErrorContext } from 'features/help/hooks/ContentfulErrorContext';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';

type SearchBarRowProps = {
  buttonSize?: 'medium' | 'large' | 'small' | undefined;
  searchBarClass?: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      margin: theme.spacing(0, 2),
    },
    searchBar: {
      display: 'flex',
      maxWidth: theme.spacing(43.75),
      minWidth: theme.spacing(40),
      height: theme.spacing(5),
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      background: theme.palette.background.paper,
      borderRadius: theme.spacing(0.375),
      padding: theme.spacing(0, 0.75),
    },
    input: {
      '&::placeholder': {
        flex: '1 0 0',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.spacing(1.5),
        fontFeatureSettings: "'clig' off, 'liga' off;",
        color: theme.palette.text.hint,
        opacity: 1,
      },
      '&.MuiInputBase-inputMarginDense': {
        padding: 0,
      },
      '&.MuiInputBase-input': {
        padding: 0,
      },
    },
    searchButton: {
      whiteSpace: 'nowrap',
      color: '#FFFFFF !important',
      backgroundColor: '#0074CA !important',
      marginLeft: theme.spacing(2),
      '&:hover': {
        backgroundColor: '#00497A !important',
      },
    },
    getInTouchButton: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(15),
      marginLeft: theme.spacing(2),
    },
  }),
);
function SearchBarRow({ buttonSize = 'large', searchBarClass }: SearchBarRowProps): ReactElement | null {
  const history = useHistory();
  const query = useSearchParams<{ q?: string }>();

  const classes = useStyles();
  const [searchText, setSearchText] = React.useState(query.q || '');
  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  useEffect(() => {
    setSearchText(query.q || '');
  }, [query.q]);

  const handleSearch = React.useCallback(
    (e: React.SyntheticEvent) => {
      setContentfulErrorType(null);
      const path = `${HELP_SEARCH_ROUTE}?q=${encodeURIComponent(searchText)}`;
      history.push(path);
      e.preventDefault();
    },
    [history, searchText],
  );

  return (
    <div data-testid="knowledge-base-searchBar-row" className={classes.container}>
      <form onSubmit={handleSearch} style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
        <SearchBar
          searchString={searchText}
          onSearchChange={setSearchText}
          textFieldClass={clsx(classes.searchBar, searchBarClass)}
          inputClass={classes.input}
          placeholder="Enter Search Term"
          fullWidth
        />
        <AgentNetButton
          data-testid="knowledge-base-searchBar-button"
          type="submit"
          variant="contained"
          size={buttonSize}
          color="primary"
          className={classes.searchButton}
          disabled={!searchText?.trim()}
        >
          Search Articles
        </AgentNetButton>
      </form>
    </div>
  );
}

export { SearchBarRow };
