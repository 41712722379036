import typography from './typography/typography';
import spacing from './spacing';
import props from './props';
import shape from './shape';
import breakpoints from './breakpoints';
import transitions from '@material-ui/core/styles/transitions';
import palette from 'ui-kit/theme/palette';
import overrides from 'ui-kit/theme/overrides';
import { createMuiTheme } from '@material-ui/core';

export const kbTheme = createMuiTheme({
  palette,
  typography,
  spacing,
  overrides,
  props,
  shape,
  breakpoints,
  transitions,
});

export default kbTheme;
