import React, { ElementType } from 'react';
import { createStyles, makeStyles, Link, LinkProps, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type L2NavItemProps = LinkProps<ElementType, { component?: ElementType }>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      display: 'flex',
      padding: theme.spacing(0, 2.5),
      whiteSpace: 'nowrap',
      letterSpacing: 1,
      position: 'relative',
      '&.disabled': {
        cursor: 'default',
        color: theme.palette.text.disabled,
      },
      '& $wrapper': {
        lineHeight: 1,
        borderBottomColor: 'transparent',
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        paddingLeft: theme.spacing(0.125),
        textTransform: 'uppercase',
        '&:after': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingTop: theme.spacing(0.875),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(0.0625),
          content: 'attr(data-title)',
          fontWeight: theme.typography.fontWeightBold,
          letterSpacing: 0.75, // Compensate for the increased character width when bolded
          color: theme.palette.text.primary,
          borderBottomWidth: 2.3,
          borderBottomStyle: `solid`,
          borderBottomColor: 'transparent',
          opacity: 0,
          transition:
            'border-bottom-color ' + theme.transitions.duration.short + 'ms ' + theme.transitions.easing.easeInOut,
        },
      },
      '&:not(.disabled):not(.active):hover $wrapper:after': {
        // Target hover styling here
      },
      '&.active $wrapper': {
        color: 'transparent',
        '&:after': {
          opacity: 1,
          borderBottomColor: theme.palette.primary.main,
        },
      },
    },
    wrapper: {
      paddingBottom: theme.spacing(0.5),
    },
  }),
);

export const L2NavItem = ({ children, className, active, disabled, ...props }: L2NavItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Link
      {...props}
      className={clsx(classes.root, className, active && 'active', disabled && 'disabled')}
      underline="none"
    >
      <div className={clsx(classes.wrapper)} data-title={children}>
        {children as ChildNode}
      </div>
    </Link>
  );
};

export default L2NavItem;
