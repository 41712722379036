import React from 'react';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@fluentsms/agentnet-web-components';

const OrderManagementContent = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  );

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.contentWrap}>
          <AgentNetDivider title="Order Management" typoVariant="h1" />
        </div>
      </div>
    </>
  );
};

export default OrderManagementContent;
