import React, { useContext, useEffect, useState } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import AgentNetDropdownSelector from '../../../../ui-kit/inputs/AgentNetDropdownSelector';
import DateFieldString from '../../../../ui-kit/inputs/DateField/DateFieldString';
import { format as formatDate } from 'date-fns';
import '../CPLEditComponents.scss';
import {
  additionalPartyFields,
  coveredParty,
  cplFormDataType,
  CreateCPLType,
  partySection,
  SecondPartyType,
} from 'features/cpl/types';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { msgLetterType, msgStandard } from '../../cpl-list/constants';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import CurrencyField from 'ui-kit/inputs/CurrencyField/CurrencyField';
import { HelpOutline } from '@material-ui/icons';
import { getAmountWithCommas } from 'utilities/utilities';
import { letterTypeSchema } from 'utilities/validation/schemas/cpl-schema';
import { pxToRem } from '@fluentsms/agentnet-web-components';

interface LettertypeProps {
  index?: number;
  loadCPLData: CreateCPLType | null;
  closingDate: string | null;
  issueDate: string | null;
  setCoveredParties: (value: coveredParty[]) => void;
  setPartiesSection: (value: partySection) => void;
  setAdditionalPartiesFields: (value: additionalPartyFields) => void;
  setSecondPartyOptions: (value: SecondPartyType) => void;
  setClosingDate: (value: string | null) => void;
  setCplIssueDate: (value: string | null) => void;
  setCplFormData: (data: letterTypeFormData) => void;
  cplFormData: cplFormDataType;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  showValidation: boolean;
  validationErrors: FieldValidationError[];
  setValidationErrors: (errs: FieldValidationError[]) => void;
  additionalPartiesFields?: additionalPartyFields;
  getFilteredErrors: (errs: FieldValidationError[], fields: string[]) => void;
}
interface letterTypeFormData {
  office: {
    officeId: number;
    officeName: string;
  };
  dbaName: string;
  closingDate: string | null;
  issuedDate: string | null;
  coverageLimit: number | undefined;
  letterType: string;
}

const LetterType: React.FC<LettertypeProps> = ({
  index,
  loadCPLData,
  closingDate,
  issueDate,
  setCoveredParties,
  setPartiesSection,
  setAdditionalPartiesFields,
  setSecondPartyOptions,
  setClosingDate,
  setCplIssueDate,
  setCplFormData,
  cplFormData,
  isStarsStatusActive,
  noStarsStatusActive,
  validationErrors,
  setValidationErrors,
  showValidation,
  getFilteredErrors,
  additionalPartiesFields,
}: LettertypeProps) => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;

  const letterTypes = loadCPLData?.cplTypes?.map((cplLetterType) => {
    return { name: cplLetterType.letterTypeName, value: cplLetterType.letterTypeId.toString() };
  });

  const officeData = loadCPLData?.offices?.map((office) => {
    return { name: office.officeName, value: office.officeId };
  });

  const dbas = loadCPLData?.dbas?.map((dba) => {
    return { name: dba, value: dba };
  });

  const [letterType, setLetterType] = useState<string>('');
  const [officeId, setOfficeId] = useState<number>(0);
  const [dba, setDba] = useState<string>('');
  const [isEditForm, setEditForm] = useState<boolean>(false);
  const [coverageLimit, setCoverageLimit] = useState<number | undefined>();

  const isFormDisable = loadCPLData?.cpl?.status === 'Void' || isStarsStatusActive || noStarsStatusActive;

  const formData: letterTypeFormData = {
    office: {
      officeName: loadCPLData?.offices?.find((office) => office.officeId === officeId)?.officeName ?? '',
      officeId: officeId,
    },
    dbaName:
      additionalPartiesFields?.approvedAttorneys?.display == true && cplFormData.approvedAttorney?.dbaName
        ? cplFormData.approvedAttorney.dbaName
        : dba,
    closingDate: closingDate,
    issuedDate: issueDate,
    coverageLimit: coverageLimit,
    letterType: letterTypes?.find((type) => type.value === letterType)?.name ?? '',
  };

  useEffect(() => {
    if (!loadCPLData?.cpl) {
      setLetterType(loadCPLData?.cplTypes[0].letterTypeId.toString() ?? '');
      setOfficeId(loadCPLData?.offices[0].officeId ?? 0);
      setDba(loadCPLData?.dbas[0] ?? '');
      setCoverageLimit(loadCPLData?.coverageLimit.default);
    } else {
      setEditForm(true);
      const letterType = loadCPLData?.cpl?.letterType || '';
      const cplType = loadCPLData?.cplTypes.find(
        (type) => type.letterTypeName == letterType || type.letterTypeId == +letterType,
      );
      const letterTypeId = cplType?.letterTypeId.toString() || '';
      setLetterType(letterTypeId);
      handleCoveredParties(letterTypeId);
      !additionalPartiesFields?.approvedAttorneys?.display && !additionalPartiesFields?.myClosingAttorneys?.display
        ? setOfficeId(loadCPLData?.cpl.office?.officeId ?? 0)
        : setOfficeId(loadCPLData?.offices[0].officeId ?? 0);
      setDba(loadCPLData?.cpl.dbaName || '');
      setCoverageLimit(loadCPLData.cpl.coverageLimit);
    }
  }, [loadCPLData]);

  useEffect(() => {
    setCplFormData(formData);
  }, [formData]);

  useEffect(() => {
    doValidate({ issueDate, closingDate }, letterTypeSchema).then((errs: FieldValidationError[]) => {
      getFilteredErrors(errs, ['issueDate', 'closingDate']);
    });
  }, [issueDate, closingDate]);

  const handleCoveredParties = (letterType: string) => {
    const coveredParty = loadCPLData?.cpl ? loadCPLData?.cpl.coveredParty : '';

    loadCPLData?.cplTypes?.map((type) => {
      type.letterTypeId.toString() === letterType
        ? (setCoveredParties(
            type.coveredParties.map((party) => ({
              coveredPartyName: party,
              checked: coveredParty == party,
            })),
          ),
          setPartiesSection(type.parties),
          setAdditionalPartiesFields(type.additionalParties),
          setSecondPartyOptions({
            escrowSecondParties: type.escrowSecondParties,
            titleSecondParties: type.titleSecondParties,
          }))
        : '';
    });
  };

  const iconStyles = { marginLeft: pxToRem(12), marginTop: '6px' };
  const coverageTooltipMsg = `Amount must be between ${getAmountWithCommas(
    loadCPLData?.coverageLimit.min,
  )} and ${getAmountWithCommas(loadCPLData?.coverageLimit.max)}.  For an amount higher than ${getAmountWithCommas(
    loadCPLData?.coverageLimit.max,
  )}, please submit an underwriting request`;

  return (
    <Grid container spacing={3} className="CPAEditDetails">
      {additionalPartiesFields?.approvedAttorneys?.display == false &&
      additionalPartiesFields?.myClosingAttorneys?.display == false ? (
        <>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              name="dba"
              required
              label="DBA"
              Data-QA={index ? `CPL${index}DBA` : 'CPLDBA'}
              options={dbas ?? []}
              value={dba}
              id="dba"
              dropdowntype="outlined"
              menuOption={(value) => {
                setDba(value);
                return false;
              }}
              disabled={isFormDisable}
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              name="office"
              required
              label="Office"
              Data-QA={index ? `CPL${index}Office` : 'CPLOffice'}
              options={officeData ?? []}
              value={officeId}
              id="office"
              dropdowntype="outlined"
              menuOption={(value) => {
                setOfficeId(value);
                return false;
              }}
              disabled={isFormDisable}
            />
          </Grid>
        </>
      ) : null}
      <Grid item sm={3}>
        <AgentNetDropdownSelector
          name="letter-type"
          required
          label="Letter Type"
          Data-QA={index ? `CPL${index}LetterType` : 'CPLLetterType'}
          options={letterTypes ?? []}
          disabled={isEditForm || isFormDisable}
          value={letterType}
          id="letter-type"
          dropdowntype="outlined"
          menuOption={(value) => {
            setLetterType(value);
            handleCoveredParties(value);
            setValidationErrors([]);
          }}
        />
      </Grid>
      <Grid item sm={3} style={{ display: 'flex' }}>
        <CurrencyField
          disabled={isFormDisable}
          Data-QA={index ? `CPL${index}CoverageLimit` : 'CPLCoverageLimit'}
          required
          onBlur={(e) => {
            if (loadCPLData?.coverageLimit.min && loadCPLData?.coverageLimit.max && coverageLimit) {
              if (coverageLimit < loadCPLData?.coverageLimit.min) setCoverageLimit(loadCPLData?.coverageLimit.min);
              if (coverageLimit > loadCPLData?.coverageLimit.max) setCoverageLimit(loadCPLData?.coverageLimit.max);
            }
          }}
          variant="outlined"
          fullWidth
          label="Coverage Limit"
          id="coverageLimit"
          name="coverageLimit"
          max={100000000000}
          allowNegative={false}
          value={coverageLimit}
          onChange={(e) => {
            setCoverageLimit(parseFloat(e.target.value));
          }}
        />
        <Tooltip data-qa={'coverageToolTip'} title={coverageTooltipMsg} style={iconStyles}>
          <HelpOutline fontSize="large" color="primary" />
        </Tooltip>
      </Grid>
      <Grid item sm={3}>
        <DateFieldString
          label="CPL Issue Date"
          id="cpl-issue-date"
          error={validationErrors?.some((err) => err.field === 'issueDate') && showValidation}
          helperText={validationErrors?.filter((err) => err.field === 'issueDate')?.[0]?.message}
          name="issueDate"
          showValidation={showValidation}
          disabled={isFormDisable}
          qaAttribute={index ? `CPL${index}IssueDate` : 'CPLIssueDate'}
          maxDate="12/31/9999"
          value={(() => {
            const dateParsed = Date.parse(issueDate || '');
            return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : issueDate;
          })()}
          required
          onChange={(dateVal) => {
            setCplIssueDate(dateVal !== '' ? dateVal : null);
          }}
        />
      </Grid>
      <Grid item sm={3}>
        <DateFieldString
          label="Closing Date"
          id="closing-date"
          disabled={isFormDisable}
          qaAttribute={index ? `CPL${index}ClosingDate` : 'CPLClosingDate'}
          maxDate="12/31/9999"
          value={(() => {
            const dateParsed = Date.parse(closingDate || '');
            return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : closingDate;
          })()}
          error={validationErrors?.some((err) => err.field === 'closingDate') && showValidation}
          name="closingDate"
          onChange={(dateVal) => {
            setClosingDate(dateVal !== '' ? dateVal : null);
          }}
        />
      </Grid>
      {fileData?.propertyState == 'NY' && letterType == '161' ? (
        <Grid item sm={12}>
          <Typography variant="h6" className={'summary-title'}>
            {msgStandard}
          </Typography>
        </Grid>
      ) : null}
      {fileData?.propertyState == 'NY' && letterType == '207' ? (
        <Grid item sm={12}>
          <Typography variant="h6" className={'summary-title'}>
            {msgLetterType}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default LetterType;
