import React from 'react';
import ReactDOM from 'react-dom';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import './index.css';
import App from './App';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-048623 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( First American )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Agentnet )_only_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Agentnet )_need_to_be_licensed___( Agentnet )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 December 2024 )____[v2]_MTczMzg3NTIwMDAwMA==c53727f07ee5d5d9e6e6b9d13f17cbb6',
);

ReactDOM.render(<App />, document.getElementById('root'));
