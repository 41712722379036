import createTypography from '@material-ui/core/styles/createTypography';
import palette from '../palette/palette';
import '../../../common-assets/fonts/inter/Inter-Regular.ttf';
import '../../../common-assets/fonts/inter/Inter-Medium.ttf';

const typography = createTypography(palette, {
  h1: {
    textTransform: 'uppercase',
  },
  h2: {
    textTransform: 'uppercase',
  },
  h3: {
    textTransform: 'uppercase',
  },
  h4: {
    textTransform: 'uppercase',
  },
  h5: {
    textTransform: 'uppercase',
  },
  h6: {
    textTransform: 'uppercase',
  },
});

export default typography;
